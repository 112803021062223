import React from "react";
import { Order } from "../helpers/api";
import { isEmpty } from "lodash";
import { sendNotification } from "../helpers/notification";

export const OrderContext = React.createContext({});

export class OrderProvider extends React.Component {

  state = {
    updateState: async (key, val) => {
      await this.setState((state) => {
        state[key] = val;
        return state;
      })
    },
    getOrders: async (filter) => {
      await Order.getOrders(filter)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    getArchiveOrders: async (filter) => {
      await Order.getArchiveOrders(filter)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    updateOrderStatus: async () => {
      let params = {}
      params.status = this.state.status;
      if (!isEmpty(this.state.amount)) params.amount = this.state.amount;
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      return Order.updateOrderStatus(this.state.activeOrder, params.status, params)
        .then((res) => {
          return { status: 'success', ...res.body }
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    changeOrderStatus: async () => {
      let params = {}
      let array = [];
      params.status = this.state.status;
      array = this.state.orderArray;
      let errorOrders = [];
      let successOrders = [];

      for (const item of array) {
        try {
          const res = await Order.updateOrderStatus(item.id, params.status);
          successOrders.push(item.id);
        } catch (error) {
          errorOrders.push({ id: item.id, message: error?.response?.body?.message });
          console.error("error", JSON.stringify(error, null, 4));
        }
      }

      return { errorOrders, successOrders };
    },
    getOrder: async (id) => {
      return Order.getOrder(id)
        .then((res) => {
          this.setState({ order: res.body });
          let history = [];
          for (const [key, val] of Object.entries(res.body.metadata)) {
            if (val.id) {
              history.push({ date: key, data: val})
            }
          }
          this.setState({ history });
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
        });
    },
    updateOrderDetails: async () => {
      let params = {}
      if (!isEmpty(this.state.status)) params.status = this.state.status;
      if (!isEmpty(this.state.cardNumber)) params.cardNumber = this.state.cardNumber;
      if (!isEmpty(this.state.cardProvider)) params.cardProvider = this.state.cardProvider;
      if (!isEmpty(this.state.amount)) params.amount = parseFloat(this.state.amount);
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      return Order.updateOrderDetails(this.state.order.id, params)
        .then((res) => {
          this.setState({});
          this.setState({ order: res.body.order });
          return { status: 'success' }
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    updateObjectProp: async (key, val) => {
      await this.setState({ filter: {...this.state.filter, [key]: val}});
    },
    resendCallback: async (id) => {
      return Order.resendCallback(id)
        .then((res) => {
          if (res.body.message === 'ok') {
            return { status: 'success' }
          }
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    getStat: async () => {
      return Order.getStat(this.state.startOrderId)
        .then((res) => {
          this.setState({ stats: res.body });
          return { status: 'success' }
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    getExport: async () => {
      let params = {};
      params.exportid = !isEmpty(this.state.exportid) ? this.state.exportid : 0;
      await Order.getExport(params.exportid)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    clean: async () => {
      return this.setState({
        status: null,
        cardNumber: null,
        cardProvider: null,
        amount: null,
        comment: null,
        filter: null,
        order: null,
        orders: null,
        history: null,
      });
    },
    startOrderId: 0,
  };

  render() {
    return (
      <OrderContext.Provider value={this.state}>
        {this.props.children}
      </OrderContext.Provider>
    );
  }
}