import React, { useContext } from "react";
import { UserContext } from "../../../context/users-context";
import { sendNotification } from "../../../helpers/notification";
import { isEmpty } from "lodash";

const UserFilter = (props) => {

  const [loading, setLoading] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const UserCunsumer = useContext(UserContext);

  const updateFilter = async (event) => {
    await setFilter({...filter, [event.target.name]: event.target.value});
  };
 
  const submit = async () => {  
    setLoading(true);
    props.updateFilter(filter);
    return UserCunsumer.getUserOrders(filter)
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  const clean = async () => {
    setLoading(true);
    setFilter({});
    props.updateFilter({});
    return UserCunsumer.getUserOrders({})
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <h4 className="mb-3 text-primary">
          Фильтр<i className='menu-icon tf-icons mdi mdi-filter-outline ms-2'></i>
        </h4>
        <hr />
        <div className="row mb-5">
          <div className="col-md-6 col-6 pe-0 pe-sm-5">
            <div className="form-group">
              <label style={{ fontSize: 14 }} className="mb-2 mt-3" htmlFor="cardProvider">
                <strong>Банк:</strong>
              </label>
              <select
                onChange={updateFilter}
                className="form-control text-primary"
                name="cardProvider"
                id="cardProvider"
                value={!isEmpty(filter.cardProvider) ? filter.cardProvider : ''}
              >
                <option value='' defaultValue> Все банки </option>
                <option value={"sberbank"}>sberbank</option>
                <option value={"tinkoff"}>tinkoff</option>
                <option value={"yandex"}>yandex</option>
                <option value={"visa/master"}>visa/master</option>
              </select>
            </div>
            <div className="form-group">
              <label style={{ fontSize: 14 }} className="mb-2 mt-3" htmlFor="status">
                <strong>Статус:</strong>
              </label>
              <select
                onChange={updateFilter}
                className="form-control text-primary"
                name="status"
                value={!isEmpty(filter.status) ? filter.status : ''}
              >
                <option value="" defaultValue>Все статусы</option>
                <option value={"CREATED"}>Новая</option>
                <option value={"PENDING"}>В процессе</option>
                <option value={"SUCCESS"}>Выполненная</option>
                <option value={"EDITED"}>Выполненная с корректировкой</option>
                <option value={"EXPIRED"}>Просроченная</option>
              </select>
            </div>
          </div> 
          <div className="col-md-6 col-6 pe-0 pe-sm-5">
            <div className="form-group">
              <label style={{ fontSize: 14 }} className="mb-2 mt-3" htmlFor="minSum">
                <strong>Сумма от:</strong>
              </label>
              <input
                type="number"
                onChange={updateFilter}
                className="form-control text-primary"
                name="minSum"
                id="minSum"
                value={!isEmpty(filter.minSum) ? filter.minSum : ''}
              />
            </div>
            <div className="form-group">
              <label style={{ fontSize: 14 }} className="mb-2 mt-3" htmlFor="maxSum">
                <strong>Сумма до:</strong>
              </label>
              <input
                type="number"
                onChange={updateFilter}
                className="form-control text-primary"
                name="maxSum"
                id="maxSum"
                value={!isEmpty(filter.maxSum) ? filter.maxSum : ''}
              />
            </div>
          </div>
        </div>
        {loading === true ? (
          <button className="btn btn-outline-info waves-effect" type="button" >
            Загрузка...<span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
          </button>
        ) : (
          <>
            <button 
              type="button" 
              className="btn btn-outline-success waves-effect me-3 media-mb-4"
              onClick={(e) => submit(e)}
            >
              Фильтровать <i className=' tf-icons mdi mdi-filter-check-outline ms-2'></i>
            </button>
            <button 
              type="button" 
              className="btn btn-outline-danger waves-effect"
              onClick={(e) => clean(e)}
            >
              Сбросить <i className=' tf-icons mdi mdi-filter-minus-outline ms-2'></i>
            </button>
          </>
        )}
      </form>
    </>
  );
};

export default UserFilter;