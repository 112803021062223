import React, { useContext, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";

import Loader from "../../components/Loader";
import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import ModalEditBallance from "./modals/ModalEditBallance";
import Corretions from "./modals/Corretions";
import { UserContext } from "../../context/users-context";

const Users = () => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showCorrection, setShowCorrection] = React.useState(false);
  const [showCorrectionHistory, setShowCorrectionHistory] = React.useState(false);
  const [correctionsData, setCorrectionData] = React.useState([]);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    UserCunsumer.getUsers();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []); 

  useEffect(() => {
    UserCunsumer.getUsers();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []); 

  useEffect(() => {
    if (UserCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", UserCunsumer.redirectMessage);
      setRedirectPath(UserCunsumer.redirectPath);
      UserCunsumer.clear();
      setRedirect(true);
    }
  }, [UserCunsumer.redirect]); 

  const updateData = () => {
    setLoading(true);
    UserCunsumer.clear();
    setTimeout(() => {
      UserCunsumer.getUsers();
      setLoading(false);
    }, 100);
  };

  const togglePopUp = useCallback(
    (trigger) => {
      UserCunsumer.updateState('activeUser', null)
      UserCunsumer.updateState('balanceAmount', null)
      UserCunsumer.updateState('comment', null)
      setShowCorrection(false);
    },[] 
  );

  const handleUser = (userId) => {
    UserCunsumer.updateState('activeUser', userId)
    setShowCorrection(true);
  }

  const handleCorrections = (data) => {
    setCorrectionData(data);
    setShowCorrectionHistory(true);
  }

  const togglePopUpCorrections = useCallback(
    (trigger) => {
      setCorrectionData([]);
      setShowCorrectionHistory(false);
    },[]
  );

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const getImageNumber = (max) => {
    return Math.floor(Math.random() * max);
  }

  return (
    <>
      {renderRedirect()}
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle media-fs-17">
                      <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Пользователи и их балансы
                    </h4>
                  </div>
                </div>
              </div>
            </nav>
            {loading ? (
              <Loader />
            ) : (
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <ModalEditBallance
                    updateData={() => updateData()}
                    togglePopUp={togglePopUp}
                    show={showCorrection}
                  />
                  <Corretions 
                    data={correctionsData}
                    togglePopUp={togglePopUpCorrections}
                    show={showCorrectionHistory}
                  />
                  <div className="row gy-4 mb-4">
                    {!isEmpty(UserCunsumer.users) && UserCunsumer.users.map((item, index) => (
                      <div className="col-12 col-xl-4 order-1 order-md-0" key={index}>
                        <div className="card mb-1">
                          <div className="card-body ms-2">
                            <div className="row">
                              <div className="col-5">
                                <div className="user-avatar-section">
                                  <div className="d-flex flex-column">
                                    <img className="img-fluid rounded mb-3 mt-2" src={require(`../../images/avatars/${getImageNumber(8)}.png`)} height="150" width="150" alt="User avatar"/>
                                  </div>
                                </div>
                              </div>
                              <div className="col-7">
                                <div className="d-flex justify-content-between flex-wrap py-1">
                                  <div className="d-flex align-items-center gap-3 mt-1">
                                    <div className="avatar avatar-icon">
                                      <div className="avatar-initial bg-label-warning rounded">
                                        <img src={require('../../images/currency-rub-1.png')} style={{width: '20px', height: '20px'}}/>
                                      </div>
                                    </div>
                                    <div>
                                      <h5 className="mb-0 fw-normal">{item.balance}</h5>
                                      <span>Баланс</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between flex-wrap py-1">
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="avatar avatar-icon">
                                      <div className="avatar-initial bg-label-primary rounded">
                                        <i className="mdi mdi-format-list-numbered mdi-20px"></i>
                                      </div>
                                    </div>
                                    <div>
                                      <h5 className="mb-0 fw-normal">{item?.orders_count ? item.orders_count : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</h5>
                                      <span>Заявки</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="info-container my-1">
                              <ul className="list-unstyled mb-4">
                                <li className="mb-3">
                                  <span className="fw-semibold text-heading me-2">id:</span>
                                  <span>{item?.id ? item.id : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</span>
                                </li>
                                <li className="mb-3">
                                  <span className="fw-semibold text-heading me-2">Nickname:</span>
                                  <span className="badge bg-label-danger">{item.nickname}</span>
                                </li>
                                <li className="mb-3">
                                  <span className="fw-semibold text-heading me-2">Email:</span>
                                  <span className="badge bg-label-success">{item.email}</span>
                                </li>
                              </ul>
                            </div>
                            <hr/>
                            <div className="mb-3 text-center">
                              <button className="btn btn-outline-primary" onClick={() => handleUser(item.id)}>
                                Кoрректировка баланса<i className='tf-icons mdi mdi-clipboard-edit-outline ms-2 '></i>
                              </button>
                            </div>
                            <div className=" text-center mb-2">
                              <button 
                                className="btn btn-outline-info" 
                                onClick={() => handleCorrections(item?.metadata?.correctionBalanceHistory ? item?.metadata?.correctionBalanceHistory : [])}
                              >
                                История корректировок<i className='tf-icons mdi mdi-clipboard-clock-outline ms-2 '></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;