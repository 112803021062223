import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { sendNotification } from "../../helpers/notification";
import UserBar from "../../components/UserBar";
import TwoFa from "../../components/TwoFa";
import { UserContext } from "../../context/users-context";
import Loader from "../../components/Loader";

const UserSettings = () => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const userCunsumer = useContext(UserContext);

  React.useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", userCunsumer.redirectMessage);
      setRedirectPath(userCunsumer.redirectPath);
      userCunsumer.clear();
      setRedirect(true);
    }
  }, [userCunsumer.redirect]);

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  return (
    <>
      {renderRedirect()}
      {loading && <Loader/>}
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <UserBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle">
                      <a className="icon-menu-mobil-media" onClick = { () => {userCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Настройки пользователя
                    </h4>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">                  
                <TwoFa />
              </div>
            </div>
          </div>
        </div> 
      </div> 
    </>
  );
};

export default UserSettings;