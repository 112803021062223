import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import Cookies from "js-cookie";

const superagent = superagentPromise(_superagent, global.Promise);
const API_URL = `${process.env.REACT_APP_API_URL}/api/v1`;
const API_PAYMENTS = `${process.env.REACT_APP_API_PAYMENTS}/api/v1`;

const handleErrors = (err) => {
  if (err && err.response && err.response.status === 401) {
    console.log(err);
  }
  return err;
};

const tokenPlugin = (req) => {
  const jwt = Cookies.get("jwt");
  if (jwt) {
    req.set("Authorization", `Bearer ${jwt}`);
  }
};

const requests = {
  get: async (url, query) =>
    await superagent
      .get(`${API_URL}${url}`)
      .query(query)
      .use(tokenPlugin)
      .end(handleErrors),
  put: (url, body) =>
    superagent.put(`${API_URL}${url}`, body).use(tokenPlugin).end(handleErrors),
  post: (url, body) =>
    superagent
      .post(`${API_URL}${url}`, body)
      .use(tokenPlugin)
      .end(handleErrors),
  patch: (url, body, root = API_URL) =>
    superagent.patch(`${root}${url}`, body).use(tokenPlugin).end(handleErrors),
  delete: (url) =>
    superagent.del(`${API_URL}${url}`).use(tokenPlugin).end(handleErrors),
};

export const Auth = {
  login: (email, password, code) =>
    requests.post("/auth/login", { email, password, code }),
};

export const Order = {
  getOrders: async (params) => await requests.get("/orders", params),
  getArchiveOrders: async (params) => await requests.get("/orders/archive", params),
  updateOrderStatus: async (orderId, status, params) => await requests.patch(`/order/${orderId}/status/${status}`, params),
  getOrder: async (id) => await requests.get(`/orders/single/${id}`),
  updateOrderDetails: async (id, params) => await requests.patch(`/order/${id}`, params),
  resendCallback: async (id) => await requests.get(`/order/callback/${id}`),
  getStat: async (id) => await requests.get(`/orders/stats/${id}`),
  getExport: async (id) => await requests.get(`/orders/export/${id}`),
};

export const Card = {
  getCards: async () => await requests.get("/cards"),
  pushNewCard: async (params) => await requests.post("/card/create", params),
  getCard: async (id) => await requests.get(`/card/single/${id}`),
  updateCard: async (id, params) => await requests.patch(`/card/${id}`, params),
  deleteCard: async (id) => await requests.delete(`/card/${id}`),
  changeStatus: async (params) => await requests.patch(`/cards/status`, params),
};

export const User = {
  current: () => requests.get("/user/me"),
  getUserOrders: async (params) => await requests.get("/user/orders", params),
  getUserStat: async () => await requests.get(`/orders/stats`),
  createTwoFaToken: (params) => requests.post("/user/twofa/create", params),
  confirmTwoFa: (params) => requests.post("/user/twofa/confirm", params),
  getUsers: async (params) => await requests.get("/users", params),
  updateBallance: async (id, params) => await requests.patch(`/user/${id}/balance`, params),
  getCourse: async () => await superagent.get(`https://garantex.org/api/v2/depth?market=usdtrub`).end(handleErrors),
  createWithdraw: async (params) => await requests.post(`/user/withdrawal`, params),
  getWithdrawalsOrders: async () => await requests.get(`/user/withdraws`),
};

export const Withdraw = {
  getPengingWithdrawsCount: async () => await requests.get(`/users/withdrawals_count`),
  getWithdrawsOrders: async () => await requests.get(`/users/withdrawals`),
  updateWithdrawStatus: async (params) => await requests.patch(`/user/withdrawal/maked`, params),
}

export const Payments = {
  current: async (params) => await superagent.get(`${API_PAYMENTS}/user`).query(params).use(tokenPlugin).end(handleErrors),
  getStatPayments: async (params) => await superagent.get(`${API_PAYMENTS}/orders/stats`).query(params).use(tokenPlugin).end(handleErrors),
  getOrdersPayments: async (params) => await superagent.get(`${API_PAYMENTS}/user/orders`).query(params).use(tokenPlugin).end(handleErrors),
  createOrder: async (body) => await superagent.post(`${API_PAYMENTS}/order/create-manual`, body).use(tokenPlugin).end(handleErrors),
};