import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from '../components/Loader';

const Forbidden = () => {

    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return(
      <>
        {loading && <Loader/>}
        <div className="misc-wrapper">
          <div className="text-center mt-3">
            <h1 className="mb-2 mx-2" style={{fontSize: '6rem'}}>403</h1>
            <h4 className="mb-2 fw-semibold">Доступ запрещен ⚠️</h4>
            <p className="mb-4 mx-2">Oopps!! The page you were looking for doesn't exist.</p>
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <img src={require('../images/illustrations/misc-error-illustration.png')} alt="misc-error" className="img-fluid zindex-1 media-w-img-error" width="190"/>
            <div>
              <Link to={'/'} className="btn btn-primary text-center my-4 waves-effect waves-light">Вернуться на главную </Link>
            </div>
          </div>
        </div>
      </>
    )
};

export default Forbidden;