import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, isNaN } from 'lodash';
import "../../scss/cards-statistics.css";
import "../../scss/cards-analytics.css";
import { evaluate } from 'mathjs';

import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";
import { PaymentsContext } from "../../context/payments-context";
import moment from 'moment';

const UserWhite = () => {

  const [loading, setLoading] = React.useState(true);
  const UserCunsumer = useContext(UserContext);
  const PayCunsumer = useContext(PaymentsContext);

  useEffect(() => {
    UserCunsumer.userMe();
    UserCunsumer.getUserOrders({ limit: 3 });
    UserCunsumer.getUserStat();
    PayCunsumer.getPayBalance();
    PayCunsumer.getPayOrders({ limit: 3 });
    PayCunsumer.getPayStat();
    setLoading(false);
    return () => {
      UserCunsumer.clear();
      PayCunsumer.clear();
    };
  }, []);  


  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'SUCCESS':
        return 'bg-label-success'
      case 'EDITED':
        return 'bg-label-warning'
      case 'EXPIRED':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getColorClass = (bank) => {
    switch (bank) {
      case 'sberbank':
        return 'success'
      case 'tinkoff':
        return 'warning'
      case 'yandex':
        return 'danger'
      default:
        return 'dark'
    }
  }

  const getBadgeClassPay = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'COMPLETED':
        return 'bg-label-success'
      case 'ERROR':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'НОВАЯ'
      case 'PENDING':
        return 'В ПРОЦЕССЕ'
      case 'SUCCESS':
        return 'ВЫПОЛНЕННАЯ'
      case 'EDITED':
        return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
      case 'EXPIRED':
        return 'ПРОСРОЧЕННАЯ'
      default:
        return status;
    }
  }

  const getStatusPay = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'ВЫПОЛНЕННАЯ'
      case 'PENDING':
        return 'В РАБОТЕ'
      case 'ERROR':
        return 'ОШИБОЧНАЯ'
      case 'CREATED':
        return 'НОВАЯ'
      default:
        return status;
    }
  }

  const totalSumOrder = (value) => {
    if (!isEmpty(UserCunsumer.stats) && UserCunsumer.stats.all.expiredCount && UserCunsumer.stats.all.successCount) {
      var dep = (+UserCunsumer.stats.all.expiredCount + +UserCunsumer.stats.all.successCount);
    } else {
      var dep = 0;
    }
    if (!isEmpty(PayCunsumer.stats) && PayCunsumer.stats.all.completedCount && PayCunsumer.stats.all.errorCount){
      var pay = (+PayCunsumer.stats.all.completedCount + +PayCunsumer.stats.all.errorCount)
    } else {
      var pay = 0;
    }
    let total = dep + pay;
    if (total >= 0) {
      if (value === "deposits") {
        return (dep);
      }
      if (value === "payments") {
        return (pay);
      }
      if (value === "total") {
        return (total);
      }
    } else {
      return (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>);
    }
  }

  const percentageOrder = (value) => {
    if (!isEmpty(UserCunsumer.stats)) {
      var sumDep = (+UserCunsumer.stats.all.expiredCount + +UserCunsumer.stats.all.successCount);
    }
    if (!isEmpty(PayCunsumer.stats)) {
      var sumPay = (+PayCunsumer.stats.all.completedCount + +PayCunsumer.stats.all.errorCount)
    }
    let total = sumPay + sumDep;
    let pay = 100 * sumPay / total;
    let dep = 100 * sumDep / total;
    if (total >= 0) {
      if (value === "deposits") {
        return (dep.toFixed(2) + '%');
      }
      if (value === "payments") {
        return (pay.toFixed(2) + '%');
      }
    } else {
      return (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>);
    }
  }

  return(
    <>
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <UserBar />
          <div className='layout-page layout-page-pt'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme nav-menu-mobil-media" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <a onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                      <i className="mdi mdi-menu mdi-24px me-3"></i>
                    </a>
                    <Link to='/user/home' className='sidebar-brand'>
                      <img src={require('../../images/logo2.png')} className='me-5' width={'110px'} alt='' onClick = {() => {UserCunsumer.setOpenMenu(false)}}/>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper pt-75">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="card">
                      <div className="d-flex align-items-end row">
                        <div className="col-md-7 order-2 order-md-1">
                          <div className="card-body ps-5" >
                            <h4 className="card-title pb-xl-2"> Добро пожаловать! 🎉</h4>
                            <div className="row">
                              <div className="col-12 col-lg-7">
                                <p className="mb-0">Заявки уже ждут<span className="fw-semibold"> вас</span>😎</p>
                                <p>Смотрите заявки в своем профиле.</p>
                              </div>
                              <div className="col-12 col-lg-5 ps-3 pt-1">
                                <Link to='/user/orders'>
                                  <button className="btn btn-primary waves-effect waves-light media-btn-sm">Перейти к заявкам </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 text-center text-md-end order-1 order-md-2">
                          <div className="card-body pb-0 px-0 px-md-4 ps-0">
                            <img src={require('../../images/illustrations/illustration-john-dark.png')}  height={180}  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4">
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-8 col-12">
                    <div className="card h-100">
                      <div className="card-body text-nowrap">
                        <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс депозитов</h4>
                        <p className="pb-0">Общая принятая сумма</p>
                        <div className="d-flex align-items-center gap-3 my-4">
                          <div className="avatar avatar-icon">
                            <div className="avatar-initial bg-label-warning rounded">
                              <i className="mdi mdi-currency-usd mdi-24px"></i>
                              {/* <img src={require('../../images/currency-rub-1.png')} style={{width: '24px', height: '24px'}}/> */}
                            </div>
                          </div>
                          <div>
                            <h4 className="mb-0 text-warning fw-normal">
                              {UserCunsumer?.user?.balance || UserCunsumer?.user?.balance === 0 ? 
                                UserCunsumer.user.balance 
                              : 
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              } usdt
                            </h4>
                            <span>Баланс</span>
                          </div>
                        </div>
                        <Link to='/user/withdraw' className=" btn btn-sm btn-outline-dark waves-effect waves-light">Вывод баланса</Link>
                      </div>
                      <img src={require('../../images/illustrations/money-bag-5654697-4712953.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="140" alt="view sales"/>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="card">
                      <div className="card-header pb-0">
                        <div className="d-flex justify-content-between flex-wrap gap-2">
                          <p className="d-block mb-2 text-muted">Количество закрытых заявок</p>
                          <div className="d-flex text-primary">
                            <p className="me-1">{moment(new Date()).format('DD.MM.YYYY')}</p>
                          </div>
                        </div>
                        <h4 className="mb-1">{totalSumOrder('total')} шт.</h4>
                      </div>
                      <div className="card-body">
                        <div className="row mt-3">
                          <div className="col-4">
                            <div className="d-flex gap-2 align-items-center mb-2">
                              <div className="avatar avatar-xs flex-shrink-0">
                                <div className="avatar-initial rounded bg-label-warning">
                                  <i className="mdi mdi-sack-outline mdi-14px"></i>
                                </div>
                              </div>
                              <p className="mb-0 text-muted">Депозиты</p>
                            </div>
                            <h4 className="mb-0 pt-1 text-nowrap">{totalSumOrder('deposits')} шт.</h4>
                            <small className="text-muted">{percentageOrder('deposits')} </small>
                          </div>
                          <div className="col-4">
                            <div className="divider divider-vertical">
                              <div className="divider-text">
                                <span className="badge-divider-bg bg-label-secondary">VS</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end pe-lg-0 pe-xl-2">
                            <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
                              <p className="mb-0 text-muted">Выплаты</p>
                              <div className="avatar avatar-xs flex-shrink-0" style={{width: '1.563rem', height: '1.563rem'}}>
                                <div className="avatar-initial rounded bg-label-success">
                                  <i className="mdi mdi-cash-multiple mdi-14px"></i>
                                </div>
                              </div>
                            </div>
                            <h4 className="mb-0 pt-1 text-nowrap">{totalSumOrder('payments')} шт.</h4>
                            <small className="text-muted">{percentageOrder('payments')} </small>
                          </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 pt-1">
                          <div className="progress w-100 rounded" style={{height: '10px'}}>
                            <div className="progress-bar bg-warning" style={{width: percentageOrder('deposits')}} role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                            <div className="progress-bar bg-success" role="progressbar" style={{width: percentageOrder('payments')}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-8 col-12">
                    <div className="card h-100">
                      <div className="card-body text-nowrap">
                        <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс выплат</h4>
                        <p className="pb-0">Общая сумма баланса выплат</p>
                        <div className="d-flex align-items-center gap-3 my-4">
                          <div className="avatar avatar-icon">
                            <div className="avatar-initial bg-label-success rounded">
                            {PayCunsumer.currency === 'usdt' ? 
                              <i className="mdi mdi-currency-usd mdi-24px"></i>
                            :
                              <img src={require('../../images/currency-rub-3.png')} style={{width: '24px', height: '24px'}}/>
                            }
                            </div>
                          </div>
                          <div>
                          <h4 className="mb-0 text-success fw-normal">
                            {PayCunsumer?.balance || PayCunsumer.balance === 0 ? 
                              PayCunsumer.balance 
                            : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            } {PayCunsumer.currency}
                          </h4>
                            <span>Баланс</span>
                          </div>
                        </div>
                        <Link to='/user/payments/create' className=" btn btn-sm btn-outline-dark waves-effect waves-light">Создать выплату</Link>
                      </div>
                      <img src={require('../../images/illustrations/payment-banknote.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="120" alt="view sales"/>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card h-100 p-3 media-p-none">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2 media-w-100">Последние заявки на депозит</h5>
                        {!isEmpty(UserCunsumer.orders) ? (
                          <div className="mb-2 me-1 media-w-100">
                            <Link to='/user/orders' className="btn btn-warning btn-sm waves-effect waves-light media-btn-sm media-ms-3">все заявки</Link>
                          </div>
                          ) : (
                          <div className="mb-2 me-1 media-w-100">
                            <Link to='/user/orders' className="btn btn-warning btn-sm waves-effect waves-light media-btn-sm media-ms-3">смотреть заявки</Link>
                          </div>
                          )}
                      </div>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-borderless">
                          {!isEmpty(UserCunsumer.orders) ? (
                            <>
                            <thead >
                              <tr>
                                <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Заявки</th>
                                <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Rub</th>
                                <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Курс</th>
                                <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Usdt</th>
                                <th className="text-end text-capitalize text-body fw-medium fs-6 media-fs-11">Статус</th>
                              </tr>
                            </thead>
                            <tbody className='border-top'>
                                {UserCunsumer.orders && (UserCunsumer.orders.reverse().slice((UserCunsumer.orders.length - 3), UserCunsumer.orders.length)).map( (item, index) => (
                                  <tr key={index}>
                                    <td className="d-flex mt-1">
                                      <div className="ms-1">
                                        <h6 className="mb-0 fw-semibold media-fs-11">Id: {item.id}</h6>
                                      </div>
                                    </td>
                                    <td className="text-muted small">{item.amount}</td>
                                    <td className="text-muted small">{item.course}</td>
                                    <td className="text-muted small">{item.total}</td>
                                    <td className="text-end">
                                      <div className={`badge ${getBadgeClass(item.status)} media-fs-11 rounded-pill`}>
                                        {getStatus(item.status)}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          ) : (
                            <>
                              <thead >
                                <tr>
                                  <th className="text-capitalize text-body fw-medium fs-6">Заявка</th>
                                  <th className="text-capitalize text-body fw-medium fs-6">Rub</th>
                                  <th className="text-capitalize text-body fw-medium fs-6">Курс</th>
                                  <th className="text-capitalize text-body fw-medium fs-6">Usdt</th>
                                  <th className="text-end text-capitalize text-body fw-medium fs-6">Статус</th>
                                </tr>
                              </thead>
                              <tbody className='border-top'>
                                <tr>
                                  <td className="d-flex mt-1">
                                    <div className="ms-1">
                                      <h6 className="mb-0 fw-semibold">Id: 24</h6>
                                    </div>
                                  </td>
                                  <td className="text-muted small">1029</td>
                                  <td className="text-muted small">93.21</td>
                                  <td className="text-muted small">11.04</td>
                                  <td className="text-end">
                                    <div className={`badge ${getBadgeClass('CREATED')}  rounded-pill`}>
                                      {getStatus('CREATED')}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>                               
                            </>
                          )}  
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card h-100 p-3 media-p-none">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2 media-w-100">Последние заявки на выплату</h5>
                        {!isEmpty(PayCunsumer.orders) ? (
                          <div className="mb-2 me-1 media-w-100">
                            <Link to='/user/payments/orders' className="btn btn-success btn-sm waves-effect waves-light media-btn-sm media-ms-3">все заявки</Link>
                          </div>
                          ) : (
                          <div className="mb-2 me-1 media-w-100">
                            <Link to='/user/payments/orders' className="btn btn-success btn-sm waves-effect waves-light media-btn-sm media-ms-3">смотреть заявки</Link>
                          </div>
                          )}
                      </div>
                      <div className="table-responsive text-nowrap">
                        <table className="table table-borderless">
                        {!isEmpty(PayCunsumer.orders) ? (
                            <>
                              <thead >
                                <tr>
                                  <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Заявки</th>
                                  <th className="text-capitalize text-body fw-medium fs-6 media-fs-11">Сумма</th>
                                  <th className="text-end text-capitalize text-body fw-medium fs-6 media-fs-11">Статус</th>
                                </tr>
                              </thead>
                              <tbody className='border-top'>
                                  {PayCunsumer.orders && (PayCunsumer.orders.reverse().slice((PayCunsumer.orders.length - 3), PayCunsumer.orders.length)).map( (item, index) => (
                                    <tr key={index}>
                                      <td className="d-flex mt-1">
                                        <div className="ms-1">
                                          <h6 className="mb-0 fw-semibold media-fs-11">Id: {item.id}</h6>
                                        </div>
                                      </td>
                                      <td className="text-muted small">{item.amount}</td>
                                      <td className="text-end">
                                        <div className={`badge ${getBadgeClassPay(item.status)} media-fs-11 rounded-pill`}>
                                          {getStatusPay(item.status)}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            ) : (
                              <>
                                <thead >
                                  <tr>
                                    <th className="text-capitalize text-body fw-medium fs-6">Заявка</th>
                                    <th className="text-capitalize text-body fw-medium fs-6">Сумма</th>
                                    <th className="text-end text-capitalize text-body fw-medium fs-6">Статус</th>
                                  </tr>
                                </thead>
                                <tbody className='border-top'>
                                  <tr>
                                    <td className="d-flex mt-1">
                                      <div className="ms-1">
                                        <h6 className="mb-0 fw-semibold">Id: 24</h6>
                                      </div>
                                    </td>
                                    <td className="text-muted small">1029 руб</td>
                                    <td className="text-end">
                                      <div className={`badge ${getBadgeClass('CREATED')}  rounded-pill`}>
                                        {getStatus('CREATED')}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>                               
                              </>
                            )}  
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4 media-mb-0">
                  <div className="col-12 col-xl-7">
                    <div className="card p-3 media-p-none h-100">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title m-0">
                          <small className="text-muted">Cтатистикa</small>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <ul className="nav nav-tabs nav-tabs-widget pb-3 gap-4 mx-1 d-flex flex-nowrap" role="tablist">
                          <li className="nav-item" role="presentation">
                            <div className="nav-link btn d-flex flex-column align-items-center justify-content-center  page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="true">
                              <button type="button" className="btn btn-icon rounded-pill btn-label-success waves-effect page-white-icon">
                                <img src={require('../../images/favicon/logoicon1.png')} width={30}/>
                              </button>
                            </div>
                          </li>
                          <li className="nav-item" role="presentation">
                            <div className="nav-link btn d-flex flex-column align-items-center justify-content-center page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabIndex="-1">
                              <button type="button" className="btn btn-icon rounded-pill btn-label-instagram waves-effect  page-white-icon">
                                <img src={require('../../images/favicon/logoicon3.png')} width={30}/>
                              </button>
                            </div>
                          </li>
                          <span className="tab-slider" style={{left: '0px', width: '112px', bottom: '0px'}}></span>
                        </ul>
                        {!isEmpty(UserCunsumer.stats) ? (
                          <div className="tab-content p-0 pt-3 ms-0 ms-sm-2">
                            <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                              <div className="table-responsive text-nowrap">
                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th className="fw-medium ps-0 text-heading">Статус</th>
                                      <th className="pe-0 fw-medium text-heading">Заявки</th>
                                      <th className="pe-0 text-end text-heading">Сумма в USDT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-success media-fs-11">Выполненные</span></td>
                                      <td className="pe-0 text-success ">{UserCunsumer.stats.all.successCount} шт.</td>
                                      <td className="pe-0 text-success text-end fw-semibold h6">{UserCunsumer.stats.all.successSumUsdt ? UserCunsumer.stats.all.successSumUsdt.toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-danger media-fs-11">Просроченные</span></td>
                                      <td className="text-danger pe-0">{UserCunsumer.stats.all.expiredCount} шт.</td>
                                      <td className="text-danger pe-0 text-end">
                                        <Link to='/user/stats'>
                                          <button className="btn btn-danger btn-sm waves-effect waves-light media-d-none">Смотреть статистику</button>
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="tab-content p-0 pt-3 ms-0 ms-sm-2">
                            <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                              <div className="table-responsive text-nowrap">
                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th className="fw-medium ps-0 text-heading">Статус</th>
                                      <th className="pe-0 fw-medium text-heading">Заявки</th>
                                      <th className="pe-0 text-end text-heading">Сумма</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-success">Выполненные</span></td>
                                      <td className="pe-0 text-success">10 шт.</td>
                                      <td className="pe-0 text-success text-end fw-semibold h6">1258</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-danger">Просроченные</span></td>
                                      <td className="text-danger pe-0">3 шт.</td>
                                      <td className="text-danger pe-0 text-end">
                                        <Link to='/user/stats'>
                                          <button className="btn btn-danger btn-sm waves-effect waves-light">Смотреть статистику</button>
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-5 h-100">
                    <div className="row gy-4">
                      <div className="col-lg-12 col-sm-12">
                        <div className="card h-100">
                          <div className="row">
                            <div className="col-5 d-flex text-center ">
                              <div className="card-body my-3">
                                <img style={{opacity: "0.85"}} src={require("../../images/2fa2.png")} alt="Ratings" width="100"/>
                              </div>
                            </div>
                            <div className="col-7 text-end pe-4">
                              <div className="card-body">
                                <div className="card-info mb-3 mt-3 mb-lg-1 mb-xl-3">
                                  <h6 className="mb-3 mb-lg-2 mb-xl-3 text-nowrap media-fs-11">Настройки пользователя</h6>
                                </div>
                                <div className="d-flex justify-content-end mt-3">
                                  <div className='mt-4'>
                                    <button type="button" className="btn btn-warning btn-sm waves-effect waves-light media-btn-sm"><Link to='/user/settings' className='text-white'>Подключить 2FA</Link></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="card h-100">
                          <div className="row">
                            <div className="col-12 col-xl-6">
                              <div className="card-body ps-4">
                                <div className="card-info mb-3 py-2 mb-lg-1 mb-xl-3">
                                  <h5 className="mb-3 mb-lg-2 mb-xl-3 text-nowrap">Все заявки</h5>
                                  <div className="badge bg-label-primary rounded-pill lh-xs">Архив</div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                  <div>
                                    <h6 className="mb-1 fw-semibold">Смотреть </h6>
                                    <p className="mb-0 text-muted">за все время</p>
                                  </div>
                                  <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded">
                                      <Link to='/user/orders' >
                                        <i className="mdi mdi-chevron-right mdi-24px scaleX-n1-rtl"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-xl-6 text-end d-flex align-items-end justify-content-center">
                              <div className="card-body pb-0 pt-3 position-absolute bottom-0">
                                <img src={require("../../images/illustrations/card-ratings-illustration.png")} alt="Ratings" width="80"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-9 col-12">
                    <div className="card h-100 p-3">
                      <div className="row">
                        <div className="col-4">
                          <div className="card-header media-p-2">
                            <div className="d-flex justify-content-between">
                              <h4 className="mb-2 text-primary media-w-100 media-fs-17">
                                Комиссии <i className='menu-icon tf-icons mdi mdi-credit-card-search-outline ms-2'></i>
                              </h4>
                            </div>
                            <div className="d-flex align-items-center media-w-100">
                              <small className="me-2"> Ваши комиссии на прием</small>
                            </div>
                          </div>
                        </div>
                        <div className="col-8 mt-2">
                          <div className="card-body d-flex flex-wrap gap-3 py-2" style={{flex: '0'}}>
                            {UserCunsumer?.user?.fees && !isEmpty(UserCunsumer.user.fees) &&
                              Object.keys(UserCunsumer.user.fees).map((item, index) => {
                                return (
                                  <div className="d-flex gap-3 me-5" style={{width: '165px'}} key={{index}}>
                                    <div className="avatar">
                                      <div className={`avatar-initial rounded bg-label-${getColorClass(item)}`}>
                                        <img src={require(`../../images/${item}.png`)} alt="" style={{width: '35px', height: '35px'}} />
                                      </div>
                                    </div>
                                    <div className={`card-${getColorClass(item)}`}>
                                      <h4 className={`mb-0 text-${getColorClass(item)}`}>
                                        {evaluate(`(1 - ${UserCunsumer.user.fees[item]}) * 100`).toFixed(1)}%
                                      </h4>
                                      <small className="text-muted">{item}</small>
                                    </div>
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default UserWhite;