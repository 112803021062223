import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import moment from "moment";
import 'moment/locale/ru'
import Checkbox from '@material-ui/core/Checkbox';

import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

const ExportedOrders = (props) => {

  moment.locale('ru');

  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showtotable, setShowtotable] = React.useState();
  const [btnload, setBtnload] = React.useState(false);
  const OrderCunsumer = useContext(OrderContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    return () => OrderCunsumer.clean();
  }, []);

  useEffect(() => {
    if (OrderCunsumer.orderArray && OrderCunsumer.orderArray.length > 0){
      setShowtotable(true);
    } else {
      setShowtotable(false);
    }
  }, [OrderCunsumer.orderArray]);

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clean();
      setRedirect(true);
    }
  }, [OrderCunsumer.redirect]); 

  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'SUCCESS':
        return 'bg-label-success'
      case 'EDITED':
        return 'bg-label-warning'
      case 'EXPIRED':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'НОВАЯ'
      case 'PENDING':
        return 'В РАБОТЕ'
      case 'SUCCESS':
        return 'ВЫПОЛНЕННАЯ'
      case 'EDITED':
        return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
      case 'EXPIRED':
        return 'ПРОСРОЧЕННАЯ'
      default:
        return status;
    }
  }

  let columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Внешний id",
      selector: (row) => row.externalId,
      cellExport: (row) => row.externalId,
      sortable: true,
      width: "115px",
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      sortable: true,
      width: "250px",
      cell: (row) => {
        return (
          <span
            style={{ fontSize: 12 }}
            className={`badge ${getBadgeClass(row.status)}`}
          >
            {getStatus(row.status)}
          </span>
        );
      },
    },
    {
      name: "Карта",
      selector: (row) => row.cardNumber,
      cellExport: (row) => row.cardNumber,
      sortable: true,
      width: "170px",
      cell: (row, index) => {
        return (
          <div>
            <span className={`mr-2`}>{!isEmpty(row.cardNumber) ? row.cardNumber : 'еще не получено'}</span>
          </div>
        )
      },
    },
    {
      name: "Сумма",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      sortable: true,
      width: "90px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    {
      name: "Банк",
      selector: (row) => row.cardProvider,
      cellExport: (row) => row.cardProvider,
      sortable: true,
      width: "110px",
      cell: (row) => {
        return `${!isEmpty(row.cardProvider) ? row.cardProvider : 'еще не получено'}`;
      },
    },
    {
      name: "Коммент:",
      selector: (row) => row.comment,
      cellExport: (row) => row.comment,
      width: "200px",
      cell: (row) => {
        return `${row.comment}`;
      },
    },
    {
      name: "Дата в процессе",
      sortable: true,
      selector: (row) => row.pendingAt,
      cellExport: (row) => row.pendingAt,
      width: "180px",
      cell: (row) => {
        return (
          <span>{!isEmpty(row.pendingAt) ? moment(row.pendingAt).format("D/MM/YYYY, H:mm:ss") : 'новая'}</span>
        );
      },
    },
    {
      name: "Дата создания",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    {
      name: "id карты",
      selector: (row) => row.cardId,
      cellExport: (row) => row.cardId,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return `${!isEmpty(row.cardId) ? row.cardId : 'новая'}`;
      },
    },
    {
      name: "User id",
      selector: (row) => row.userId,
      cellExport: (row) => row.userId,
      sortable: true,
      width: "90px",
    },
    {
      name: "Хеш",
      selector: (row) => row.hash,
      cellExport: (row) => row.hash,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return `${row.hash}`;
      },
    },
    {
      name: "Сallback",
      selector: (row) => row.callbackUrl,
      cellExport: (row) => row.callbackUrl,
      sortable: true,
      width: "400px",
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },   
  ];

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    export: true,
    print: false,
    filter: false,
    exportHeaders: false
  };

  const handler = async (event) => {
    await OrderCunsumer.updateState(event.target.name, event.target.value);
  };

  const moveToOrders = async () => {
    await OrderCunsumer.updateState("orders", OrderCunsumer.orderArray);
  };

  const hendleSelected = async (state) => {
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
  }

  const submit = async () => {
    setBtnload(true)
    return OrderCunsumer.getExport()
      .then(() => sendNotification("success", "Успех", "Заявки на экспорт получены."))
      .then(() => setBtnload(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      {renderRedirect()}
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle">
                      <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Заявки на экспорт
                    </h4>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  <div className="col-md-12 col-12">
                    <div className="card">
                      <div className="row">
                        <div className="col-md-7 col-12 grid-margin ">
                          <div className="card-body p-5">
                            <h4 className="card-title text-primary mb-5">
                              Отправить коллбэк <i className='icon-menu tf-icons mdi mdi-database-import ms-2 mb-2'></i>
                            </h4>
                            <form onSubmit={(e) => e.preventDefault()}>
                              <div className="form-group">
                                <label className="mb-4" style={{ fontSize: 16 }}>
                                  <strong>ID заявки:</strong>
                                  <br/><span className="text-muted" style={{textTransform: 'none', fontSize: '13px'}}>(введите id заявки от которой надо забрать новые заявки)</span>
                                </label>
                                <input
                                  type="text"
                                  onChange={handler}
                                  className="form-control mb-2"
                                  placeholder="3000"
                                  name="exportid"
                                  id="exportid"
                                  style={{height: '40px'}}
                                />
                                <span className="text-muted" style={{textTransform: 'none', fontSize: '13px'}}>(пример: если указать id 2004, посчитает все заявки с id больше чем 2004)</span>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-5 col-12">
                          <div className=" h-100">
                            {btnload === true ? (
                              <div className="text-end pe-5" style={{marginTop: '25%'}}>
                                <button className="btn btn-outline-info waves-effect ms-5 mb-5" type="button" >
                                  Загрузка...<span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="text-end pe-5 media-m-5 media-text-start" style={{marginTop: '25%'}}>
                                  <button
                                    onClick={(e) => submit(e)}
                                    className={`btn btn-outline-success`}
                                    style={{
                                      fontSize: "16px",
                                    }}
                                  >
                                    Получить заявки <i className=' tf-icons mdi mdi-database-import-outline ms-2'></i>
                                  </button>
                                </div>
                                {showtotable === true ? (
                                  <div className=" text-end pe-5" style={{marginTop: '10%'}}>
                                    <label className="mb-4" style={{ fontSize: 15 }}>
                                      <strong>Поместить в таблицу только выделенные</strong>
                                    </label>
                                    <button
                                      onClick={(e) => moveToOrders()}
                                      className={`btn btn-outline-warning`}
                                      style={{fontSize: "16px",}}
                                    >
                                      {OrderCunsumer?.orderArray && OrderCunsumer.orderArray.length} заявки 
                                      <i className=' tf-icons mdi mdi-send-check-outline ms-2'></i>
                                    </button>
                                  </div>
                                ) : ('')}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
                {!isEmpty(OrderCunsumer.orders) &&
                  <div className="row ">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <DataTableExtensions {...tableData} >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  selectableRows={true}
                                  selectableRowsComponent={Checkbox}
                                  selectableRowsComponentProps={{ color: 'primary' }}
                                  selectableRowsVisibleOnly={true}
                                  selectableRowsHighlight={true}
                                  onSelectedRowsChange={hendleSelected}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </DataTableExtensions>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportedOrders;