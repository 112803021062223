import React, { useContext, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import ConfirmWithdrawModal from "./modals/ConfirmWithdrawModal";

import { UserContext } from "../../context/users-context";
import { WithdrawContext } from "../../context/withdrawal-context";

moment.locale('ru'); 

const WithdrawalOrders = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showConfirmWithdrawModal, setShowConfirmWithdrawModal] = React.useState(false);
  const UserCunsumer = useContext(UserContext);
  const WithdrawCunsumer = useContext(WithdrawContext);

  useEffect(() => {
    WithdrawCunsumer.getWithdrawsOrders();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []);  

  const updateData = () => {
    WithdrawCunsumer.clear();
    setTimeout(() => {
      WithdrawCunsumer.getWithdrawsOrders();
    }, 100);
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case 'PENDING':
        return 'bg-label-primary'
      case 'COMPLETED':
        return 'bg-label-success'
      case 'ERROR':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'В процессе'
      case 'COMPLETED':
        return 'Выполненая'
      case 'ERROR':
        return 'Ошибочная'
      default:
        return status;
    }
  }

  let columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      width: '60px',
      sortable: true,
    },
    {
      name: "user ID",
      selector: (row) => row.userId,
      cellExport: (row) => row.userId,
      width: '90px',
      sortable: true,
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      sortable: true,
      width: '110px',
      cell: (row) => {
        return (
          <span style={{ fontSize: 12 }} className={`badge ${getBadgeClass(row.status)}`}>
            {getStatus(row.status)}
          </span>
        );
      },
    },
    {
      name: "Тип вывода",
      selector: (row) => row.type,
      cellExport: (row) => row.type,
      sortable: true,
      width: '180px',
      cell: (row) => {
        return (
          <span style={{ fontSize: 12 }} className={`badge ${row.type === 'usdt' ? 'bg-label-warning' : 'bg-label-warning'}`}>
            {row.type === 'usdt' ? 'Вывод в USDT' : "Вывод на баланс выплат"}
          </span>
        );
      },
    },
    {
      name: "Сумма вывода",
      selector: (row) => row.baseAmount,
      cellExport: (row) => row.baseAmount,
      sortable: true,
      minWidth: '135px',
      cell: (row, index) => {
        return (
          <div>
            <span className={``}>{row.baseAmount} RUB</span>
          </div>
        )
      },
    },
    {
      name: "Сумма к зачислению",
      selector: (row) => row.totalAmount,
      cellExport: (row) => row.totalAmount,
      sortable: true,
      minWidth: '175px',
      cell: (row) => {
        return `${row.totalAmount} ${row.currency}`;
      },
    },
    {
      name: "Курс",
      selector: (row) => row.course,
      cellExport: (row) => row.course,
      sortable: true,
      minWidth: '50px',
      cell: (row) => {
        return row.course;
      },
    },
    {
      ignoreRowClick: false,
      button: true,
      name: "Действия",
      width: "150px",
      cell: (row) => {
        return (
          <button
            className="btn btn-outline-success waves-effect btn-sm"
            onClick={() => handler(row.id, "COMPLETED", row.type)}
          >
            Выполнено
          </button>
        );
      },
    },
    {
      ignoreRowClick: false,
      button: true,
      name: "Действия",
      width: "150px",
      cell: (row) => {
        return (
          <button
            className="btn btn-outline-danger waves-effect btn-sm"
            onClick={() => handler(row.id, "ERROR", row.type)}
          >
            Отклонить
          </button>
        );
      },
    },
    {
      name: "Кошелек",
      sortable: true,
      minWidth: '200px',
      selector: (row) => row.wallet,
      cellExport: (row) => row.wallet,
      cell: (row) => {
        return (
          <span>{row.wallet}</span>
        );
      },
    },
    {
      name: "Комментарий",
      sortable: true,
      minWidth: '180px',
      selector: (row) => row.comment,
      cellExport: (row) => row.comment,
      cell: (row) => {
        return (
          <span>{row.comment}</span>
        );
      },
    },
    {
      name: "Баланс до",
      selector: (row) => row.metadata.balance_before,
      cellExport: (row) => row.metadata.balance_before,
      sortable: true,
      width: "110px",
      cell: (row) => {
        return `${row.metadata.balance_before} RUB`;
      },
    },
    {
      name: "Баланс после",
      selector: (row) => row.metadata.balance_after,
      cellExport: (row) => row.metadata.balance_after,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.metadata.balance_after} RUB`;
      },
    },
    {
      name: "Дата создания",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      sortable: true,
      width: "170px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    {
      name: "Хеш",
      selector: (row) => row.hash,
      cellExport: (row) => row.hash,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return `${row.hash}`;
      },
    },
  ];

  const tableData = {
    columns: columns,
    data: WithdrawCunsumer.withdraws,
    export: true,
    print: false,
    filter: true,
    filterPlaceholder: 'Универсальный поиск...'
  };

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const handler = async (id, status, type) => {
    await WithdrawCunsumer.updateState('activeOrder', id);
    await WithdrawCunsumer.updateState('status', status);
    await WithdrawCunsumer.updateState('type', type);

    if (type === 'usdt' && status === 'COMPLETED') {
      setShowConfirmWithdrawModal(true);
    } else {
      if (window.confirm(`Вы уверены, что хотите изменить статус вывода id: ${id} - на '${status === "COMPLETED" ? "Выполненая" : "Отклонена"}' ?`)) {
        await WithdrawCunsumer.updateWithdrawStatus()
        .then((res) => {
          if (res.status === 'ok') {
            sendNotification("success", "Статус вывода изменен", res.message);
            WithdrawCunsumer.getWithdrawsOrders();
          }
        });
      } else {
        return;
      }   
    }
  };

  const togglePopUp = useCallback(
    (trigger) => {
      WithdrawCunsumer.updateState('activeOrder', '');
      WithdrawCunsumer.updateState('status', '');
      WithdrawCunsumer.updateState('type', '');
      WithdrawCunsumer.updateState('hash', '');
      setShowConfirmWithdrawModal(false);
    },[] 
  );

  return (
    <>
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle media-fs-17">
                      <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Заявки на вывод баланса
                    </h4>
                  </div>
                </div>
              </div>
            </nav>
            
            <ConfirmWithdrawModal
              updateData={() => updateData()}
              togglePopUp={togglePopUp}
              show={showConfirmWithdrawModal}
            />

            { loading === true ? (
              <Loader />
            ) : (
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4">
                    <div className="col-md-12 grid-margin stretch-card">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-6">
                              <h4 className="card-title text-primary ms-3">Заявки на вывод</h4>
                            </div>
                          </div>
                          {isEmpty(WithdrawCunsumer.withdraws) ? (
                            <h5 className="text-center text-muted my-5">Заявок пока нету...</h5>
                          ) : (
                            <div className="table-responsive">
                              <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <DataTableExtensions {...tableData} >
                                  <DataTable
                                    title={false}
                                    columns={columns}
                                    highlightOnHover={true}
                                    striped={true}
                                    pointerOnHover={true}
                                    className={"table dataTable"}
                                    pagination={true}
                                    noHeader={true}
                                    theme="solarized"
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[
                                      20,
                                      40,
                                      100,
                                      200,
                                      300,
                                    ]}
                                  />
                                </DataTableExtensions>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawalOrders;