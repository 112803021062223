import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import { sendNotification } from "../../../helpers/notification";
import Loader from "../../../components/Loader";
import { UserContext } from "../../../context/users-context";

const ModalEditBallance = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [user, setUser] = React.useState();
  const [amountError, setAmountError] = React.useState(false);
  const UserCunsumer = useContext(UserContext);
  const { show } = props;
  const simulateClick = React.useRef(null);

  useEffect(() => {
    if (!isEmpty(UserCunsumer.activeUser)) {
      let user = UserCunsumer.users.filter((obj) => {
        return obj.id === UserCunsumer.activeUser;
      });
      setUser(user[0]);
      setAmountError(false)
      setLoading(false);
    }
  }, [UserCunsumer.activeUser]);

  const updateField = async (event) => {
    if (event.target.name === 'balanceAmount' && amountError) {
      setAmountError(false)
    }
    await UserCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setSubmitLoading(true);
    if (isEmpty(UserCunsumer.balanceAmount)) {
      sendNotification("danger", "Введите сумму", "Вы не ввели сумму корректировки.")
      setAmountError(true);
      setSubmitLoading(false);
      return;
    }
    if (window.confirm(`Вы уверены что хотите добавить пользователю ${user.email} - на баланс сумму ${UserCunsumer.balanceAmount} ?`)) {
      return UserCunsumer.updateBallance()
      .then((res) => {
        if (res.status === 'success') {
          setSubmitLoading(false);
          sendNotification("success", "Успех", "Баланс обновлен.")
          props.updateData();
          simulateClick.current.click()
        } else {
          props.updateData();
          simulateClick.current.click()
        }
      });
    } else {
      setSubmitLoading(false);
      return;
    }
  };

  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content py-3 px-4">
            {loading ? (
              <Loader /> 
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title my-3" id="exampleModalLabel">
                    Корректировка баланса для <br/> <span className="text-primary">{user.email}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={props.togglePopUp}
                    className="btn-close-x"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-5 mt-2">
                          <div className="alert alert-info px-4 pt-4" role="alert">
                            <p>
                              <i className='tf-icons mdi mdi-clipboard-alert-outline me-2 '></i>
                              <strong>Если Вы хотите отнять от баланса сумму</strong>, <br/>то вам нужно в сумме корректировки ввести <br/> сумму со знаком минус. Пример: "-1000".
                            </p>
                          </div>
                        </div>
                        <div className="form-group" style={{ fontSize: 16 }}>
                          <p className="mb-2">ID пользователя: <span className="text-white"> {user.id}</span></p>
                          <p className="mb-2">Email: <span className="text-white"> {user.email}</span></p>
                          <p className="mb-2">Текущий баланс:  <span className="text-white"> {user.balance}</span></p>
                        </div>                        
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className={`form-group ${amountError && 'has-danger'}`}>
                          <label className="my-3 text-primary"  style={{ fontSize: 16 }} htmlFor="comment"> Прибавить к балансу: </label>
                          <input
                            onChange={updateField}
                            className={`form-control ${amountError && 'form-control-danger'}`}
                            name="balanceAmount"
                            id="balanceAmount"
                            placeholder={`10000`}
                            value={UserCunsumer.balanceAmount ? UserCunsumer.balanceAmount : ''}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="my-3 text-primary"  style={{ fontSize: 16 }} htmlFor="comment"> Комментарий: </label>
                          <input
                            type="text"
                            onChange={updateField}
                            className="form-control"
                            name="comment"
                            id="comment"
                            value={UserCunsumer.comment ? UserCunsumer.comment : ''}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer mt-3">
                  <button type="button" ref={simulateClick} className="btn rounded-pill btn-outline-danger waves-effect" onClick={props.togglePopUp}>Закрыть</button>
                  {!submitLoading ? (
                    <button type="button" className="btn rounded-pill btn-outline-success waves-effect" onClick={(e) => submit(e)}>Сохранить</button>
                  ) : (
                    <button className="btn btn-outline-info rounded-pill waves-effect" type="button" >
                      Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditBallance;