import React from "react";
import { User } from "../helpers/api";
import { isEmpty } from "lodash";
import  secureLocalStorage  from  "react-secure-storage";
import { sendNotification } from "../helpers/notification";

export const UserContext = React.createContext({});

export class UserProvider extends React.Component {

  state = {
    userMe: () => {
      return User.current()
        .then((res) => {
          this.setState({ user: res.body.user });
        })
        .catch((error) => {
          console.log(error.message);
          if (error.message === 'Forbidden') {
            this.setState({
              redirectPath: `/auth/login`,
              redirectMessage: "Время сесии истекло ⚠️",
              redirect: true,
            });
          } else {
            this.setState({
              redirectPath: `/${!isEmpty(secureLocalStorage.getItem('role') ? secureLocalStorage.getItem('role') : '/manager/home' )}/home`,
              redirectMessage: "Что то пошло не так. ⚠️",
              redirect: true,
            });
          }
        });
    },
    getUserOrders: async (filter) => {
      await User.getUserOrders(filter)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    getUserStat: async () => {
      return User.getUserStat()
        .then((res) => {
          this.setState({ stats: res.body });
          return { status: 'success' }
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    setOpenMenu: async (bool) => {
      this.setState({ openMenu: bool });
    },
    getQrCode: () => {
      let params = {};
      return User.createTwoFaToken(params)
        .then((res) => {
          if (res.body.qrcode && res.body.token) {
            sendNotification("success", "Удачно", `QR Код получен`);
            return this.setState({ qrcode: res.body.qrcode, token: res.body.token });
          }
        })
        .catch((error) => {
          return sendNotification("warning", "Ошибка", "Не удалось получить код для 2FA");
        });
    },
    confirmTwoFa: () => {
      let params = {};
      params.code = this.state.code;
      return User.confirmTwoFa(params)
        .then((res) => {
          sendNotification("success", "Успешно", `2FA Код активирован для профиля.`);
          return {...res, status: 'success'}
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return {...error?.response?.body, status: 'error'}
        });
    },

    getCourse: async () => {
      return User.getCourse()
        .then((res) => {
          this.setState({ withdraw_course: res.body.bids[0].price });
          return { status: 'success' }
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", 'Не удалось загрузить курс с биржы.');
          return { status: 'error' }
        });
    },

    createWithdraw: async () => {
      let params = {};
      params.base_amount = this.state.withdraw.amount;
      params.type = this.state.withdraw.type;
      params.comment = this.state.withdraw.comment;

      if (this.state.withdraw.type === 'usdt') {
        params.currency = "USDT";
        params.wallet = this.state.withdraw.wallet;
      } 

      if (this.state.withdraw.type === 'internal') {
        params.currency = "USDT";
        params.wallet = 'Баланс выплат';
      }

      return User.createWithdraw(params)
        .then((res) => {
          if (res.body.message === 'Успешно') {
            sendNotification("success", "Заявка создана", 'Заявка на вывод успешно создана.');
          }
          return res.body.message;
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },

    getWithdrawalsOrders: async () => {
      await User.getWithdrawalsOrders()
        .then((res) => {
          this.setState({ withdrawalsOrders: res.body });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },

    getPengingWithdrawsCount: async () => {
      await User.getPengingWithdrawsCount().then((res) => { 
          this.setState({ pendingWithdrawsCount: res.body.count });
        }).catch((error) => { 
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", 'Нету доступа.');
        });
    },
    
    clear: () => {
      this.setState({
        user: null,
        users: null,
        redirectPath: null,
        redirectMessage: null,
        redirect: null,
        qrcode: null,
        token: null,
        activeUser: null,
        withdraw_course: null,
        withdraw: {},
        pendingWithdrawsCount: null,
      });
    },
    getUsers: async (params) => {
      await User.getUsers(params)
        .then((res) => {
          this.setState({ users: res.body.users });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          this.setState({
            redirectPath: "/auth/login",
            redirectMessage: "Недостаточно прав ⚠️",
            redirect: true,
          });
        });
    },
    updateBallance: async () => {
      let params = {};
      params.amount = this.state.balanceAmount;
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      return User.updateBallance(this.state.activeUser, params)
        .then((res) => {
          return { status: 'success', ...res.body }
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
      }),

    user: {},
    redirectPath: "",
    redirectMessage: "",
    redirect: false,
    activeUser: null,
    openMenu: true,
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}