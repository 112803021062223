import React from "react";

const Loader = () => {
  
  return (
    <div className="text-center" style={{marginTop: '300px'}}>
      <div className="spinner-grow text-primary me-1" style={{width: '20px', height: '20px'}} role="status"></div>
      <div className="spinner-grow text-primary me-1" style={{width: '20px', height: '20px'}}  role="status"></div>
      <div className="spinner-grow text-primary me-1" style={{width: '20px', height: '20px'}}  role="status"></div>
    </div>
  );
};

export default Loader;