import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import Loader from '../components/Loader';

const NoRoute = () => {
  
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        setLoading(false);
    }, []);

    return(
      <>
        {loading && <Loader/>}
        <div className="misc-wrapper">
          <div className="text-center mt-5">
            <h3 className="mb-2 mx-2">Страница не найдена 🚀</h3>
            <p className="mb-4 mx-2">Oopps!! The page you were looking for doesn't exist.</p>
            <div>
              <Link to={'/'} className="btn btn-primary text-center my-4 waves-effect waves-light">Вернуться на главную </Link>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <img src={require('../images/illustrations/misc-coming-soon-illustration.png')} alt="misc-coming-soon" className="img-fluid zindex-1 media-w-img-error " width="190"/>
          </div>
        </div>
    </>

    )
};

export default NoRoute;