import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import Loader from "../../components/Loader";
import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";

const UserStats = () => {

  const [loading, setLoading] = React.useState(true);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    UserCunsumer.getUserStat();
    setLoading(false)
    return () => UserCunsumer.clear();
  }, []);

  return (
    <>
      {loading ? (
        < Loader/>
      ) : (   
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <UserBar />
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Статистика
                      </h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  {UserCunsumer.stats &&
                    <div className="row gy-4">
                      <div className="col-12 col-xl-12">
                        <div className="card p-4 media-p-none">
                          <div className="card-header d-flex justify-content-between">
                            <div className="card-title m-0">
                              <small className="text-muted">Cтатистикa</small>
                            </div>
                          </div>
                          <div className="card-body pb-3">
                            <ul className="nav nav-tabs nav-tabs-widget pb-3 gap-4 mx-1 d-flex flex-nowrap" role="tablist">
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="true">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-success waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon1.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-sales-id" aria-controls="navs-sales-id" aria-selected="false" tabIndex="-1">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-primary waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon2.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabIndex="-1">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-instagram waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon3.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <span className="tab-slider" style={{left: '0px', width: '112px', bottom: '0px'}}></span>
                            </ul>
                            <div className="tab-content p-0 pt-5 ms-0 ms-sm-2">
                              <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                                <div className="table-responsive text-nowrap">
                                  <h4>Статистика за все время</h4>
                                  <hr/>
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th className="fw-medium ps-0 text-heading">Статус</th>
                                        <th className="pe-0 fw-medium text-heading media-d-none"></th>
                                        <th className="pe-0 fw-medium text-heading">Заявки</th>
                                        <th className="pe-0 text-end text-heading">Сумма в RUB</th>
                                        <th className="pe-0 text-end text-heading">Сумма в USDT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-success">Выполненные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}}>* количество заявок в статусах "Успешная" <br/> и "Выполненная с корректировкой"</td>
                                        <td className="pe-0 text-success">
                                          {UserCunsumer?.stats?.all?.successCount ? UserCunsumer.stats.all.successCount : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                        <td className="pe-0 text-success text-end fw-semibold h6">
                                          {UserCunsumer?.stats?.all?.successSum && !isEmpty(UserCunsumer?.stats?.all?.successSum.toString()) || UserCunsumer?.stats?.all?.successSum === 0 ? UserCunsumer.stats.all.successSum : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </td>
                                        <td className="pe-0 text-success text-end fw-semibold h6">
                                          {UserCunsumer?.stats?.all?.successSumUsdt && !isEmpty(UserCunsumer?.stats?.all?.successSumUsdt.toString()) || UserCunsumer?.stats?.all?.successSumUsdt === 0 ? UserCunsumer.stats.all.successSumUsdt.toFixed(2) : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-danger">Просроченные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}} >* количество заявок в статусе "В процессе" (не финальный статус). <br/> По ним ожидаем поступление на карту.</td>
                                        <td className="text-danger pe-0">
                                          {UserCunsumer?.stats?.all?.expiredCount ? UserCunsumer.stats.all.expiredCount : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <hr/>
                            <div className="tab-content p-0 pt-5 ms-0 ms-sm-2">
                              <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                                <div className="table-responsive text-nowrap">
                                  <h4>Статистика за сегодня</h4>
                                  <hr/>
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th className="fw-medium ps-0 text-heading">Статус</th>
                                        <th className="pe-0 fw-medium text-heading media-d-none"></th>
                                        <th className="pe-0 fw-medium text-heading">Заявки</th>
                                        <th className="pe-0 text-end text-heading">Сумма в RUB</th>
                                        <th className="pe-0 text-end text-heading">Сумма в USDT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-success">Выполненные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}}>* количество заявок в статусах "Успешная" <br/> и "Выполненная с корректировкой"</td>
                                        <td className="pe-0 text-success">
                                          {UserCunsumer?.stats?.day?.successCount ? UserCunsumer.stats.day.successCount : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                        <td className="pe-0 text-success text-end fw-semibold h6">
                                          {UserCunsumer?.stats?.day?.successSum && !isEmpty(UserCunsumer?.stats?.day?.successSum.toString()) || UserCunsumer?.stats?.day?.successSum === 0 ? UserCunsumer.stats.day.successSum : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </td>
                                        <td className="pe-0 text-success text-end fw-semibold h6">
                                          {UserCunsumer?.stats?.day?.successSumUsdt && !isEmpty(UserCunsumer?.stats?.day?.successSumUsdt.toString()) || UserCunsumer?.stats?.day?.successSumUsdt === 0 ? UserCunsumer.stats.day.successSumUsdt.toFixed(2) : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-primary">В процессе</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}}>* заявки в статусе "Просроченная" <br/>клиент не успел в сроки и оператор перевел ее в просроченную.</td>
                                        <td className="text-primary pe-0">
                                          {UserCunsumer?.stats?.day?.pendingCount ? UserCunsumer.stats.day.pendingCount : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                        <td className="text-primary pe-0 text-end fw-semibold h6">
                                          {UserCunsumer?.stats?.day?.pendingSum && !isEmpty(UserCunsumer?.stats?.day?.pendingSum.toString()) || UserCunsumer?.stats?.day?.pendingSum === 0 ? UserCunsumer.stats.day.pendingSum : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-danger">Просроченные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}} >* количество заявок в статусе "В процессе" (не финальный статус). <br/> По ним ожидаем поступление на карту.</td>
                                        <td className="text-danger pe-0">
                                          {UserCunsumer?.stats?.day?.expiredCount ? UserCunsumer.stats.day.expiredCount : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <hr/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserStats;