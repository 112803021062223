import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";

import { sendNotification } from "../../helpers/notification";
import Loader from "../../components/Loader";
import { CardContext } from "../../context/cards-context";
import { UserContext } from "../../context/users-context";
import AdminBar from "../../components/AdminBar";

const AddCard = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [showCard, setShowCard] = React.useState(false);
  const [error, setError] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const CardCunsumer = useContext(CardContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    setLoading(false);
  }, []); 

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{pathname: redirectPath,}}/>
      );
    }
  };

  const updateField = async (event) => {
    if (error[event.target.name]) {
      setError({ ...error, [event.target.name]: false})
    }
    await CardCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setSubmitLoading(true);
    setError(false);
    if (isEmpty(CardCunsumer.number)) {
      sendNotification("danger", "Ведите номер карты", "Номер карты обязателен для добавления");
      setError({ ...error, number: true})
      setSubmitLoading(false);
      return;
    }
    if (isEmpty(CardCunsumer.provider)) {
      setError({ ...error, provider: true})
      sendNotification("danger", "Введите провайдера", "Провайдер карты обязателен для добавления");
      setSubmitLoading(false);
      return;
    }
    if (isEmpty(CardCunsumer.totalDayPaymentLimit)) {
      setError({ ...error, totalDayPaymentLimit: true})
      sendNotification("danger", "Введите дневной лимит по платежам", "");
      setSubmitLoading(false);
      return;
    }
    if (isEmpty(CardCunsumer.totalDayLimit)) {
      setError({ ...error, totalDayLimit: true})
      sendNotification("danger", "Введите дневной лимит по сумме", "");
      setSubmitLoading(false);
      return;
    }
    if (isEmpty(CardCunsumer.totalMonthLimit)) {
      setError({ ...error, totalMonthLimit: true})
      sendNotification("danger", "Введите месячный лимит по сумме", "");
      setSubmitLoading(false);
      return;
    }
    if (isEmpty(CardCunsumer.status)) {
      setError({ ...error, status: true})
      sendNotification("danger", "Выберите статус карты", "");
      setSubmitLoading(false);
      return;
    }
    return CardCunsumer.createCard()
      .then((res) => {
        if (res.status === 'success') {
          setSubmitLoading(false);
          sendNotification("success", "Карта добавлена", "Карта успешно добавлена.");
		  CardCunsumer.clearForm();
		  setShowCard(true);
        } else {
          setSubmitLoading(false);
		  CardCunsumer.clearForm();
        }
      });
  };

  const getStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активная'
      case 'ONHOLD':
        return 'На удержании'
      case 'NOTACTIVE':
        return 'Не активная'
      default:
        return status;
    }
  }

  return (
    <>
      {renderRedirect()}
      <div className='layout-wrapper layout-content-navbar'>
			<div className='layout-container'>
				<AdminBar />
				<div className='layout-page'>
					{loading === true ? (
						<Loader />
					) : ( 
						<>
							<nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
								<div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
									<div className="navbar-nav align-items-center">
										<div className="nav-item navbar-search-wrapper mb-0">
											<h4 className="align-middle">
												<a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
												<i className="mdi mdi-menu mdi-24px me-3"></i>
												</a>
												Добавить карту
											</h4>
										</div>
									</div>
								</div>
							</nav> 
							<div className="content-wrapper">
								<div className="container-xxl flex-grow-1 container-p-y">
									<div className="row gy-4 mb-4">
										<div className="col-md-12 col-12">
											<div className="card">
												<div className="card-body p-5 media-p-3" onSubmit={(e) => e.preventDefault()}>
													<h4 className="card-title text-primary">
														Добавление новой карты <i className='tf-icons mdi mdi-credit-card-plus-outline ms-2'></i>
													</h4>
													<hr />
													<div className="row">
														<div className="col-md-4 mt-3">
															<div className="alert alert-success alert-dismissible px-4" role="alert">
																<h4 className="alert-heading d-flex align-items-center mt-2">
																	<i className="mdi mdi-credit-card mdi-24px me-2"></i> Карта
																</h4>
																<hr/>
																<p className="mb-3" style={{textTransform: 'uppercase'}}>
																	{CardCunsumer.number ? CardCunsumer.number : '**** **** **** ****'}
																</p>
																<hr/>
																<p className="mb-3" style={{textTransform: 'uppercase', fontSize: '13px'}}>
																	{CardCunsumer.provider ? CardCunsumer.provider : 'банк'}
																</p>
																<p className="mb-0" style={{textTransform: 'uppercase', fontSize: '13px'}}>
																	{CardCunsumer.status ? getStatus(CardCunsumer.status) : 'статус'}
																</p>
															</div>
														</div>
														<div className="col-md-4">
															<div className={`form-group ${error.number && 'has-danger'}`}>
																<label className="mb-2 "  style={{ fontSize: 13 }} htmlFor="number">
																	<strong>Номер карты:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className={`form-control ${error.number ? 'form-control-danger' : ''}`}
																	name="number"
																	placeholder="1234 1234 1234 1234"
																	id="number"
																	value={CardCunsumer.number ? CardCunsumer.number : ''}
																/>
															</div>
															<div className={`form-group ${error.provider && 'has-danger'}`}>
																<label className="my-2"  style={{ fontSize: 13 }} htmlFor="provider">
																	<strong>Банк карты:</strong>
																</label>
																<select
																	onChange={updateField}
																	className={`form-control ${error.provider ? 'form-control-danger' : 'text-primary'}`}
																	name="provider"
																	id="provider"
																	value={CardCunsumer.provider ? CardCunsumer.provider : ''}
																>
																	<option value="" defaultValue>Выберите банк</option>
																	<option value={"card_uah"}>card_uah</option>
																	<option value={"sberbank"}>sberbank</option>
																	<option value={"tinkoff"}>tinkoff</option>
																	<option value={"yandex"}>yandex</option>
																	<option value={"visa/master"}>visa/master</option>
																</select>
															</div>
															<div className={`form-group ${error.status && 'has-danger'}`}>
																<label className="my-2 "  style={{ fontSize: 13 }} htmlFor="status">
																	<strong>Статус карты:</strong>
																</label>
																<select
																	onChange={updateField}
																	value={CardCunsumer.status ? CardCunsumer.status : ''}
																	className={`form-control ${error.status ? 'form-control-danger' : 'text-primary'}`}
																	name="status"
																	id="status"
																	required
																>
																	<option defaultValue>Выберите статус</option>
																	<option value={"ACTIVE"}>Активная</option>
																	<option value={"NOTACTIVE"}>Не активная</option>
																</select>
															</div>
														</div>
														<div className="col-md-4">
															<div className={`form-group ${error.totalDayPaymentLimit && 'has-danger'}`}>
																<label className="mb-2 " style={{ fontSize: 13 }} htmlFor="totalDayPaymentLimit">
																	<strong>Верхний лимит платежей в день по карте (шт):</strong>
																</label>
																<input
																	type="text"
																	className={`form-control ${error.totalDayPaymentLimit ? 'form-control-danger' : ''}`}
																	onChange={updateField}
																	placeholder="5"
																	name="totalDayPaymentLimit"
																	id="totalDayPaymentLimit"
																	value={CardCunsumer.totalDayPaymentLimit ? CardCunsumer.totalDayPaymentLimit : ''}
																/>
															</div>
															<div className={`form-group ${error.totalDayLimit && 'has-danger'}`}>
																<label className="my-2 " style={{ fontSize: 13 }} htmlFor="totalDayLimit">
																	<strong>Верхний лимит суммы в день:</strong>
																</label>
																<input
																	className={`form-control ${error.totalDayLimit ? 'form-control-danger' : ''}`}
																	onChange={updateField}
																	placeholder="50000"
																	name="totalDayLimit"
																	id="totalDayLimit"
																	type="text"
																	value={CardCunsumer.totalDayLimit ? CardCunsumer.totalDayLimit : ''}
																/>
															</div>
															<div className={`form-group ${error.totalMonthLimit && 'has-danger'}`}>
																<label className="my-2 " style={{ fontSize: 13 }} htmlFor="totalMonthLimit">
																	<strong>Верхний лимит суммы в месяц:</strong>
																</label>
																<input
																	className={`form-control ${error.totalMonthLimit ? 'form-control-danger' : ''}`}
																	onChange={updateField}
																	name="totalMonthLimit"
																	placeholder="1500000"
																	id="totalMonthLimit"
																	type="text"
																	value={CardCunsumer.totalMonthLimit ? CardCunsumer.totalMonthLimit : ''}
																/>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<div className='form-group'>
																<label className="my-2 " style={{ fontSize: 13 }} htmlFor="comment">
																	<strong>Комментарий:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control "
																	name="comment"
																	id="comment"
																	value={CardCunsumer.comment ? CardCunsumer.comment : ''}
																/>
															</div>
														</div>
													</div>
													<hr />
													<div className="mt-4 d-flex ">
														{!submitLoading ? (
															<>
																<button type="button" onClick={(e) => submit(e)} className="btn btn-outline-success waves-effect media-btn-sm">Добавить карту</button>
																<button type="button" onClick={CardCunsumer.clearForm} className="btn btn-outline-secondary media-btn-sm waves-effect ms-3">Очистить</button>
															</>
														) : (
															<button type="button" className="btn btn-outline-info waves-effect">
																Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
															</button>
														)}
														{showCard &&
															<a href='/admin/cards' className="btn btn-outline-info waves-effect ms-auto">Перейти к картам</a>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
          		</div>
        	</div>
      	</div>
    </>
  );
};

export default AddCard;