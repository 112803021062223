import React, { useContext, useEffect } from "react";
import { OrderContext } from "../../context/orders-context";
import { PaymentsContext } from "../../context/payments-context";
import { isEmpty } from "lodash";

const SummSelected = (props) => {

  const [summ, setSumm] = React.useState(0);
  const OrderCunsumer = useContext(OrderContext);
  const PayCunsumer = useContext(PaymentsContext);

  useEffect(() => {
    if (props.page === 'payments' ){
      if (!isEmpty(PayCunsumer.orderArray)) {
        let total = 0;
        PayCunsumer.orderArray.map((item) => {
          total = parseFloat(total) + parseFloat(item.amount)
          return false;
        })
        setSumm(total.toFixed(2));
      } else {
        setSumm(0);
      }
    } else {
      if (!isEmpty(OrderCunsumer.orderArray)) {
        let total = 0;
        OrderCunsumer.orderArray.map((item) => {
          total = parseFloat(total) + parseFloat(item.amount)
          return false;
        })
        setSumm(total.toFixed(2));
      } else {
        setSumm(0);
      } 
    }
  }, [OrderCunsumer.orderArray, PayCunsumer.orderArray]);

  return (
    <>
      {props.page !== 'payments' ? (
        <div className={`card h-100 ${props.size === 'small' ? 'p-1' : 'p-3'}`}>
          <div className="card-body">
            {props.size === 'small' ? (
              <h5 className="text-primary media-fs-17 mb-5">
                Сумма выделенных заявок: <i className='menu-icon tf-icons mdi mdi-abacus'></i>
              </h5> 
            ) : (
              <h4 className="text-primary mb-5 media-fs-17">
                Сумма выделенных заявок: <i className='menu-icon tf-icons mdi mdi-abacus'></i>
              </h4>
            )}
            <h5 className="mt-2 text-info">{summ} RUB</h5>
            <hr />
            <p className="" style={{fontSize: props.size === 'small' ? '13px' : '15px'}}>выделите заявки, чтобы узнать их сумму</p>
            <button
              className={`btn btn-outline-primary waves-effect d-block media-btn-sm mt-5`}
              onClick={() => {
                setSumm(0);
                props.handleClearSelected(true);
              }}
            >
              Очистить выделенные <i className='tf-icons mdi mdi-backspace ms-2 mb-3'></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="card p-3">
          <div className="card-body pb-4 h-100">
            <h5 className="text-primary mb-4 media-fs-17">
              Сумма выделенных заявок: <i className='menu-icon tf-icons mdi mdi-abacus'></i>
            </h5>
            <h5 className="mt-3 text-info">{summ} RUB</h5>
            <hr />
            <p className="" style={{fontSize: '13px'}}>выделите заявки, чтобы узнать их сумму</p>
            <button
              className="btn btn-outline-primary waves-effect mt-3 d-block media-btn-sm"
              onClick={() => {
                setSumm(0);
                props.handleClearSelected(true);
              }}
            >
              Очистить выделенные <i className='tf-icons mdi mdi-backspace ms-2 mb-1'></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SummSelected;