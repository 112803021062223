import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import 'react-data-table-component-extensions/dist/index.css';
import { Link } from 'react-router-dom';

import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";
import { PaymentsContext } from "../../context/payments-context";

moment.locale('ru'); 

const CreatePayOrder = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [errorInput, setErrorInput] = React.useState(false);
  const UserCunsumer = useContext(UserContext);
  const PayCunsumer = useContext(PaymentsContext);

  useEffect(() => {
    PayCunsumer.getPayBalance();
    setLoading(false);
    return () => {
      PayCunsumer.clear();
    };
  }, []);   

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}} />
      );
    }
  };

  const OrderParams = async (name, value) => {
    await PayCunsumer.updateState(name, value);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (isEmpty(PayCunsumer.amount) || isEmpty(PayCunsumer.card) || isEmpty(PayCunsumer.bank)) {
      setErrorInput(true)
    } else {
      setErrorInput(false)
      setLoading(true);
      PayCunsumer.createOrder().then(() => {
        PayCunsumer.clear();
        PayCunsumer.getPayBalance();
        setLoading(false);
      });
    }
  };

  return (
    <>
      {renderRedirect()}
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <UserBar openMenu={openMenu}/>
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Создать заявку на выплату</h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4 mb-4">
                    <div className="col-md-8 col-lg-8">
                    <div className="card pe-3 pt-3 ps-2 h-100">
                        <div className="card-body pt-2">
                          <form onSubmit={(e) => e.preventDefault()}>
                            <h4 className="mb-3 text-primary">
                              Заявка на выплату<i className='menu-icon tf-icons mdi mdi-view-grid-plus-outline ms-2'></i>
                            </h4>
                            <hr />
                            <div className="row mb-2">
                              <div className="col-md-4 col-12 pe-0">
                                <div className="form-group">
                                  <label style={{ fontSize: 14 }} className="mb-2" htmlFor="minSum">
                                    <strong>Сумма выплаты в RUB:</strong>
                                  </label>
                                  <input
                                    type="number"
                                    onChange={(event) => OrderParams('amount', event.target.value)}
                                    className="form-control text-primary"
                                    name="amount"
                                    id="amount"
                                    value={PayCunsumer.amount ? PayCunsumer.amount : ''}
                                    placeholder="1000 RUB"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-12 pe-0">
                                <div className="form-group">
                                  <label style={{ fontSize: 14 }} className="mb-2" htmlFor="minSum">
                                    <strong>Карта:</strong>
                                  </label>
                                  <input
                                    type="text"
                                    onChange={(event) => OrderParams('card', event.target.value)}
                                    className="form-control text-primary"
                                    placeholder="XXXX-XXXX-XXXX-XXXX"
                                    name="card"
                                    value={PayCunsumer.card ? PayCunsumer.card : ''}
                                    id="card"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-12 pe-0">
                                <div className="form-group">
                                  <label style={{ fontSize: 14 }} className="mb-2" htmlFor="cardProvider">
                                    <strong>Банк карты:</strong>
                                  </label>
                                  <select
                                    onChange={(event) => OrderParams('bank', event.target.value)}
                                    className="form-control text-primary"
                                    name="bank"
                                    value={PayCunsumer.bank ? PayCunsumer.bank : ''}
                                    id="bank"
                                  >
                                    <option value="" defaultValue>
                                      Выберите банк
                                    </option>
                                    <option value={"sberbank"}>sberbank</option>
                                    <option value={"tinkoff"}>tinkoff</option>
                                    <option value={"visa/master"}>visa/master</option>
                                  </select>
                                </div>
                              </div> 
                            </div>
                            {errorInput === true && <p className='text-danger'>Заполните все поля!</p>}
                            <hr />
                            {loading === true ? (
                              <button className="btn btn-outline-info waves-effect w-100" type="button" >
                                Загрузка...<span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                              </button>
                            ) : (
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <button 
                                    type="button" 
                                    className="btn btn-outline-success waves-effect"
                                    onClick={(e) => submit(e)}
                                  >
                                    Создать заявку <i className=' tf-icons mdi mdi-plus ms-2'></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-xl-4 order-1 order-md-0">
                      <div className="card h-100">
                        <div className="card-body text-nowrap">
                          <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс выплат</h4>
                          <p className="pb-0">Общая принятая сумма</p>
                          <div className="d-flex align-items-center gap-3 my-4">
                            <div className="avatar avatar-icon">
                              <div className="avatar-initial bg-label-success rounded">
                                {PayCunsumer.currency === 'usdt' ? 
                                  <i className="mdi mdi-currency-usd mdi-24px"></i>
                                :
                                  <img src={require('../../images/currency-rub-3.png')} style={{width: '24px', height: '24px'}}/>
                                }
                              </div>
                            </div>
                            <div>
                            <h4 className="mb-0 text-success fw-normal">
                              {PayCunsumer?.balance || PayCunsumer.balance === 0 ? PayCunsumer.balance : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> } {PayCunsumer.currency}
                            </h4>
                              <span>Баланс</span>
                            </div>
                          </div>
                          <Link to='/user/payments/orders' className=" btn btn-sm btn-outline-dark waves-effect waves-light mt-1">Смотреть заявки</Link>
                        </div>
                        <img src={require('../../images/illustrations/payment-banknote.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="120" alt="view sales"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default CreatePayOrder;