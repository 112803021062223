import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import Loader from "../../../components/Loader";
import { sendNotification } from "../../../helpers/notification";
import { WithdrawContext } from "../../../context/withdrawal-context";

const ConfirmWithdrawModal = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [hashError, setHashError] = React.useState(false);

  const WithdrawCunsumer = useContext(WithdrawContext);
  
  const { show } = props;
  const simulateClick = React.useRef(null);

  useEffect(() => {
    if (!isEmpty(WithdrawCunsumer.activeOrder)) {
      setLoading(false);
    }
  }, [WithdrawCunsumer.activeOrder]); 

  const updateField = async (event) => {
    setHashError(false);
    await WithdrawCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setSubmitLoading(true);
    if (isEmpty(WithdrawCunsumer.hash)) {
      sendNotification("warning", "Укажите хеш", "Укажите хеш транзакции для пользователя.");
      setSubmitLoading(false);
      setHashError(true);
      return;
    }

    
    await WithdrawCunsumer.updateWithdrawStatus()
      .then((res) => {
        if (res.status === 'ok') {
          props.updateData();
          setSubmitLoading(false);
          sendNotification("success", "Статус вывода изменен", res.message);
          simulateClick.current.click()
        } else {
          props.updateData();
          simulateClick.current.click()
        }
      });  
  };

  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content py-3 px-4">
            {loading ? (
              <Loader /> 
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title  my-3" id="exampleModalLabel">
                    Подтверждение вывода <span className="text-primary"> id: {WithdrawCunsumer.activeOrder}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={props.togglePopUp}
                    className="btn-close-x"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group" style={{ fontSize: 16 }}>
                          <p className="mb-2 text-white">Новый статус: <span className={`text-success`}>Выполнено</span></p>  
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="my-3 text-white"  style={{ fontSize: 16 }} htmlFor="hash">
                          Хеш транзакции:
                          </label>
                          <input
                            type="text"
                            onChange={updateField}
                            className={`form-control text-primary ${hashError && 'is-invalid'}`}
                            name="hash"
                            id="hash"
                            value={WithdrawCunsumer.hash ? WithdrawCunsumer.hash : ''}
                          />
                          { hashError === true && 
                            <p className="text-danger mt-1">Укажите хеш.</p>
                          }
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer mt-3">
                  <button type="button" ref={simulateClick} className="btn btn-outline-secondary waves-effect" onClick={props.togglePopUp}>Закрыть</button>
                  {!submitLoading ? (
                    <button type="button" className="btn btn-outline-success waves-effect" onClick={(e) => submit(e)}>Подтвердить ✅</button>
                  ) : (
                    <button className="btn btn-outline-info waves-effect" type="button" >
                      Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmWithdrawModal;