import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

import { UserContext } from "../../../context/users-context";

moment.locale('ru'); 

const Withdrawals = (props) => {

  const [loading, setLoading] = React.useState(true);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    UserCunsumer.getWithdrawalsOrders();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []);  

  const getBadgeClass = (status) => {
    switch (status) {
      case 'PENDING':
        return 'bg-label-primary'
      case 'COMPLETED':
        return 'bg-label-success'
      case 'ERROR':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'В процессе'
      case 'COMPLETED':
        return 'Выполненая'
      case 'ERROR':
        return 'Ошибочная'
      default:
        return status;
    }
  }

  let columns = [
    { name: "Id",
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      width: '60px',
      sortable: true,
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <span style={{ fontSize: 12 }} className={`badge ${getBadgeClass(row.status)}`}>
            {getStatus(row.status)}
          </span>
        );
      },
    },
    {
      name: "Тип вывода",
      selector: (row) => row.type,
      cellExport: (row) => row.type,
      sortable: true,
      width: '190px',
      cell: (row) => {
        return (
          <span style={{ fontSize: 12 }} className={`badge ${row.type === 'usdt' ? 'bg-label-warning' : 'bg-label-warning'}`}>
            {row.type === 'usdt' ? 'Вывод в USDT' : "Вывод на баланс выплат"}
          </span>
        );
      },
    },
    {
      name: "Сумма вывода",
      selector: (row) => row.baseAmount,
      cellExport: (row) => row.baseAmount,
      sortable: true,
      minWidth: '140px',
      cell: (row, index) => {
        return (
          <div>
            <span className={``}>{row.baseAmount} USDT</span>
          </div>
        )
      },
    },
    {
      name: "Кошелек",
      sortable: true,
      minWidth: '150px',
      selector: (row) => row.wallet,
      cellExport: (row) => row.wallet,
      cell: (row) => {
        return (
          <span>{row.wallet}</span>
        );
      },
    },
    {
      name: "Дата создания",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    {
      name: "Комментарий",
      sortable: true,
      minWidth: '150px',
      selector: (row) => row.comment,
      cellExport: (row) => row.comment,
      cell: (row) => {
        return (
          <span>{row.comment}</span>
        );
      },
    },
    {
      name: "Хеш",
      selector: (row) => row.hash,
      cellExport: (row) => row.hash,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return `${row.hash}`;
      },
    },
    {
      name: "Баланс до",
      selector: (row) => row.metadata.balance_before,
      cellExport: (row) => row.metadata.balance_before,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return `${row.metadata.balance_before} USDT`;
      },
    },
    {
      name: "Баланс после",
      selector: (row) => row.metadata.balance_after,
      cellExport: (row) => row.metadata.balance_after,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.metadata.balance_after.toFixed(2)} USDT`;
      },
    },
  ];

  const tableData = {
    columns: columns,
    data: UserCunsumer.withdrawalsOrders,
    export: true,
    print: false,
    filter: true,
    filterPlaceholder: 'Универсальный поиск...'
  };

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <h4 className="card-title text-primary ms-3">История заявок на выплату</h4>
            </div>
          </div>
          {isEmpty(UserCunsumer.withdrawalsOrders) ? (
            <h5 className="text-center text-muted my-5">История пока пустая...</h5>
          ) : (
            <div className="table-responsive">
              <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <DataTableExtensions {...tableData} >
                  <DataTable
                    title={false}
                    columns={columns}
                    highlightOnHover={true}
                    striped={true}
                    pointerOnHover={true}
                    className={"table dataTable"}
                    pagination={true}
                    noHeader={true}
                    theme="solarized"
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[
                      20,
                      40,
                      100,
                      200,
                      300,
                    ]}
                  />
                </DataTableExtensions>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Withdrawals;