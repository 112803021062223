import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-data-table-component-extensions/dist/index.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Loader from "../../components/Loader";
import UserBar from "../../components/UserBar";
import SummSelected from "../../components/divs/SummSelected";
import { sendNotification } from "../../helpers/notification";
import { UserContext } from "../../context/users-context";
import { PaymentsContext } from "../../context/payments-context";

moment.locale('ru'); 

const PaymentsOrders = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [openMenu, setOpenMenu] = React.useState(false);
  const [filter, setFilter] = React.useState({});
  const UserCunsumer = useContext(UserContext);
  const PayCunsumer = useContext(PaymentsContext);

  useEffect(() => {
    PayCunsumer.getPayOrders();
    PayCunsumer.getPayBalance();
    setLoading(false);
    return () => {
      PayCunsumer.clear();
    };
  }, []);   

  useEffect(() => {
    if (PayCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", PayCunsumer.redirectMessage);
      setRedirectPath(PayCunsumer.redirectPath);
      PayCunsumer.clean();
      setRedirect(true);
    }
  }, [PayCunsumer.redirect]); 

  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'COMPLETED':
        return 'bg-label-success'
      case 'ERROR':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'ВЫПОЛНЕННАЯ'
      case 'PENDING':
        return 'В РАБОТЕ'
      case 'ERROR':
        return 'ОШИБОЧНАЯ'
      case 'CREATED':
        return 'НОВАЯ'
      default:
        return status;
    }
  }

  let columns = [
    { name: "Id",
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    { name: "Статус",
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      sortable: true,
      width: '160px',
      cell: (row) => {
        return (
          <span style={{ fontSize: 12 }} className={`badge ${getBadgeClass(row.status)}`}>
            {getStatus(row.status)}
          </span>
        );
      },
    },
    { name: "Карта",
      selector: (row) => row.card,
      cellExport: (row) => row.card,
      sortable: true,
      width: "190px",
      cell: (row, index) => {
        return (
          <div>
            <span className={`mr-2`}>{!isEmpty(row.card) ? row.card : 'еще не получено'}</span>
          </div>
        )
      },
    },
    { name: "Сумма в RUB",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      sortable: true,
      width: "130px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    { name: 'Cумма в USDT',
      selector: (row) => row.total,
      cellExport: (row) => `${row.total === row.amount ? 'Cумма в RUB' : `${row.total} USDT`}`,
      sortable: true,
      width: "135px",
      cell: (row) => {
        return `${row.total === row.amount ? 'Cумма в RUB' : `${row.total} USDT`}`;
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      cellExport: (row) => row.course,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return `${row.course ? row.course : 1}`;
      },
    },
    { name: "Банк",
      selector: (row) => row.bank,
      cellExport: (row) => row.bank,
      sortable: true,
      width: "110px",
      cell: (row) => {
        return `${!isEmpty(row.bank) ? row.bank : 'еще не получено'}`;
      },
    },
    { name: "Дата создания",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      sortable: true,
      width: "180px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("DD/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    { name: "Выполненная в",
      sortable: true,
      selector: (row) => row.successedAt,
      cellExport: (row) => row.successedAt,
      width: "180px",
      cell: (row) => {
        return (
          <span>{row.successedAt ? moment(row.successedAt).format("D/MM/YYYY, H:mm:ss") : '-------'}</span>
        );
      },
    },
    { name: "External ID",
      selector: (row) => row.externalId,
      cellExport: (row) => row.externalId,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return `${row.externalId}`;
      },
    },
    { name: "Callback url",
      selector: (row) => row.callbackUrl,
      cellExport: (row) => row.callbackUrl,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
  ];

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}} />
      );
    }
  };

  const hendleSelected = async (state) => {
    await PayCunsumer.updateState('orderArray', state.selectedRows);
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await PayCunsumer.updateState('orderArray', []);
    await setClearOrderArray(false);
  }

  const tableData = {
    columns: columns,
    data: PayCunsumer.orders,
    export: true,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const updateFilter = async (name, value) => {
    await setFilter({...filter, [name]: value });
  };
 
  const submit = async () => {  
    setLoading(true);
    return PayCunsumer.getPayOrders(filter)
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  const clean = async () => {
    setLoading(true);
    setFilter({});
    return PayCunsumer.getPayOrders({})
      .then(() => sendNotification("success", "Успех", "Заявки получены."))
      .then(() => setLoading(false))
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };

  return (
    <>
      {renderRedirect()}
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <UserBar openMenu={openMenu}/>
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Все заявки на выплаты</h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4 mb-4">
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="card ps-1 pe-3 pt-3 h-100">
                        <div className="card-body pt-2">
                          <form onSubmit={(e) => e.preventDefault()}>
                            <h4 className="mb-3 text-primary">
                              Фильтр<i className='menu-icon tf-icons mdi mdi-filter-outline ms-2'></i>
                            </h4>
                            <hr />
                            <div className="row mb-4">
                              <div className="col-md-6 col-12 pe-0">
                                <div className="form-group">
                                  <label style={{ fontSize: 14 }} className="mb-2" htmlFor="status">
                                    <strong>Статус:</strong>
                                  </label>
                                  <select
                                    onChange={(event) => updateFilter('status', event.target.value)}
                                    className="form-control text-primary"
                                    name="status"
                                    value={!isEmpty(filter.status) ? filter.status : ''}
                                  >
                                    <option value="" defaultValue>Все статусы</option>
                                    <option value={"ERROR"}>Ошибочная</option>
                                    <option value={"COMPLETED"}>Выполненная</option>
                                    <option value={"PENDING"}>В роботе</option>
                                    <option value={"CREATED"}>Новая</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-12 pe-0">
                                <div className="form-group">
                                  <label style={{ fontSize: 14 }} className="mb-2" htmlFor="cardProvider">
                                    <strong>Дата создания:</strong>
                                  </label>
                                  <DatePicker 
                                    selected={filter && filter.date && filter.date}
                                    placeholder="Выберите дату"
                                    wrapperClassName="form-control"
                                    className="form-control"
                                    dateFormat='dd-MM-yyyy' 
                                    onChange={(date) => updateFilter('date', date)}
                                  />
                                </div>
                              </div> 
                            </div>
                            <hr />
                            {loading === true ? (
                              <button className="btn btn-outline-info waves-effect w-100 mx-1" type="button" >
                                Загрузка...<span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                              </button>
                            ) : (
                              <div className="row">
                                <div className="col-md-6 col-6">
                                  <button 
                                    type="button" 
                                    className="btn btn-sm btn-outline-success waves-effect px-2"
                                    onClick={(e) => submit(e)}
                                  >
                                    Фильтровать <i className=' tf-icons mdi mdi-filter-check-outline ms-2'></i>
                                  </button>
                                </div>
                                <div className="col-md-6 col-6 text-end pe-0">
                                  <button 
                                    type="button" 
                                    className="btn btn-sm btn-outline-danger waves-effect px-3"
                                    onClick={(e) => clean(e)}
                                  >
                                    Сбросить <i className=' tf-icons mdi mdi-filter-minus-outline ms-2'></i>
                                  </button>
                                </div>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <SummSelected handleClearSelected={() => handleClearSelected() } page='payments'/>
                    </div>
                    <div className="col-12 col-xl-4 order-1 order-md-0">
                      <div className="card h-100">
                        <div className="card-body text-nowrap">
                          <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс выплат</h4>
                          <p className="pb-0">Общая принятая сумма</p>
                          <div className="d-flex align-items-center gap-3 my-4">
                            <div className="avatar avatar-icon">
                              <div className="avatar-initial bg-label-success rounded">
                                {PayCunsumer.currency === 'usdt' ? 
                                  <i className="mdi mdi-currency-usd mdi-24px"></i>
                                :
                                  <img src={require('../../images/currency-rub-3.png')} style={{width: '24px', height: '24px'}}/>
                                }
                              </div>
                            </div>
                            <div>
                            <h4 className="mb-0 text-success fw-normal">{PayCunsumer?.balance || PayCunsumer.balance === 0 ? PayCunsumer.balance : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}  {PayCunsumer.currency}</h4>
                              <span>Баланс</span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center gap-3 mb-1">
                            <div className="avatar avatar-icon">
                              <div className="avatar-initial bg-label-primary rounded">
                                <i className="mdi mdi-layers-triple-outline mdi-24px"></i>
                              </div>
                            </div>
                            <div>
                              <h4 className="mb-0 text-primary fw-normal">{PayCunsumer?.orders ? `${PayCunsumer.orders.length} шт` : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</h4>
                              <span>Заявки</span>
                            </div>
                          </div>
                        </div>
                        <img src={require('../../images/illustrations/payment-banknote.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="120" alt="view sales"/>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    < Loader/>
                  ) : (
                    <div className="row gy-4 mb-4">
                      <div className="col-md-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <h4 className="card-title text-primary ms-3">Все заявки на выплаты</h4>
                              </div>
                            </div>
                            {isEmpty(PayCunsumer.orders) ? (
                              <h5 className="text-center text-muted my-5">Заявок пока нету...</h5>
                            ) : (
                              <div className="table-responsive">
                                <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                  <DataTableExtensions {...tableData} >
                                    <DataTable
                                      title={false}
                                      columns={columns}
                                      highlightOnHover={true}
                                      striped={true}
                                      pointerOnHover={true}
                                      className={"table dataTable"}
                                      pagination={true}
                                      noHeader={true}
                                      theme="solarized"
                                      paginationPerPage={50}
                                      selectableRows={true}
                                      selectableRowsComponent={Checkbox}
                                      selectableRowsComponentProps={{ color: 'primary' }}
                                      selectableRowsVisibleOnly={true}
                                      clearSelectedRows={clearOrderArray}
                                      selectableRowsHighlight={true}
                                      onSelectedRowsChange={hendleSelected}
                                      paginationRowsPerPageOptions={[
                                        50,
                                        100,
                                        200,
                                        300,
                                        400,
                                      ]}
                                    />
                                  </DataTableExtensions>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default PaymentsOrders;