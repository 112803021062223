import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import { sendNotification } from "../../helpers/notification";
import AdminBar from "../../components/AdminBar";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

const ResendCallback = () => {

  const [orderId, setOrderId] = React.useState(null);
  const OrderCunsumer = useContext(OrderContext);
  const UserCunsumer = useContext(UserContext);
  

  useEffect(() => {
    return () => setOrderId(null);
  }, []);

  const handler = async (event) => {
    if (event.target.name === 'order') {
      await setOrderId(event.target.value);
    }
  };

  const submit = async () => {
    if (!isEmpty(orderId)) {
      return OrderCunsumer.resendCallback(orderId).then((res) => {
        if (res.status === 'success') {
          setOrderId(null);
          sendNotification("success", "Колбэк отправлен", "колбэк по заявке был успешно отправлен");
        } else {
					setOrderId(null);
				}
      });
    } else {
      sendNotification("danger", "Ошибка ⚠️", "Вы не ввели id заявки.");
      return
    }
  };

  return (
    <>
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle">
                      <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Отправить коллбэк
                    </h4>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  <div className="col-12 col-xl-6">
                    <div className="card">
                      <div className="card-body p-5">
                        <h4 className="card-title text-primary mb-5">
                          Отправить коллбэк <i className='icon-menu tf-icons mdi mdi-send-variant ms-2'></i>
                        </h4>
                        <div className="form-group">
                          <label className="mb-3" style={{ fontSize: 16 }} htmlFor="order">
                            <strong>ID заявки:</strong>
                          </label>
                          <input
                            type="text"
                            onChange={handler}
                            className="form-control"
                            name="order"
                            id="order"
                            value={orderId ? orderId : ''}
                          />
                        </div>
                        <button
                          onClick={(e) => submit(e)}
                          className={`btn btn-outline-success media-btn-sm media-fs-12 mt-5`}
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          Отправить коллбэк <i className='tf-icons mdi mdi-send-variant-outline ms-2'></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResendCallback;