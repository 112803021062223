import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";
import  secureLocalStorage  from  "react-secure-storage";
// Scss
import "./scss/style.scss";
// Pages admin
import White from "./pages/admin/White";
import Settings from "./pages/admin/Settings";
import Orders from "./pages/admin/Orders";
import EditOrder from "./pages/admin/EditOrder";
import Cards from "./pages/admin/Cards";
import EditCard from "./pages/admin/EditCard";
import AddCard from "./pages/admin/AddCard";
import Users from "./pages/admin/Users";
import ResendCallback from "./pages/admin/ResendCallback";
import Stats from "./pages/admin/Stats";
import Archive from "./pages/admin/Archive";
import ExportOrders from "./pages/admin/ExportOrders";
import WithdrawalOrders from "./pages/admin/WithdrawalOrders";
// Pages user
import UserStats from "./pages/user/UserStats";
import UserWhite from "./pages/user/UserWhite";
import UserOrders from "./pages/user/UserOrders";
import UserSettings from "./pages/user/UserSettings";
import UserWithdraw from "./pages/user/UserWithdraw";
import PaymentsOrders from "./pages/user/PaymentsOrders";
import CreatePayOrder from "./pages/user/CreatePayOrder";
// system pages
import Welcome from "./pages/Home";
import NoRoute from "./pages/NoRoute";
import Login from "./pages/Login";
import Forbidden from "./pages/Forbidden";
// context

const PrivateRoute = ({ component, role, ...options }) => {
  let userRole = secureLocalStorage.getItem("role");
  if (isEmpty(Cookies.get("jwt"))) {
    var user = false;
  } else {
    user = true;
  }
  if (user && userRole === role) {
    return <Route {...options} component={component} />
  } else if (user && userRole !== role) {
    return <Redirect
      push
      to={{
        pathname: '/forbidden'
      }}
    />
  } else {
    return <Redirect
      push
      to={{
        pathname: '/auth/login'
      }}
    />
  }
};

export default class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          {/* admin routes */}
          <PrivateRoute exact role="admin" path="/admin/home" component={White} props={this} />
          <PrivateRoute exact role="admin" path="/admin/settings" component={Settings} props={this} />
          <PrivateRoute exact role="admin" path="/admin/orders" component={Orders} />
          <PrivateRoute exact role="admin" path="/admin/edit/order/:id" component={EditOrder} props={this} />
          <PrivateRoute exact role="admin" path="/admin/orders" component={Orders} />
          <PrivateRoute exact role="admin" path="/admin/cards" component={Cards} />
          <PrivateRoute exact role="admin" path="/admin/edit/card/:id" component={EditCard} />
          <PrivateRoute exact role="admin" path="/admin/add/card" component={AddCard} />
          <PrivateRoute exact role="admin" path="/admin/users" component={Users} />
          <PrivateRoute exact role="admin" path="/admin/orders/resendcallback" component={ResendCallback} />
          <PrivateRoute exact role="admin" path="/admin/orders/stats" component={Stats} />
          <PrivateRoute exact role="admin" path="/admin/orders/archive" component={Archive} />
          <PrivateRoute exact role="admin" path="/admin/orders/export" component={ExportOrders} />
          <PrivateRoute exact role="admin" path="/admin/withdrawals" component={WithdrawalOrders} />
          {/* user routes */}
          <PrivateRoute exact role="user" path="/user/home" component={UserWhite} props={this} />
          <PrivateRoute exact role="user" path="/user/stats" component={UserStats} props={this} />
          <PrivateRoute exact role="user" path="/user/orders" component={UserOrders} props={this} />
          <PrivateRoute exact role="user" path="/user/settings" component={UserSettings} props={this} />
          <PrivateRoute exact role="user" path="/user/withdraw" component={UserWithdraw} props={this} />
          <PrivateRoute exact role="user" path="/user/payments/orders" component={PaymentsOrders} props={this} />
          <PrivateRoute exact role="user" path="/user/payments/create" component={CreatePayOrder} props={this} />
          {/* system routes */}
          <Route exact path="/forbidden" component={Forbidden} />
          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/" component={Welcome} />
          <Route component={NoRoute} />
        </Switch>
      </Router>
    );
  }
}