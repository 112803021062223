import React, { useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import Checkbox from '@material-ui/core/Checkbox';

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { sendNotification } from "../../helpers/notification";
import { CardContext } from "../../context/cards-context";
import { UserContext } from "../../context/users-context";

moment.locale('ru'); 

const Cards = (props) => {

  const [showSearch, setShowSearch] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [showSelect, setShowSelect] = React.useState(false);
  const [clearCardArray, setClearCardArray] = React.useState(false);
  const CardCunsumer = useContext(CardContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    CardCunsumer.getCards();
    setLoading(false);
    return () => {
      CardCunsumer.clean();
    }
  }, []);  

  useEffect(() => {
    if (CardCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", CardCunsumer.redirectMessage);
      setRedirectPath(CardCunsumer.redirectPath);
      CardCunsumer.clean();
      setRedirect(true);
    }
  }, [CardCunsumer.redirect]);

  const getBadgeClass = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-label-success'
      case 'ONHOLD':
        return 'bg-label-warning'
      case 'NOTACTIVE':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активная'
      case 'ONHOLD':
        return 'На удержании'
      case 'NOTACTIVE':
        return 'Не активная'
      default:
        return status;
    }
  }

  let columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      width: "60px",
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <span
            style={{ fontSize: 11 }}
            className={`badge ${getBadgeClass(row.status)} pb-1`}
          >
            {getStatus(row.status)}
          </span>
        );
      },
    },
    {
      name: "Номер карты",
      selector: (row) => row.number,
      sortable: true,
      width: "180px",
    },
    {
      name: "Банк",
      selector: (row) => row.provider,
      sortable: true,
      cell: (row) => {
        return `${row.provider}`;
      },
    },
    
    {
      ignoreRowClick: false,
      button: true,
      name: "Редактировать",
      width: "235px",
      cell: (row) => {
        return (
          <button className="btn btn-outline-info waves-effect btn-sm mx-3">
            <Link className="text-info" to={`/admin/edit/card/${row.id}`}>
              Редакрировать карту
            </Link>
          </button>
        );
      },
    },
    {
      name: "Лимит суммы в день",
      selector: (row) => row.currentDayLimit,
      sortable: true,
      width: "230px",
      cell: (row) => {
        return `Принято: ${row.currentDayLimit} из ${row.totalDayLimit}`;
      },
    },
    {
      name: "Лимит платежей в день",
      selector: (row) => row.currentDayPaymentLimit,
      sortable: true,
      width: "210px",
      cell: (row) => {
        return `Принято: ${row.currentDayPaymentLimit} из ${row.totalDayPaymentLimit}`;
      },
    },
    {
      name: "Лимит в месяц",
      selector: (row) => row.currentMonthLimit,
      sortable: true,
      width: "230px",
      cell: (row) => {
        return `Принято: ${row.currentMonthLimit} из ${row.totalMonthLimit}`;
      },
    },
    {
      name: "Коммент",
      selector: (row) => row?.metadata.comment,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return `${row?.metadata?.comment ? row.metadata.comment : 'пусто'}`;
      },
    }
  ];
  
  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}}/>
      );
    }
  };

  const tableData = {
    columns: columns,
    data: CardCunsumer.cards ? CardCunsumer.cards : [],
    export: false,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    let ids = state.selectedRows.map(order => order.id);
    await CardCunsumer.updateState('ids', ids);
    setShowSelect(true);
  }

  const updateField = async (event) => {
    await CardCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    if (!isEmpty(CardCunsumer.ids)) {
      if (!isEmpty(CardCunsumer.status)) {
        if (window.confirm(`Вы подтверждаете изменение статуса карт на ${getStatus(CardCunsumer.status)}?`)) {
          setLoading(true);
          return CardCunsumer.changeCardsStatus()
          .then(() => {
            setLoading(false)
            CardCunsumer.clean();
            CardCunsumer.getCards();
          })
        }
      } else{
        sendNotification("danger", "Выберите статус!", "Выберите статус, что бы изменить его");
      }
    } else{
      sendNotification("danger", "Выберите карты!", "Что бы изменить статус, нужно выбрать карты");
    }
  };

  return (
    <>
      {renderRedirect()}
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Все карты для приема
                      </h4>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  <div className="col-lg-8 col-12">
                    <div className="card h-100 p-3">
                      <div className="card-header media-p-2">
                        <div className="d-flex justify-content-between">
                          <h4 className="mb-2 text-primary media-w-100 media-fs-17">
                            Информация <i className='menu-icon tf-icons mdi mdi-credit-card-search-outline ms-2'></i>
                          </h4>
                          <div className="dropdown media-d-none">
                            <button  style={{fontSize: "16px"}} className="btn btn-label-success btn-sm media-fs-11 media-w-100 media-px-1">
                              <Link className='text-success'  to={`/admin/add/card`}> 
                                Добавить новую карту <i className='tf-icons mdi mdi-credit-card-plus-outline ms-2'></i>
                              </Link>
                            </button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center media-w-100">
                          <small className="me-2">Информация по картам</small>
                        </div>
                      </div>
                      <div className="card-body d-flex flex-wrap gap-3 mt-3 py-2" style={{flex: '0'}}>
                        <div className="d-flex gap-3 me-5" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                              <i className="mdi mdi-credit-card-multiple-outline mdi-24px"></i>
                            </div>
                          </div>
                          <div className="card-primary">
                            <h4 className="mb-0 text-primary">
                              {CardCunsumer.cards ? CardCunsumer.cards.length :
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">всего карт</small>
                          </div>
                        </div>
                        <div className="d-flex gap-3 me-5" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-success rounded">
                              <i className="mdi mdi-account-credit-card-outline mdi-24px"></i>
                            </div>
                          </div>
                          <div className="card-success">
                            <h4 className="mb-0 text-success">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.status === "ACTIVE"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">активные</small>
                          </div>
                        </div>
                        <div className="d-flex gap-3 " style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-danger rounded">
                              <i className="mdi mdi-credit-card-off-outline mdi-24px"></i>
                            </div>
                          </div>
                          <div className="card-danger">
                            <h4 className="mb-0 text-danger">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.status === "NOTACTIVE"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">не активные</small>
                          </div>
                        </div>
                      </div>
                      <div className="card-body d-flex flex-wrap gap-3 py-2" style={{flex: '0'}}>
                        <div className="d-flex gap-3 me-5" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-warning rounded">
                              <i className="mdi mdi-credit-card-clock-outline mdi-24px"></i>
                            </div>
                          </div>
                          <div className="card-warning">
                            <h4 className="mb-0 text-warning">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.status === "ONHOLD"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">на удержании</small>
                          </div>
                        </div>
                        <div className="d-flex gap-3 me-5" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                              <i className="mdi mdi-currency-uah mdi-24px"></i>
                            </div>
                          </div>
                          <div className="card-warning">
                            <h4 className="mb-0 text-primary">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.provider === "card_uah"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">card_uah</small>
                          </div>
                        </div>
                        <div className="d-flex gap-3 " style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-warning rounded">
                              <img src={require('../../images/u8lrha0a.png')} alt="" style={{width: '35px', height: '35px'}} />
                            </div>
                          </div>
                          <div className="card-warning">
                            <h4 className="mb-0 text-warning">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.provider === "tinkoff"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">tinkoff</small>
                          </div>
                        </div>
                      </div>
                      <div className="card-body d-flex flex-wrap gap-3 py-2" style={{flex: '0'}}>
                        <div className="d-flex gap-3 me-5" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-success rounded">
                              <img src={require('../../images/sberbank.png')} alt="" style={{width: '38px', height: '38px'}} />
                            </div>
                          </div>
                          <div className="card-success">
                            <h4 className="mb-0 text-success">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.provider === "sberbank"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">sberbank</small>
                          </div>
                        </div>
                        <div className="d-flex gap-3" style={{width: '165px'}}>
                          <div className="avatar">
                            <div className="avatar-initial bg-label-danger rounded">
                              <img src={require('../../images/yandex.png')} alt="" style={{width: '35px', height: '35px'}} />
                            </div>
                          </div>
                          <div className="card-danger">
                            <h4 className="mb-0 text-danger">
                              {CardCunsumer.cards ? CardCunsumer.cards.filter((item) => {return item.provider === "yandex"}).length : 
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            </h4>
                            <small className="text-muted">yandex</small>
                          </div>
                        </div>
                        <button  style={{fontSize: "16px"}} className="nav-menu-mobil-media btn btn-label-success btn-sm media-fs-11 media-w-100 media-px-1 media-mt-2">
                          <Link className='text-success'  to={`/admin/add/card`}> 
                            Добавить новую карту <i className='tf-icons mdi mdi-credit-card-plus-outline ms-2'></i>
                          </Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-12">
                    <div className="alert alert-info h-100 px-4 pt-5" role="alert">
                      <p className='my-4'>
                        <i className='menu-icon tf-icons mdi mdi-credit-card-remove-outline me-2'></i>
                        <strong>Что бы удалить карту</strong> - нажмите на редактировать карту и удалите ее.
                      </p>
                      <p className='my-4'>
                        <i className='menu-icon tf-icons mdi mdi-credit-card-lock-outline me-2'></i>
                        <strong>Что бы отключить карту</strong> - нажмите на редактировать карту и смените ее статус.
                      </p>
                      <p className="my-4">
                        <i className='menu-icon tf-icons mdi mdi-credit-card-sync-outline me-2'></i>
                        <strong>Сменить лимит карты</strong> - нажмите на редактировать карту и смените лимиты.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gy-4">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <h4 className="card-title text-primary ms-3 mt-3"> Список карт</h4>
                          </div>
                          {showSelect && 
                            <>
                              <div className="col-md-4">
                                <div className='form-group mt-1'>
                                  <label className="mb-3 text-info"  style={{ fontSize: 13 }} htmlFor="status">
                                    <strong>Изменить статус выделенных карт:</strong>
                                  </label>
                                  <select
                                    onChange={updateField}
                                    value={CardCunsumer.status ? CardCunsumer.status : ''}
                                    className="form-control input-active"
                                    name="status"
                                    id="status"
                                    required
                                  >
                                    <option value={""}>Выберите статус</option>
                                    <option value={"ACTIVE"}>Активная</option>
                                    <option value={"NOTACTIVE"}>Не активная</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-2" style={{marginTop: '41px'}}>
                                <button type="button" onClick={(e) => submit(e)} className="btn btn-outline-info waves-effect">Изменить</button>
                              </div>
                            </>
                          } 
                        </div>
                        {loading && <Loader/>}
                        {loading === false && isEmpty(CardCunsumer.cards) ? (
                          <h5 className="text-center text-muted my-5">Карт пока что нету. Добавте новые.</h5>
                        ) : (
                          <div className="table-responsive">
                            <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                              <DataTableExtensions {...tableData} >
                                <DataTable
                                  title={false}
                                  columns={columns}
                                  highlightOnHover={true}
                                  striped={true}
                                  pointerOnHover={true}
                                  className={"table dataTable"}
                                  pagination={true}
                                  noHeader={true}
                                  theme="solarized"
                                  paginationPerPage={50}
                                  selectableRows={true}
                                  selectableRowsComponent={Checkbox}
                                  selectableRowsComponentProps={{ color: 'primary' }}
                                  selectableRowsVisibleOnly={true}
                                  clearSelectedRows={clearCardArray}
                                  selectableRowsHighlight={true}
                                  onSelectedRowsChange={hendleSelected}
                                  paginationRowsPerPageOptions={[
                                    50,
                                    100,
                                    200,
                                    300,
                                    400,
                                  ]}
                                />
                              </DataTableExtensions>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;