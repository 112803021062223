import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import "../../scss/cards-statistics.css";
import "../../scss/cards-analytics.css";

import UserBar from "../../components/UserBar";
import { UserContext } from "../../context/users-context";
import { evaluate } from 'mathjs';
import Withdrawals from './components/Withdrawals';

const UserWithdraw = () => {

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState({});
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    UserCunsumer.userMe();
    UserCunsumer.getCourse();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []);

  useEffect(() => {
    if (UserCunsumer?.withdraw?.type === 'usdt') {
      UserCunsumer.getCourse();
    }
  }, [UserCunsumer.withdraw]);

  const handleChange = (e) => {
    setError({ ...error, [e.target.name]: false });

    if (e.target.name === 'amount') {
      if (parseFloat(e.target.value) > 0 || e.target.value === '') {
        if (parseFloat(e.target.value) >= parseFloat(UserCunsumer.user.balance)) {
          UserCunsumer.updateState('withdraw', {...UserCunsumer.withdraw, [e.target.name]: UserCunsumer.user.balance});
        } else {
          UserCunsumer.updateState('withdraw', {...UserCunsumer.withdraw, [e.target.name]: e.target.value});
        }
      }
    } else {
      UserCunsumer.updateState('withdraw', {...UserCunsumer.withdraw, [e.target.name]: e.target.value})
    }
  }

  const setAllBalance = () => {
    setError({ ...error, amount: false });
    UserCunsumer.updateState('withdraw', {...UserCunsumer.withdraw, amount: parseFloat(UserCunsumer.user.balance).toFixed(2)})
  }

  const submit = async () => {
    if (!['internal', 'usdt'].includes(UserCunsumer?.withdraw?.type)) {
      setError({ ...error, type: true });
      return;
    }

    if (isEmpty(UserCunsumer?.withdraw?.amount)) {
      setError({ ...error, amount: true });
      return;
    }

    if (UserCunsumer?.withdraw?.type === 'usdt' && isEmpty(UserCunsumer?.withdraw?.wallet)) {
      setError({ ...error, wallet: true });
      return;
    }
    
    setLoading(true);

    let res = await UserCunsumer.createWithdraw();

    if (!isEmpty(res)) {
      setLoading(false);
    }
    return;
  }

  return(
    <>
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <UserBar/>
          <div className='layout-page'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <h4 className="align-middle">
                      <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                        <i className="mdi mdi-menu mdi-24px me-3"></i>
                      </a>
                      Вывод баланса</h4>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  {/* <div className="col-md-3 col-12">
                    <div className="card h-100">
                      <div className="row">
                        <div className="col-6">
                          <div className="card-body">
                            <div className="card-info mb-3 py-2 mb-lg-1 mb-xl-3">
                              <h5 className="mb-3 mb-lg-2 mb-xl-3 text-nowrap">Курс USDT-RUB</h5>
                              <span className='d-flex'>по<span className="ms-2 badge bg-label-success rounded-pill lh-xs">garantex.org</span> </span>
                            </div>
                            <div className="d-flex align-items-end flex-wrap gap-1">
                              <h4 className="mb-0 me-2">{UserCunsumer.withdraw_course}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 text-end d-flex align-items-end justify-content-center">
                          <div className="card-body pb-0 pt-3 position-absolute bottom-0">
                            <img src={require('../../images/illustrations/faq-illustration.png')} alt="Ratings" width="100" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-xl-5 col-md-5 col-12">
                    <div className="card h-100">
                      <div className="card-body text-nowrap">
                        <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс депозитов</h4>
                        <p className="pb-0">Общая принятая сумма</p>
                        <div className="d-flex align-items-center gap-3 my-4">
                          <div className="avatar avatar-icon">
                            <div className="avatar-initial bg-label-warning rounded">
                              <i className="mdi mdi-currency-usd mdi-24px"></i>
                              {/* <img src={require('../../images/currency-rub-1.png')} style={{width: '24px', height: '24px'}}/> */}
                            </div>
                          </div>
                          <div>
                            <h4 className="mb-0 text-warning fw-normal">{UserCunsumer?.user?.balance || UserCunsumer?.user?.balance === 0 ? UserCunsumer.user.balance : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} usdt</h4>
                            <span>Баланс</span>
                          </div>
                        </div>
                        <Link to='/user/orders' className=" btn btn-sm btn-outline-dark waves-effect waves-light">Смотреть заявки</Link>
                      </div>
                      <img src={require('../../images/illustrations/money-bag-5654697-4712953.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="140" alt="view sales"/>
                    </div>
                  </div>
                  <div className="col-xl-7 col-12">
                    <div className="card h-100">
                      <div className="card-body text-nowrap me-5 ms-2">
                        <h4 className="card-title mb-2 d-flex gap-2 flex-wrap">
                          Вывод баланса
                          <i className="mdi mdi-database-export-outline mdi-24px"></i>
                        </h4>
                        <div className='row'>
                          <div className='col-10'>
                            {/* <p className="card-text pb-1 mb-0">Создайте заявку на вывод ниже, заполнив форму. </p> */}
                            <p className="card-text" style={{fontSize: '17px'}}>
                              <br />Вы можете вывести баланс на выплатный счет или 
                              <br />получить USDT на Ваш TRC20 кошелек.
                              <br />Все вопросы/детали можно уточнить у операторов.
                            </p>
                          </div>
                        </div>
                      </div>
                      <img src={require('../../images/illustrations/trophy.png')} className="position-absolute bottom-0 end-0 me-3" height="140" alt="view sales" />
                    </div>
                  </div>
                </div>            
                <div className="row justify-content-center mb-4">
                  <div className="col-md-12 media-mb-4">
                    <div className="card p-3 h-100">
                      <h4 className="card-header text-primary ms-1">
                        Заявка на вывод
                        <i className="mdi ms-2 mdi-briefcase-arrow-left-right-outline mdi-24px"></i>
                      </h4>
                      <div className="card-body">
                        { error.type && <p className='text-danger fw-bold text-center'>Выберите тип</p> }
                        <div className="row">
                          <div className="col-md-4 mb-md-0 mb-2">
                            <div className={`form-check custom-option custom-option-icon ${error.type && "is-invalid"} ${UserCunsumer?.withdraw?.type === 'internal' && "checked"}`}>
                              <label className="form-check-label custom-option-content text-start " htmlFor="type">
                                <span className="custom-option-body">
                                  <input name="type" onChange={handleChange} className="form-check-input input-withdraw" type="radio" value="internal" id="type" />
                                  <img src={require('../../images/logo2.png')} style={{height: '35px'}} className="ms-4 mb-2" alt="wallet" />
                                  <span className="custom-option-title"> Вывод на выплатный баланс </span>
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className={`form-check custom-option custom-option-icon ${error.type && "is-invalid"} ${UserCunsumer?.withdraw?.type === 'usdt' && "checked"}`}>
                              <label className="form-check-label custom-option-content text-start " htmlFor="type2">
                                <span className="custom-option-body">
                                  <input name="type" onChange={handleChange} className="form-check-input input-withdraw" type="radio" value="usdt" id="type2" />
                                  <img src={require('../../images/usdt.png')} style={{height: '35px'}} className="ms-4 mb-2" alt="cc-success" />
                                  <span className="custom-option-title"> Вывод в USDT </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div className="input-group mt-4">
                              <input type="text" 
                                onChange={handleChange} 
                                value={UserCunsumer?.withdraw?.amount ? UserCunsumer.withdraw.amount : "" } 
                                name='amount' 
                                className={`form-control ${error.amount && 'is-invalid'}`} 
                                placeholder="Сумма к выводу" 
                                aria-label="Сумма к выводу" 
                              />
                              <button className="btn btn-outline-primary waves-effect" type="button" onClick={(e) => setAllBalance()}>ВСЕ: {UserCunsumer?.user?.balance} usdt.</button>
                            </div>
                            {error.amount && <p className='text-danger mt-1'>Введите сумму к списанмю баланса</p>}
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="form-floating form-floating-outline mb-4 mt-4">
                              <input type="text" 
                                onChange={handleChange} 
                                value={UserCunsumer?.withdraw?.comment ? UserCunsumer.withdraw.comment : "" } 
                                className="form-control" 
                                id="comment" 
                                name='comment' 
                                placeholder="Комментарий к заявке" 
                              />
                              <label htmlFor="comment">Комментарий к заявке</label>
                            </div>
                          </div>
                        </div>
                        {UserCunsumer?.withdraw?.type === 'usdt' && 
                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-floating form-floating-outline mb-4">
                                <input type="text" 
                                  onChange={handleChange} 
                                  value={UserCunsumer?.withdraw?.wallet ? UserCunsumer.withdraw.wallet : "" } 
                                  className={`form-control ${error.wallet && 'is-invalid'}`} 
                                  id="wallet" 
                                  name='wallet' 
                                  placeholder="Адрес кошелька USDT TRC20" 
                                />
                                <label htmlFor="wallet">Адрес кошелька USDT TRC20</label>
                              </div>
                            </div>
                            {error.wallet && <p className='text-danger mt-1'>Введите адрес TRC20 кошелька</p>}
                          </div>
                        }
                        <div className="text-start">
                          {loading === true ? (
                            <button className="btn btn-outline-info waves-effect" type="button" >
                              Загрузка...<span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                            </button>
                          ) : (
                            <button onClick={(e) => submit()} type="button" className="btn btn-primary waves-effect waves-light">
                              Создать заявку <i className=' tf-icons mdi mdi-plus ms-2'></i>
                            </button>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-5">
                    <div className="card p-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="alert alert-success alert-dismissible py-3 px-4" role="alert">
                            <h4 className="alert-heading d-flex align-items-center">
                              <i className="mdi mdi-chart-bar mdi-24px me-2"></i>
                              Формула расчета:
                            </h4>
                            <hr/>
                            <h5 style={{fontSize: '1.08rem'}} className='text-white mt-3'><span className='text-success'>USDT:</span> (cумма - 1%) / курс = USDT</h5>
                            <h5 style={{fontSize: '1.08rem'}} className='text-white'><span className='text-success'>Выплатный баланс:</span> cумма - 1.5% </h5>
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          <div className="alert alert-warning alert-dismissible py-3 px-4" role="alert">
                            <h4 className="alert-heading d-flex align-items-center">
                              <i className="mdi mdi-alert-outline mdi-24px me-2"></i>
                              Внимание!
                            </h4>
                            <hr/>
                            <p className='text-warning'>В момент создания заявки (отправки запроса) - сумма будет пересчитана по курсу биржи garantex на момент отправки запроса.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-12">
                    <Withdrawals />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default UserWithdraw;