import React from "react";
import { isEmpty } from "lodash";

import Loader from "./Loader";
import { sendNotification } from "../helpers/notification";
import { UserContext } from "../context/users-context";

const UserSettings = () => {

  const [showInputCode, setShowInputCode] = React.useState(false);
  const [emptyCode, setEmptyCode] = React.useState(false);
  const userCunsumer = React.useContext(UserContext);

  const handlerTwo = async (event) => {
    if (emptyCode) {
      setEmptyCode(false);
    }
    await userCunsumer.updateState(event.target.name, event.target.value);
  };

  React.useEffect(() => {
    userCunsumer.userMe();
  }, []); 

  const updateData = () => {
    userCunsumer.userMe();
  }

  const getQrCode = async (e) => {
    e.preventDefault();
    return userCunsumer
      .getQrCode()
      .then(() => {
        setShowInputCode(true)
      })
      .catch((e) => {
        console.log("e", JSON.stringify(e));
        if (e.response && e.response.body.message) {
          sendNotification("danger", "Ошибка ⚠️", e.response.body.message);
        }
      });
  };
  
  const confirmTwoFa = async () => {
    if (isEmpty(userCunsumer.code)) {
      setEmptyCode(true);
      return sendNotification("danger", "Вы не ввели код ⚠️", "Код 2ФА не может быть пустым");
    }
    return userCunsumer.confirmTwoFa()
      .then((res) => {
        if (res.status === 'success') {
          setShowInputCode(false)
          updateData();
        }
      });
  };

  return (
    <>
      <div className="row">
        {isEmpty(userCunsumer.user) ? (
          <Loader />
        ) : (
          userCunsumer.user.twoFaEnabled === true ? (
            <div className="col-xl-5 col-12 grid-margin stretch-card">
              <div className="card pt-4">
                <div className="card-body">
                  <img src={require('../images/2fa.png')} className="mx-auto d-block mb-5" width={'100px'} alt="" />
                  <div className={`alert alert-success p-3 text-center`}> 
                    <strong>2FA Включено</strong>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {!showInputCode && 
                <>
                  <div className="col-xl-5 col-12 grid-margin stretch-card">
                    <div className="card py-4">
                      <div className="card-body">
                        <img src={require('../images/2fa.png')} className="mx-auto d-block mb-4" width={'100px'} alt="" />
                        <div className='text-center'> 
                          <h5 className="card-title my-5 text-center text-warning">Включить 2FA авторизацию</h5>
                        </div>
                        <button onClick={(e) => getQrCode(e)} className={`btn btn-outline-warning mt-4 d-block mx-auto`}>
                          Получить QR код
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              }                 
              {!isEmpty(userCunsumer.qrcode) && showInputCode && 
                <>
                  <div className="col-xl-5 col-12 grid-margin stretch-card mx-auto">
                    <div className="card py-4">
                      <div className="card-body">
                        <h5 className="card-title text-center text-warning">
                          Отсканируйте и сохраните данный код <i className="menu-icon mdi mdi-shield-lock-outline ms-2"></i>
                        </h5>
                        <div className="text-center">
                          <img className="my-3" src={`${userCunsumer.qrcode}`} alt="Red dot" /> 
                          <div className="alert alert-warning px-2 pt-4 h-100" role="alert">
                            <h6 className="mb-3 ">Или введите код вручную:</h6>
                            <h6>{userCunsumer.token}</h6>
                          </div>
                        </div>
                        <div className={`form-group text-center ${ emptyCode ? 'has-danger' : ''}`}>
                          <label className="mt-2 mb-4" htmlFor="confirmCode">Введите 2FA Код с аутентификатора:</label>
                          <input
                            type="text"
                            name="code"
                            onChange={handlerTwo}
                            placeholder="*******"
                            required
                            className={`form-control input-warning ${ emptyCode ? 'form-control-danger' : ''}`}
                            id="confirmCode"
                          />
                        </div>
                        <button onClick={() => confirmTwoFa()} className={`btn btn-outline-warning mt-4 d-block mx-auto`}> 
                          Включить 2FA <i className="mdi mdi-lock-outline ms-1 mb-3"></i>
                        </button>
                        <div className="text-danger mt-3 text-center" role="alert">
                          <i className="menu-icon mdi mdi-alert-outline me-1"></i> Код повторно больше не будет показан.
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          )
        )}
      </div> 
    </>
  );
};

export default UserSettings;