import React, { useContext, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-data-table-component-extensions/dist/index.css';

import Loader from "../../components/Loader";
import UserBar from "../../components/UserBar";
import UserFilter from "./components/UserFilter";
import SummSelected from "../../components/divs/SummSelected";
import { sendNotification } from "../../helpers/notification";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

moment.locale('ru'); 

const UserOrders = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [filter, setFilter] = React.useState({});
  const OrderCunsumer = useContext(OrderContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    UserCunsumer.getUserOrders();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []);  

  useEffect(() => {
    UserCunsumer.userMe();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []);  

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clean();
      setRedirect(true);
    }
  }, [OrderCunsumer.redirect]); 

  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'SUCCESS':
        return 'bg-label-success'
      case 'EDITED':
        return 'bg-label-warning'
      case 'EXPIRED':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'НОВАЯ'
      case 'PENDING':
        return 'В ПРОЦЕССЕ'
      case 'SUCCESS':
        return 'ВЫПОЛНЕННАЯ'
      case 'EDITED':
        return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
      case 'EXPIRED':
        return 'ПРОСРОЧЕННАЯ'
      default:
        return status;
    }
  }

  let columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      cellExport: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Внешний id",
      selector: (row) => row.externalId,
      cellExport: (row) => row.externalId,
      sortable: true,
      width: "120px",
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      cellExport: (row) => row.status,
      sortable: true,
      width: '180px',
      cell: (row) => {
        return (
          <>
           {row.status === "PENDING" && row?.metadata?.markedAsPaid === true ? (
              <span style={{ fontSize: 12 }} className={`badge bg-label-primary`}>
                Отметил как оплаченую
              </span>
           ) : (
            <span style={{ fontSize: 12 }} className={`badge ${getBadgeClass(row.status)}`}>
              {getStatus(row.status)}
            </span>
           )}
          </>
        );
      },
    },
    {
      name: "Карта",
      selector: (row) => row.cardNumber,
      cellExport: (row) => row.cardNumber,
      sortable: true,
      width: "180px",
      cell: (row, index) => {
        return (
          <div>
            <span className={`mr-2`}>{!isEmpty(row.cardNumber) ? row.cardNumber : 'еще не получено'}</span>
          </div>
        )
      },
    },
    {
      name: "Сумма RUB",
      selector: (row) => row.amount,
      cellExport: (row) => row.amount,
      sortable: true,
      width: "120px",
      cell: (row) => {
        return `${row.amount} RUB`;
      },
    },
    { name: "Курс",
      selector: (row) => row.course,
      cellExport: (row) => row.course,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return `${row.course}`;
      },
    },
    { name: "Сумма USDT",
      selector: (row) => row.total,
      cellExport: (row) => row.total,
      sortable: true,
      width: "125px",
      cell: (row) => {
        return `${row.total} USDT`;
      },
    },
    {
      name: "Банк",
      selector: (row) => row.cardProvider,
      cellExport: (row) => row.cardProvider,
      sortable: true,
      width: "145px",
      cell: (row) => {
        return `${!isEmpty(row.cardProvider) ? row.cardProvider : 'еще не получено'}`;
      },
    },
    {
      name: "Коммент:",
      selector: (row) => row.comment,
      cellExport: (row) => row.comment,
      width: "200px",
      cell: (row) => {
        return `${!isEmpty(row.comment) ? row.comment : ''}`;
      },
    },
    {
      name: "Дата в процессе",
      sortable: true,
      selector: (row) => row.pendingAt,
      cellExport: (row) => row.pendingAt,
      width: "170px",
      cell: (row) => {
        return (
          <span>{!isEmpty(row.pendingAt) ? moment(row.pendingAt).format("D/MM/YYYY, H:mm:ss") : 'новая'}</span>
        );
      },
    },
    {
      name: "Дата создания",
      selector: (row) => row.createdAt,
      cellExport: (row) => row.createdAt,
      sortable: true,
      width: "170px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    {
      name: "Хеш",
      selector: (row) => row.hash,
      cellExport: (row) => row.hash,
      sortable: true,
      width: "200px",
      cell: (row) => {
        return `${row.hash}`;
      },
    },
    {
      name: "Callback",
      selector: (row) => row.callbackUrl,
      cellExport: (row) => row.callbackUrl,
      sortable: true,
      width: "265px",
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
  ];

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{ pathname: redirectPath,}} />
      );
    }
  };

  const hendleSelected = async (state) => {
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await OrderCunsumer.updateState('orderArray', []);
    await setClearOrderArray(false);
  }

  const tableData = {
    columns: columns,
    data: UserCunsumer.orders,
    export: true,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  return (
    <>
      {renderRedirect()}
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <UserBar openMenu={openMenu}/>
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Все заявки на депозит</h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4 mb-4 ">
                    <div className="col-md-8 col-lg-8">
                      <div className="row gy-4">
                        <div className="col-12 col-xl-12 media-d-none">
                          <div className="card px-3 py-2 media-p-none h-100">
                            <div className="card-body pb-1">
                              <ul className="nav nav-tabs nav-tabs-widget pb-3 gap-4 mx-1 d-flex flex-nowrap" role="tablist">
                                <li className="nav-item" role="presentation">
                                  <div className="nav-link btn d-flex flex-column align-items-center justify-content-center  page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="true">
                                    <button type="button" className="btn btn-icon rounded-pill btn-label-success waves-effect page-white-icon">
                                      <img src={require('../../images/favicon/logoicon1.png')} width={30}/>
                                    </button>
                                  </div>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <div className="nav-link btn d-flex flex-column align-items-center justify-content-center page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabIndex="-1">
                                    <button type="button" className="btn btn-icon rounded-pill btn-label-instagram waves-effect  page-white-icon">
                                      <img src={require('../../images/favicon/logoicon3.png')} width={30}/>
                                    </button>
                                  </div>
                                </li>
                                <li className="nav-item ms-auto my-auto" role="presentation">
                                  <div class="d-flex justify-content-between mt-3">
                                    <div>
                                      <h6 class="mb-1 fw-semibold">Перейти к статистике</h6>
                                      <p class="mb-0 me-4 text-muted">Смотреть статистику за сегодня</p>
                                    </div>
                                    <div class="avatar">
                                      <Link to='/user/stats'>
                                        <div class="avatar-initial bg-label-primary rounded">
                                          <i class="mdi mdi-chevron-right mdi-24px scaleX-n1-rtl"></i>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                                <span className="tab-slider" style={{left: '0px', width: '112px', bottom: '0px'}}></span>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 mt-4">
                          <div className="card px-2 py-2">
                            <div className="card-body ">
                              <UserFilter updateFilter={setFilter} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="row gy-4 ">
                        <div className="col-12 col-xl-12 order-1 order-md-0">
                          <div className="card">
                            <div className="card-body text-nowrap">
                              <h4 className="card-title mb-1 d-flex gap-2 flex-wrap">Баланс депозитов</h4>
                              <p className="pb-0">Общая принятая сумма</p>
                              <div className="d-flex align-items-center gap-3 my-4">
                                <div className="avatar avatar-icon">
                                  <div className="avatar-initial bg-label-warning rounded">
                                    <i className="mdi mdi-currency-usd mdi-24px"></i>
                                    {/* <img src={require('../../images/currency-rub-1.png')} style={{width: '24px', height: '24px'}}/> */}
                                  </div>
                                </div>
                                <div>
                                  <h4 className="mb-0 text-warning fw-normal">{UserCunsumer?.user?.balance || UserCunsumer?.user?.balance === 0 ? UserCunsumer.user.balance : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} usdt</h4>
                                  <span>Баланс</span>
                                </div>
                              </div>
                              <div className="d-flex align-items-center gap-3">
                                <div className="avatar avatar-icon">
                                  <div className="avatar-initial bg-label-primary rounded">
                                    <i className="mdi mdi-format-list-numbered mdi-24px"></i>
                                  </div>
                                </div>
                                <div>
                                  <h4 className="mb-0 text-primary fw-normal">{UserCunsumer?.orders ? `${UserCunsumer.orders.length} шт` : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}</h4>
                                  <span>Заявки</span>
                                </div>
                              </div>
                            </div>
                            <img src={require('../../images/illustrations/money-bag-5654697-4712953.png')} className="position-absolute end-0" style={{bottom: '20px' }} height="140" alt="view sales"/>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                          <SummSelected handleClearSelected={() => handleClearSelected() } size='small' />
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    < Loader/>
                  ) : (
                    <div className="row gy-4 mb-4">
                      <div className="col-md-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <h4 className="card-title text-primary ms-3">Все заявки на депозит</h4>
                              </div>
                            </div>
                            {isEmpty(UserCunsumer.orders) ? (
                              <h5 className="text-center text-muted my-5">Заявок пока нету...</h5>
                            ) : (
                              <div className="table-responsive">
                                <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                  <DataTableExtensions {...tableData} >
                                    <DataTable
                                      title={false}
                                      columns={columns}
                                      highlightOnHover={true}
                                      striped={true}
                                      pointerOnHover={true}
                                      className={"table dataTable"}
                                      pagination={true}
                                      noHeader={true}
                                      theme="solarized"
                                      paginationPerPage={50}
                                      selectableRows={true}
                                      selectableRowsComponent={Checkbox}
                                      selectableRowsComponentProps={{ color: 'primary' }}
                                      selectableRowsVisibleOnly={true}
                                      clearSelectedRows={clearOrderArray}
                                      selectableRowsHighlight={true}
                                      onSelectedRowsChange={hendleSelected}
                                      paginationRowsPerPageOptions={[
                                        50,
                                        100,
                                        200,
                                        300,
                                        400,
                                      ]}
                                    />
                                  </DataTableExtensions>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default UserOrders;