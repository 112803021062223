import React, { useContext, useEffect } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

import AdminBar from "../../components/AdminBar";
import Loader from "../../components/Loader";
import { sendNotification } from "../../helpers/notification";
import { CardContext } from "../../context/cards-context";

moment.locale('ru'); 

const EditOrder = () => {

  const [loading, setLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const CardCunsumer = useContext(CardContext);
  const { id } = useParams();

  useEffect(() => {
    CardCunsumer.getCard(id);
		return () => {
			CardCunsumer.clean()
		};
  }, []);

  useEffect(() => {
    if (!isEmpty(CardCunsumer.card)) {setLoading(false)}
  }, [CardCunsumer.card]);

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{pathname: redirectPath,}}/>
      );
    }
  };

  const updateField = async (event) => {
    await CardCunsumer.updateState(event.target.name, event.target.value);
  };

  const submit = async () => {
    setSubmitLoading(true);
		if (window.confirm(`Вы подтверждаете изменение карты id: ${CardCunsumer.card.id} с номером ${CardCunsumer.card.number}?`)) {
			return CardCunsumer.updateCard()
      .then((res) => {
        if (res.status === 'success') {
          setSubmitLoading(false);
          sendNotification("success", "Карта обновлена", "Не забывайте смотреть на блок 'Важно'.");
        } else {
			setSubmitLoading(false);
		}
      });
		} else {
			setSubmitLoading(false);
			return;
		}
  };

  const deleteCard = async () => {
	if (window.confirm(`Вы уверены что хотите удалить карту: ${CardCunsumer.card.number} ?`)) {
		setSubmitLoading(true);
		await CardCunsumer.deleteCard().then((res) => {
			if (res.status === 'success') {
				sendNotification("success", "Карта удалена ✅", "Вы успешно удалили карту");
				setSubmitLoading(false);
				setRedirectPath('/admin/cards');
				setRedirect(true);
			} else {
				setSubmitLoading(false);
			}
		});
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активная'
      case 'ONHOLD':
        return 'На удержании'
      case 'NOTACTIVE':
        return 'Не активная'
      default:
        return status;
    }
  }

  const getColor = (num) => {
    switch (num) {
      case 0:
        return 'danger'
      case 1:
        return 'success'
      case 2:
        return 'warning'
      case 3:
        return 'primary'
      case 4:
        return 'info'
      default:
        return 'secondary';
    }
  }

  return (
    <>
      {renderRedirect()}
      <div className='layout-wrapper layout-content-navbar'>
			<div className='layout-container'>
				<AdminBar />
				<div className='layout-page'>
					{loading === true ? (
						<Loader />
					) : ( 
						<>
							<nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
								<div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
									<div className="navbar-nav align-items-center">
										<div className="nav-item navbar-search-wrapper mb-0">
											<h4 className="align-middle">
												Редактирование карты<i className='ms-2 menu-icon tf-icons mdi mdi-border-color'></i>
											</h4>
										</div>
									</div>
								</div>
							</nav> 
							<div className="content-wrapper">
								<div className="container-xxl flex-grow-1 container-p-y">
									<div className="row gy-4 mb-4">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body p-5">
													<h4 className="card-title text-primary">Карта #{CardCunsumer?.card?.id ? CardCunsumer.card.id : ''}</h4>
													<h4 className="card-title text-primary">{CardCunsumer?.card?.number ? CardCunsumer.card.number : ''}</h4>
													<hr />
													<div className="row">
														<div className="col-md-4 mt-3">
															<div className="alert alert-success alert-dismissible px-4" role="alert">
																<h4 className="alert-heading d-flex align-items-center mt-2">
																	<i className="mdi mdi-credit-card mdi-24px me-2"></i>Карта
																</h4>
																<hr/>
																<p className="mb-3" style={{textTransform: 'uppercase'}}>
																	{CardCunsumer.number ? CardCunsumer.number : CardCunsumer.card.number}
																</p>
																<hr/>
																<p className="mb-3" style={{textTransform: 'uppercase', fontSize: '13px'}}>
																	{CardCunsumer.provider ? CardCunsumer.provider : CardCunsumer.card.provider}
																</p>
																<p className="mb-0" style={{textTransform: 'uppercase', fontSize: '13px'}}>
																	{CardCunsumer.status ? getStatus(CardCunsumer.status) : getStatus(CardCunsumer.card.status)}
																</p>
															</div>
														</div>
														<div className="col-md-4">
															<div className='form-group'>
																<label className="mb-2 text-info"  style={{ fontSize: 13 }} htmlFor="number">
																	<strong>Номер карты:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control input-active"
																	name="number"
																	placeholder={CardCunsumer.card.number}
																	id="number"
																	value={CardCunsumer.number ? CardCunsumer.number : CardCunsumer.card.number}
																/>
															</div>
															<div className='form-group '>
																<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="status">
																	<strong>Статус карты:</strong>
																</label>
																<select
																	onChange={updateField}
																	value={CardCunsumer.status ? CardCunsumer.status : CardCunsumer.card.status}
																	className="form-control input-active"
																	name="status"
																	id="status"
																	required
																>
																	<option defaultValue>Выберите статус</option>
																	<option value={"ACTIVE"}>Активная</option>
																	<option value={"NOTACTIVE"}>Не активная</option>
																</select>
															</div>
															<div className="form-group">
																<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="provider">
																	<strong>Банк карты:</strong>
																</label>
																<select
																	onChange={updateField}
																	className="form-control input-active"
																	name="provider"
																	id="provider"
																	placeholder={CardCunsumer.card.provider}
																	value={CardCunsumer.provider ? CardCunsumer.provider : CardCunsumer.card.provider}
																>
																	<option value="" defaultValue>Выберите банк</option>
																	<option value={"card_uah"}>card_uah</option>
																	<option value={"sberbank"}>sberbank</option>
																	<option value={"tinkoff"}>tinkoff</option>
																	<option value={"yandex"}>yandex</option>
																	<option value={"visa/master"}>visa/master</option>
																</select>
															</div>
														</div>
														<div className="col-md-4">
															<div className='form-group'>
																<label className="mb-2 text-info" style={{ fontSize: 13 }} htmlFor="currentDayPaymentLimit">
																	<strong>Принято платежей в день по карте (шт):</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control input-active"
																	name="currentDayPaymentLimit"
																	id="currentDayPaymentLimit"
																	placeholder={CardCunsumer.card.currentDayPaymentLimit}
																	value={CardCunsumer.currentDayPaymentLimit ? CardCunsumer.currentDayPaymentLimit : ''}
																/>
															</div>
															<div className='form-group'>
																<label className="my-2 text-info" style={{ fontSize: 13 }} htmlFor="currentDayLimit">
																	<strong>Принятая сумма за день:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control input-active"
																	name="currentDayLimit"
																	id="currentDayLimit"
																	placeholder={CardCunsumer.card.currentDayLimit}
																	value={CardCunsumer.currentDayLimit ? CardCunsumer.currentDayLimit : ''}
																/>
															</div>
															<div className='form-group'>
																<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="currentMonthLimit">
																	<strong>Принятая сумма за месяц:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control input-active"
																	name="currentMonthLimit"
																	id="currentMonthLimit"
																	placeholder={CardCunsumer.card.currentMonthLimit}
																	value={CardCunsumer.currentMonthLimit ? CardCunsumer.currentMonthLimit : '' }
																/>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-12">
															<div className='form-group'>
																<label className="my-2 text-info" style={{ fontSize: 13 }} htmlFor="comment">
																	<strong>Комментарий:</strong>
																</label>
																<input
																	type="text"
																	onChange={updateField}
																	className="form-control input-active"
																	name="comment"
																	id="comment"
																	placeholder={CardCunsumer.card?.metadata?.comment ? CardCunsumer.card.metadata.comment : ''}
																	value={CardCunsumer.comment ? CardCunsumer.comment : ''}
																/>
															</div>
														</div>
													</div>
													<div className="row">
														<div className="col-md-4">
															<div className='form-group'>
																<label className="my-2" style={{ fontSize: 13 }} htmlFor="totalDayLimit">
																	<strong>Верхний лимит суммы в день:</strong>
																</label>
																<input
																	type="text"
																	className="form-control"
																	value={CardCunsumer.card.totalDayLimit}
																	disabled
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className='form-group'>
																<label className="my-2" style={{ fontSize: 13 }} htmlFor="totalMonthLimit">
																	<strong>Верхний лимит суммы в месяц:</strong>
																</label>
																<input
																	type="text"
																	className="form-control"
																	value={CardCunsumer.card.totalMonthLimit}
																	disabled
																/>
															</div>
														</div>
														<div className="col-md-4">
															<div className='form-group'>
																<label className="my-2" style={{ fontSize: 13 }} htmlFor="totalDayPaymentLimit">
																	<strong>Верхний лимит платежей в день по карте (шт):</strong>
																</label>
																<input
																	type="text"
																	className="form-control"
																	value={CardCunsumer.card.totalDayPaymentLimit}
																	disabled
																/>
															</div>
														</div>
													</div>
													<hr />
													<div className="mt-4 d-flex ">
														<button type="button" className="btn btn-outline-dark waves-effect">
															<Link className='text-dark' to="/admin/cards"> Назад к картам</Link>
														</button>
														<div className="ms-auto">
															{!submitLoading ? (
																<button type="button" onClick={() => deleteCard()} className="btn btn-outline-danger waves-effect me-3">Удалить карту </button>
															) : (
																<button type="button" className="btn btn-outline-info waves-effect">
																	Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
																</button>
															)}
															{!submitLoading ? (
																<button type="button" onClick={(e) => submit(e)} className="btn btn-outline-success waves-effect">Сохранить</button>
															) : (
																<button type="button" className="btn btn-outline-info waves-effect">
																	Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
																</button>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row gy-4">
										<div className="col-md-12">
											<div className="alert alert-warning px-5 py-4" role="alert">
												<h4 className="alert-heading ms-1">
													Внимание! Важно!<i className='menu-icon tf-icons mdi mdi-comment-alert ms-2 mb-3'></i>
												</h4>
												<hr />
												<p>
													<i className='tf-icons mdi mdi-apple-keyboard-control me-2'></i>
													<strong>Верхний лимит</strong> - его менять нельзя, он указываеться только при добавлении карты в систему.
												</p>
												<p>
													<i className='tf-icons mdi mdi-keyboard-caps me-2'></i>
													<strong>Поточный лимит</strong> - не может быть больше, чем верхний лимит.
												</p>
												<p>
													<i className='tf-icons mdi mdi-credit-card-clock-outline me-2'></i>
													<strong>Карта которая "На удержании" </strong> - ее нельзя редактировать пока она не будет свободна (по ней должен сперва зайти депозит).
												</p>
												<p>
													<i className='tf-icons mdi mdi-clock-edit-outline me-2'></i>
													<strong>Редактируем сумму за день </strong> - не забывайте прибавить эту сумму в "Принято за месяц".
												</p>
												<p>
													<i className='tf-icons mdi mdi-table-edit me-2'></i>
													<strong>Редактируем сумму за месяц </strong> - если необходимо, так же прибавьте ету сумму в "Принято за день".
												</p>
												<p>
													<i className='tf-icons mdi mdi-clipboard-text-clock-outline me-2'></i>
													<strong>История изменений </strong> - в истории изменений Вы видите старые (архивные) записи по карте. Актуальные данные по карте находяться в форме редактирования. <strong>Вам нужно сравнивать </strong> то что было до (Последняя запись в истории (было), с тем что есть в форме (стало)).
												</p>
											</div>
										</div>
									</div>
									<div className="row mt-2">
										<div className="col-md-12">
											<div className="card">
												<div className="card-body mb-0 px-5 py-4" style={{ whiteSpace: 'pre-wrap' }}>
													<h4 className="card-title text-primary ms-1 mt-4">
														История изменений по карте <i className='menu-icon tf-icons mdi mdi-clipboard-text-clock-outline ms-2 mb-3'></i>
													</h4>
													<hr  style={{marginBottom: '50px'}} />
													{isEmpty(CardCunsumer.history) ? (
														<h5 className="text-center text-muted my-5">История изменений пуста...</h5>
													) : (
														CardCunsumer.history && CardCunsumer.history.map((item, index) => (
															<ul className="timeline card-timeline mb-0" key={index}>
																<li className="timeline-item timeline-item-transparent">
																	<span className={`timeline-point timeline-point-${getColor(index)}`}></span>
																	<div className="timeline-event">
																		<div className="timeline-header mb-1">
																		<h5 className={`mb-2 fw-semibold text-${getColor(index)}`}>{moment(item.date).format('DD-MM-YYYY, Время: HH:mm')}</h5>
																		</div>
																		<div className="row">
																			<div className="col-4">
																				<p className="text-muted mb-2">
																					Номер карты:<span className="text-white"> {item.data.number}</span>
																				</p>
																				<p className="text-muted mb-2">
																					Статус карты:<span className="text-white"> {item.data.status}</span>
																				</p>
																				<p className="text-muted mb-2">
																					Банк карты:<span className="text-white"> {item.data.provider}</span>
																				</p>
																				<p className="text-muted mb-2">
																					Комментарий:<span className="text-white"> {item.data.comment}</span>
																				</p>
																			</div>
																			<div className="col-5">
																				<p className="text-muted mb-2">
																					Принято платежей в день по карте (шт):<span className="text-white"> {item.data.currentDayPaymentLimit}</span>
																				</p>
																				<p className="text-muted mb-2">
																					Принятая сумма за день:<span className="text-white"> {item.data.currentDayLimit}</span>
																				</p>
																				<p className="text-muted mb-2">
																					Принятая сумма за месяц:<span className="text-white"> {item.data.currentMonthLimit}</span>
																				</p>
																			</div>
																		</div>
																	</div>
																</li>
															</ul>
														))
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)}
          		</div>
        	</div>
      	</div>
    </>
  );
};

export default EditOrder;