import React from "react";
import { Withdraw } from "../helpers/api";
import { sendNotification } from "../helpers/notification";

export const WithdrawContext = React.createContext({});

export class WithdrawProvider extends React.Component {

  state = {
    getPengingWithdrawsCount: async () => {
      await Withdraw.getPengingWithdrawsCount().then((res) => { 
          this.setState({ pendingWithdrawsCount: res.body.count });
        }).catch((error) => { 
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", 'Нету доступа.');
        });
    },
    
    getWithdrawsOrders: async () => {
      await Withdraw.getWithdrawsOrders().then((res) => { 
          this.setState({ withdraws: res.body });
        }).catch((error) => { 
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", 'Нету доступа.');
        });
    },

    updateWithdrawStatus: async () => {
      let params = {
        withdrawalId: this.state.activeOrder,
        status: this.state.status,
      }

      if (this.state.type === 'usdt') {
        params.hash = this.state.hash
      }

      return Withdraw.updateWithdrawStatus(params)
      .then((res) => {
        return { ...res.body }
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
        return { status: 'error' }
      });
    },
    
    clear: () => {
      this.setState({
        withdraw: {},
        pendingWithdrawsCount: null,
        withdraws: null,
        hash: null,
        status: null,
        type: null,
      });
    },
    
    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
      }),

    user: {},
    redirectPath: "",
    redirectMessage: "",
    redirect: false,
  };

  render() {
    return (
      <WithdrawContext.Provider value={this.state}>
        {this.props.children}
      </WithdrawContext.Provider>
    );
  }
}