import React from "react";
import { Card } from "../helpers/api";
import { isEmpty } from "lodash";
import { sendNotification } from "../helpers/notification";

export const CardContext = React.createContext({});

export class CardProvider extends React.Component {

  state = {
    updateState: async (key, val) => {
      await this.setState((state) => {
        state[key] = val;
        return state;
      })
    },
    getCards: async () => {
      await Card.getCards()
        .then((res) => {
          this.setState({ cards: res.body.cards });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },
    createCard: async () => {
      let params = {
        number: this.state.number,
        provider: this.state.provider,
        totalDayPaymentLimit: this.state.totalDayPaymentLimit,
        totalMonthLimit: this.state.totalMonthLimit,
        totalDayLimit: this.state.totalDayLimit,
        comment: this.state.comment,
        status: this.state.status,
      }
      return Card.pushNewCard(params).then((res) => {
        return { status: 'success' }
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
        return { status: 'error' }
      });
    },
    getCard: async (id) => {
      return Card.getCard(id)
        .then((res) => {
          this.setState({ card: res.body });
          let history = [];
          for (const [key, val] of Object.entries(res.body.metadata)) {
            if (val?.id) {
              history.push({ date: key, data: val})
            }
          }
          this.setState({ history });
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
        });
    },
    updateCard: async () => {
      let params = {}
      if (!isEmpty(this.state.number)) params.number = this.state.number;
      if (!isEmpty(this.state.provider)) params.provider = this.state.provider;
      if (!isEmpty(this.state.status)) params.status = this.state.status;
      if (!isEmpty(this.state.comment)) params.comment = this.state.comment;
      if (!isEmpty(this.state.currentDayPaymentLimit)) params.currentDayPaymentLimit = parseFloat(this.state.currentDayPaymentLimit);
      if (!isEmpty(this.state.currentDayLimit)) params.currentDayLimit = parseFloat(this.state.currentDayLimit);
      if (!isEmpty(this.state.currentMonthLimit)) params.currentMonthLimit = parseFloat(this.state.currentMonthLimit);  
      return Card.updateCard(this.state.card.id, params)
        .then((res) => {
          this.setState({});
          this.setState({ card: res.body.card });
          return { status: 'success' }
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    changeCardsStatus: async () => {
      let data = {};
      if (!isEmpty(this.state.status)) data.status = this.state.status;
      if (!isEmpty(this.state.ids)) data.ids = this.state.ids;
      return Card.changeStatus(data)
      .then((res) => {
        if (res.body.message === 'ok'){
          sendNotification("success", "Статус изменен", 'Cтатусы выделенных карт изменены' );
          return { status: 'success' }
        }
      })
      .catch((error) => {
        console.log("error", JSON.stringify(error, null, 4));
        sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
        return { status: 'error' }
      });
    },
    deleteCard: async () => {
      return Card.deleteCard(this.state.card.id)
        .then((res) => {
          return { status: 'success' }
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },
    clean: async () => {
      return this.setState({
        card: null,
        cards: null,
        number: null,
        provider: null,
        totalDayPaymentLimit: null,
        totalDayLimit: null,
        totalMonthLimit: null,
        status: null,
        comment: null,
        currentDayPaymentLimit: null,
        currentDayLimit: null,
        currentMonthLimit: null,
        ids: [],
        history: []
      });
    },
    clearForm: async () => {
      return this.setState({
        number: null,
        provider: null,
        status: null,
        totalDayPaymentLimit: null,
        totalDayLimit: null,
        totalMonthLimit: null,
        comment: null,
      });
    }
  };

  render() {
    return (
      <CardContext.Provider value={this.state}>
        {this.props.children}
      </CardContext.Provider>
    );
  }
}