import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import { sendNotification } from "../../helpers/notification";
import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

const Stats = () => {

  const [loading, setLoading] = React.useState(true);
  const OrderCunsumer = useContext(OrderContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    OrderCunsumer.getStat();
    setLoading(false)
    return () => OrderCunsumer.clean();
  }, []);

  const handler = async (event) => {
    if (event.target.name === 'startOrderId') {
      await OrderCunsumer.updateState('startOrderId', event.target.value);
    }
  };

  const submit = async () => {
    setLoading(true);
    if (!isEmpty(OrderCunsumer.startOrderId)) {
      return OrderCunsumer.getStat().then((res) => {
        if (res.status === 'success') {
          setLoading(false);
          sendNotification("success", "Статистика получена ", "Новая статистика была успешно получена");
        } else {
          setLoading(false);
				}
      });
    } else {
      setLoading(false);
      sendNotification("danger", "Ошибка ⚠️", "Вы не ввели id заявки.");
      return
    }
  };

  return (
    <>
      {loading ? (
        < Loader/>
      ) : (   
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <AdminBar />
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Статистика
                      </h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4 mb-4">
                    <div className="col-12 col-xl-6">
                      <div className="card">
                        <div className="card-body p-5">
                          <h4 className="card-title text-primary mb-5">
                            Получить статистику <i className='icon-menu tf-icons mdi mdi-chart-bar ms-2'></i>
                          </h4>
                          <div className="form-group">
                            <label className="mb-4" style={{ fontSize: 16 }}  htmlFor="order">
                              <strong>ID заявки:</strong>
                              <br/><span className="text-muted" style={{textTransform: 'none', fontSize: '13px'}}>(введите ID начальной заявки от которой надо статистику)</span>
                            </label>
                            <input
                              type="text"
                              onChange={handler}
                              className="form-control"
                              name="startOrderId"
                              id="startOrderId"
                              value={OrderCunsumer.startOrderId ? OrderCunsumer.startOrderId : ''}
                            />
                          </div>
                          <button
                            onClick={(e) => submit(e)}
                            className={`btn btn-outline-success mt-5`}
                            style={{fontSize: "16px"}}
                            name="startOrderId"
                            id="startOrderId"
                          >
                            Получить статистику <i className='tf-icons mdi mdi-chart-bar-stacked ms-2'></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-12">
                      <div className="alert alert-info px-5 pt-5 h-100 media-p-3" role="alert">
                        <h4 className="alert-heading mb-2 mt-2">
                          Информация
                          <i className='menu-icon tf-icons mdi mdi-information-variant-box ms-2 mb-3'></i>
                        </h4>
                        <hr />
                        <p style={{fontSize: '15px', marginBottom: '30px', marginTop: '30px'}}>
                          <i className='menu-icon tf-icons mdi mdi-numeric me-2'></i>
                          <strong>ID заявки</strong> - Пример: Вы ставите id заявки 99, статистика посчитает все, что будет выше даного id. То есть, в расчет возьмуться заявки начиная с id 100.</p>
                        <p style={{fontSize: '15px'}}>
                          <i className='menu-icon tf-icons mdi mdi-abacus me-2'></i>
                          <strong>Если Вы хотите посчитать все заявки</strong> - укажите Id - 0.</p>
                      </div>
                    </div>
                  </div>
                  {OrderCunsumer.stats &&
                    <div className="row gy-4">
                      <div className="col-12 col-xl-12">
                        <div className="card p-4 media-p-none">
                          <div className="card-header d-flex justify-content-between">
                            <div className="card-title m-0">
                              <h4 className="text-primary media-fs-17">Посчитано начиная от заявки id: {OrderCunsumer.startOrderId}</h4>
                              <small className="text-muted">Cтатистикa</small>
                            </div>
                          </div>
                          <div className="card-body pb-3">
                            <ul className="nav nav-tabs nav-tabs-widget pb-3 gap-4 mx-1 d-flex flex-nowrap" role="tablist">
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="true">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-success waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon1.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-sales-id" aria-controls="navs-sales-id" aria-selected="false" tabIndex="-1">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-primary waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon2.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <li className="nav-item" role="presentation">
                                <div className="nav-link btn d-flex flex-column align-items-center justify-content-center" role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabIndex="-1">
                                  <button type="button" className="btn btn-icon rounded-pill btn-label-instagram waves-effect">
                                    <img className="media-w-img" src={require('../../images/favicon/logoicon3.png')} width={50}/>
                                  </button>
                                </div>
                              </li>
                              <span className="tab-slider" style={{left: '0px', width: '112px', bottom: '0px'}}></span>
                            </ul>
                            <div className="tab-content p-0 pt-5 ms-0 ms-sm-2">
                              <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                                <div className="table-responsive text-nowrap">
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr>
                                        <th className="fw-medium ps-0 text-heading">Статус</th>
                                        <th className="pe-0 fw-medium text-heading media-d-none"></th>
                                        <th className="pe-0 fw-medium text-heading">Заявки</th>
                                        <th className="pe-0 text-end text-heading">Сумма</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-success media-fs-11">Выполненные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}}>* количество заявок в статусах "Успешная" <br/> и "Выполненная с корректировкой"</td>
                                        <td className="pe-0 text-success">
                                          {OrderCunsumer?.stats?.deposited_orders_count ? OrderCunsumer.stats.deposited_orders_count : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                        <td className="pe-0 text-success text-end fw-semibold h6">
                                          {!isEmpty(OrderCunsumer?.stats?.deposited_orders_total_sum.toString()) ? OrderCunsumer.stats.deposited_orders_total_sum : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} руб.
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-primary media-fs-11">В процессе</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}}>* заявки в статусе "Просроченная" <br/>клиент не успел в сроки и оператор перевел ее в просроченную.</td>
                                        <td className="text-primary pe-0">
                                          {OrderCunsumer?.stats?.pending_orders_count ? OrderCunsumer.stats.pending_orders_count : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                        <td className="text-primary pe-0 text-end fw-semibold h6">
                                          {!isEmpty(OrderCunsumer?.stats?.pending_orders_total_sum.toString()) ? OrderCunsumer.stats.pending_orders_total_sum : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} руб.
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="px-0"><span style={{fontSize: '16px'}} className="badge rounded-pill bg-label-danger media-fs-11">Просроченные</span></td>
                                        <td className="pe-0 media-d-none" style={{fontSize: '13px'}} >* количество заявок в статусе "В процессе" (не финальный статус). <br/> По ним ожидаем поступление на карту.</td>
                                        <td className="text-danger pe-0">
                                          {OrderCunsumer?.stats?.expired_orders_count ? OrderCunsumer.stats.expired_orders_count : <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} шт.
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Stats;