import React, { useContext, useEffect, useCallback } from "react";
import { Redirect, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import DataTable, {createTheme} from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions';
import Checkbox from '@material-ui/core/Checkbox';
import 'react-data-table-component-extensions/dist/index.css';

import Loader from "../../components/Loader";
import AdminBar from "../../components/AdminBar";
import Filter from "../../components/forms/filter";
import SummSelected from "../../components/divs/SummSelected";
import ChangeOrderStatus from "./modals/changeStatus";
import { sendNotification } from "../../helpers/notification";
import { OrderContext } from "../../context/orders-context";
import { UserContext } from "../../context/users-context";

moment.locale('ru'); 

const Archive = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [showSearch, setShowSearch] = React.useState(true);
  const [clearOrderArray, setClearOrderArray] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [redirectPath, setRedirectPath] = React.useState("");
  const [filter, setFilter] = React.useState({});
  const [showChangeStatusModal, setShowChangeStatusModal] = React.useState(false);
  const OrderCunsumer = useContext(OrderContext);
  const UserCunsumer = useContext(UserContext);

  useEffect(() => {
    OrderCunsumer.getArchiveOrders();
    setLoading(false);
    return () => OrderCunsumer.clean();
  }, []); 

  useEffect(() => {
    if (OrderCunsumer.redirect === true) {
      sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
      setRedirectPath(OrderCunsumer.redirectPath);
      OrderCunsumer.clean();
      setRedirect(true);
    }
  }, [OrderCunsumer.redirect]); 

  const updateData = () => {
    OrderCunsumer.clean();
    setTimeout(() => {
        OrderCunsumer.getArchiveOrders(filter);
    }, 100);
  };

  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'bg-label-info'
      case 'PENDING':
        return 'bg-label-primary'
      case 'SUCCESS':
        return 'bg-label-success'
      case 'EDITED':
        return 'bg-label-warning'
      case 'EXPIRED':
        return 'bg-label-danger'
      default:
        return 'bg-label-dark'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'НОВАЯ'
      case 'PENDING':
        return 'В ПРОЦЕССЕ'
      case 'SUCCESS':
        return 'ВЫПОЛНЕННАЯ'
      case 'EDITED':
        return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
      case 'EXPIRED':
        return 'ПРОСРОЧЕННАЯ'
      default:
        return status;
    }
  }

  const handler = (id) => {
    OrderCunsumer.updateState('activeOrder', id);
    setShowChangeStatusModal(true);
  };

  const togglePopUp = useCallback(
    (trigger) => {
      OrderCunsumer.updateState('activeOrder', '');
      OrderCunsumer.updateState('status', '');
      OrderCunsumer.updateState('amount', '');
      OrderCunsumer.updateState('comment', '');
      setShowChangeStatusModal(false);
    },[] 
  );

  createTheme('solarized', {
    text: {
      primary: '#666cff',
      secondary: '#666cff',
    },
    background: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    context: {
      background: '#a0a1b8',
      text: '#FFFFFF',
    },
    divider: {
      default: '#333',
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  let columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Внешний id",
      selector: (row) => row.externalId,
      sortable: true,
      width: "150px",
    },
    {
      name: "Статус",
      selector: (row) => row.status,
      sortable: true,
      width: "250px",
      cell: (row) => {
        return (
          <>
           {row.status === "PENDING" && row?.metadata?.markedAsPaid === true ? (
              <span
                style={{ fontSize: 12 }}
                className={`badge bg-label-primary`}
              >
                Отметил как оплаченую
              </span>
           ) : (
            <span
              style={{ fontSize: 12 }}
              className={`badge ${getBadgeClass(row.status)}`}
            >
              {getStatus(row.status)}
            </span>
           )}
          </>
        );
      },
    },
    {
      name: "Карта",
      selector: (row) => row.cardNumber,
      sortable: true,
      width: "200px",
      cell: (row, index) => {
        return (
          <div>
            <span className={`mr-2`}>{!isEmpty(row.cardNumber) ? row.cardNumber : 'еще не получено'}</span>
          </div>
        )
      },
    },
    {
      name: "Сумма",
      selector: (row) => row.amount,
      sortable: true,
      width: "90px",
      cell: (row) => {
        return `${row.amount}`;
      },
    },
    {
      name: "Банк",
      selector: (row) => row.cardProvider,
      sortable: true,
      width: "110px",
      cell: (row) => {
        return `${!isEmpty(row.cardProvider) ? row.cardProvider : 'еще не получено'}`;
      },
    },
    {
      ignoreRowClick: false,
      button: true,
      name: "Авто действия",
      width: "180px",
      cell: (row) => {
        return (
          <button
            className="btn btn-outline-success waves-effect btn-sm"
            onClick={() => handler(row.id)}
          >
            Изменить статус
          </button>
        );
      },
    },
    {
      ignoreRowClick: false,
      button: true,
      name: "Редактировать",
      width: "185px",
      cell: (row) => {
        return (
          <Link
            className="mx-2 btn btn-outline-info waves-effect btn-sm"
            to={`/admin/edit/order/${row.id}`}
          >
            Изменить заявку
          </Link>
        );
      },
    },
    {
      name: "Коммент:",
      selector: (row) => row.comment,
      width: "200px",
      cell: (row) => {
        return `${row.comment}`;
      },
    },
    {
      name: "Дата в процессе",
      sortable: true,
      selector: (row) => row.pendingAt,
      width: "200px",
      cell: (row) => {
        return (
          <span>{!isEmpty(row.pendingAt) ? moment(row.pendingAt).format("D/MM/YYYY, H:mm:ss") : 'новая'}</span>
        );
      },
    },
    {
      name: "Дата создания",
      sortable: true,
      width: "200px",
      cell: (row) => {
        return (
          <span>{moment(row.createdAt).format("D/MM/YYYY, H:mm:ss")}</span>
        );
      },
    },
    {
      name: "Id карты",
      selector: (row) => row.cardId,
      sortable: true,
      width: "100px",
      cell: (row) => {
        return `${!isEmpty(row.cardId) ? row.cardId : 'новая'}`;
      },
    },
    {
      name: "User id",
      selector: (row) => row.userId,
      sortable: true,
      width: "90px",
    },
    {
      name: "Хеш",
      selector: (row) => row.hash,
      sortable: true,
      width: "300px",
      cell: (row) => {
        return `${row.hash}`;
      },
    },
    {
      name: "Callback",
      selector: (row) => row.callbackUrl,
      sortable: true,
      width: "400px",
      cell: (row) => {
        return `${row.callbackUrl}`;
      },
    },
  ];

  const renderRedirect = () => {
    if (redirect && redirectPath) {
      return (
        <Redirect to={{pathname: redirectPath,}}/>
      );
    }
  };

  const hendleSelected = async (state) => {
    await setShowSearch(false);
    await OrderCunsumer.updateState('orderArray', state.selectedRows);
    if (isEmpty(OrderCunsumer.orderArray)) {
      await setShowSearch(true);
    }
  }

  const handleClearSelected = async (bool) => {
    await setClearOrderArray(true);
    await OrderCunsumer.updateState('orderArray', []);
    await setShowSearch(true);
    await setClearOrderArray(false);
  }

  const tableData = {
    columns: columns,
    data: OrderCunsumer.orders,
    export: false,
    print: false,
    filter: showSearch,
    filterPlaceholder: 'Универсальный поиск...'
  };

  return (
    <>
      {renderRedirect()}
      {loading ? (
        < Loader/>
      ) : (
        <div className='layout-wrapper layout-content-navbar'>
          <div className='layout-container'>
            <AdminBar />
            <div className='layout-page'>
              <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                  <div className="navbar-nav align-items-center">
                    <div className="nav-item navbar-search-wrapper mb-0">
                      <h4 className="align-middle">
                        <a className="icon-menu-mobil-media" onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                          <i className="mdi mdi-menu mdi-24px me-3"></i>
                        </a>
                        Архив заявок (все заявки)
                      </h4>
                    </div>
                  </div>
                </div>
              </nav>
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                  <div className="row gy-4 mb-4">
                    <div className="col-md-12 col-lg-12">
                      <div className="card px-4 py-3  media-p-3">
                        <div className="card-body">
                          <Filter updateFilter={setFilter} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row gy-4 mb-4">
                    <div className="col-md-6 col-lg-5">
                      <SummSelected handleClearSelected={() => handleClearSelected() } />
                    </div>
                    <div className="col-md-6 col-lg-7">
                      <div className="alert alert-warning px-5 pt-5 h-100  media-p-3" role="alert">
                        <h5 className="alert-heading mb-2">
                          Внимание! Важно! <i className='menu-icon tf-icons mdi mdi-comment-alert ms-2 mb-3'></i>
                        </h5>
                        <hr />
                        <p style={{fontSize: '14px'}}>
                          <i className='tf-icons mdi mdi-sync-alert me-2'></i>
                          <strong>Авто действия</strong> - автоматизированые действия на сервере, которое отсылает колбэк, добавляет баланс.
                        </p>
                        <p style={{fontSize: '14px'}}>
                          <i className='tf-icons mdi mdi-file-alert-outline me-2'></i>
                          <strong>Если нужно изменить финальный статус заявки</strong> - воспользуйтесь кнопкой "Изменить заявку" и отредактируйте статус на "В работе". Потом измените статус через кнопку "Авто действий" (Зеленую). 
                        </p>
                        <p style={{fontSize: '14px'}}>
                          <i className='tf-icons mdi mdi-clipboard-alert-outline me-2'></i>
                          <strong>Финальные статусы:</strong> Выполненная, просроченная, выполненная с корректировкой
                        </p>
                      </div>
                    </div>
                  </div>
                  <ChangeOrderStatus
                    updateData={() => updateData()}
                    togglePopUp={togglePopUp}
                    show={showChangeStatusModal}
                  />
                  <div className="row gy-4 mb-4">
                    <div className="col-md-12 col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title text-primary ms-3">Архив всех заявок</h4>
                          {isEmpty(OrderCunsumer.orders) ? (
                            <h5 className="text-center text-muted my-5"> Заявок пока нету...</h5>
                          ) : (
                            <div className="table-responsive">
                              <div id="dataTableExample_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <DataTableExtensions {...tableData} >
                                  <DataTable
                                    title={false}
                                    columns={columns}
                                    highlightOnHover={true}
                                    striped={true}
                                    pointerOnHover={true}
                                    className={"table dataTable"}
                                    pagination={true}
                                    noHeader={true}
                                    theme="solarized"
                                    paginationPerPage={50}
                                    selectableRows={true}
                                    selectableRowsComponent={Checkbox}
                                    selectableRowsComponentProps={{ color: 'primary' }}
                                    selectableRowsVisibleOnly={true}
                                    clearSelectedRows={clearOrderArray}
                                    selectableRowsHighlight={true}
                                    onSelectedRowsChange={hendleSelected}
                                    paginationRowsPerPageOptions={[
                                      50,
                                      100,
                                      200,
                                      300,
                                      400,
                                    ]}
                                  />
                                </DataTableExtensions>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )} 
    </>
  );
};

export default Archive;