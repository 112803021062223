import React, { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import Loader from "../../../components/Loader";
import { sendNotification } from "../../../helpers/notification";
import { OrderContext } from "../../../context/orders-context";

const ChangeStatusModal = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [order, setOrder] = React.useState();
  const [amountError, setAmountError] = React.useState(false);
  const OrderCunsumer = useContext(OrderContext);
  const { show } = props;
  const simulateClick = React.useRef(null);

  useEffect(() => {
    if (!isEmpty(OrderCunsumer.activeOrder)) {
      let order = OrderCunsumer.orders.filter((obj) => {
        return obj.id === OrderCunsumer.activeOrder;
      });
      setOrder(order[0]);
      setAmountError(false)
      setLoading(false);
    }
  }, [OrderCunsumer.activeOrder]); 

  const updateField = async (event) => {
    if (event.target.name === 'amount' && amountError) {
      setAmountError(false)
    }
    await OrderCunsumer.updateState(event.target.name, event.target.value);
  };
  
  const getBadgeClass = (status) => {
    switch (status) {
      case 'CREATED':
        return 'text-info'
      case 'PENDING':
        return 'text-primary'
      case 'SUCCESS':
        return 'text-success'
      case 'EDITED':
        return 'text-dark'
      case 'EXPIRED':
        return 'text-danger'
      default:
        return 'text-warning'
    }
  }

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'НОВАЯ'
      case 'PENDING':
        return 'В РАБОТЕ'
      case 'SUCCESS':
        return 'ВЫПОЛНЕННАЯ'
      case 'EDITED':
        return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
      case 'EXPIRED':
        return 'ПРОСРОЧЕННАЯ'
      default:
        return status;
    }
  }

  const submit = async () => {
    setSubmitLoading(true);
    if (isEmpty(OrderCunsumer.status)) {
      sendNotification("danger", "Установите статус", "Вы не выбрали новый статус для заявки");
      setSubmitLoading(false);
      return;
    }
    if (OrderCunsumer.status === "EDITED" && isEmpty(OrderCunsumer.amount)) {
      setAmountError(true);
      setSubmitLoading(false);
      sendNotification("danger", "Введите сумму", "Нужно указать сумму, которая зашла на карту");
      return;
    }
    if (window.confirm(`Вы уверены, что хотите изменить статус заявки id: ${order.id} - с '${getStatus(order.status)}' на  '${getStatus(OrderCunsumer.status)}' ?`)) {
      return OrderCunsumer.updateOrderStatus()
        .then((res) => {
          if (res.status === 'success') {
            props.updateData();
            setSubmitLoading(false);
            sendNotification("success", "Статус изменен", res.message);
            if (res.callbackStatus === 200) sendNotification("success", " Коллбэк отправлен", "Коллбэк был успешно отправлен.");
            simulateClick.current.click()
          } else {
            props.updateData();
            simulateClick.current.click()
          }
        });
    } else {
      setSubmitLoading(false);
      return;
    }    
  };

  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content py-3 px-4">
            {loading ? (
              <Loader /> 
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title  my-3" id="exampleModalLabel">
                    Смена статуса заявки <span className="text-primary"> id: {order.id}</span>
                  </h5>
                  <button
                    type="button"
                    onClick={props.togglePopUp}
                    className="btn-close-x"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group" style={{ fontSize: 16 }}>
                          <p className="mb-2">ID заявки: <span className="text-white">{order.id}</span></p>
                          <p className="mb-2">Внешний id: <span className="text-white">{order.externalId}</span></p>
                          <p className="mb-2">Текущий статус: <span className={` ${getBadgeClass(order.status)}`}>{ getStatus(order.status) }</span></p>
                          <p className="mb-2">Сумма: <span className="text-white"> {order.amount}</span></p>
                          <p className="mb-2">Карта: <span className="text-white"> {order.cardNumber ? order.cardNumber : 'новая'}</span></p>
                          <p className="mb-2">Банк: <span className="text-white"> {order.cardProvider ? order.cardProvider : 'новая'}</span></p>      
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group" style={{ fontSize: 16 }}>
                          <p className="mb-2">Заявку создал пользователь id: <span className="text-white"> {order.userId}</span></p>
                          {order.pendingAt && <p className="mb-2">В работе с:<span className="text-white"> {order.pendingAt}</span></p>}
                          <p className="mb-2">Время создания: <span className="text-white"> {order && order.createdAt}</span></p>
                          <p className="mb-2">Хеш: <span className="text-white"> {order.hash}</span></p>
                          <p className="mb-2">Callback url: <span className="text-white"> {order.callbackUrl ? order.callbackUrl : 'нету'}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="my-3 text-primary" style={{ fontSize: 16 }} htmlFor="status">
                            Изменить статус на:
                          </label>
                          <select
                            onChange={updateField}
                            value={OrderCunsumer.status ? OrderCunsumer.status : ''}
                            className="form-control text-primary"
                            name="status"
                            id="status"
                            required
                          >
                            <option defaultValue>Выберите статус</option>
                            <option value={"SUCCESS"}>Выполненная</option>
                            <option value={"EDITED"}>Выполненная с корректировкой</option>
                            <option value={"EXPIRED"}>Просроченная</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="my-3 text-primary"  style={{ fontSize: 16 }} htmlFor="comment">
                            Комментарий:
                          </label>
                          <input
                            type="text"
                            onChange={updateField}
                            className="form-control text-primary"
                            name="comment"
                            id="comment"
                            value={OrderCunsumer.comment ? OrderCunsumer.comment : ''}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        {!isEmpty(OrderCunsumer.status) && OrderCunsumer.status === "EDITED" &&
                          <div className={`form-group ${amountError && 'has-danger'}`}>
                            <label style={{ fontSize: 16 }} htmlFor="amount" className={`my-3 ${amountError ? 'text-danger' : 'text-primary'}`}>
                              Сумма которая поступила на карту:
                            </label>
                            <input
                              type="text"
                              onChange={updateField}
                              className={`form-control w-75 ${amountError ? 'form-control-danger' : 'text-primary'}`}
                              name="amount"
                              id="amount"
                            />
                          </div>
                        }
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer mt-3">
                  <button type="button" ref={simulateClick} className="btn rounded-pill btn-outline-danger waves-effect" onClick={props.togglePopUp}>Закрыть</button>
                  {!submitLoading ? (
                    <button type="button" className="btn rounded-pill btn-outline-success waves-effect" onClick={(e) => submit(e)}>Сохранить</button>
                  ) : (
                    <button className="btn btn-outline-info rounded-pill waves-effect" type="button" >
                      Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeStatusModal;