import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Chart from 'react-apexcharts';
import "../../scss/cards-statistics.css";
import "../../scss/cards-analytics.css";

import AdminBar from "../../components/AdminBar";
import { UserContext } from "../../context/users-context";
import { CardContext } from "../../context/cards-context";
import { OrderContext } from "../../context/orders-context";

const White = () => {

  const [loading, setLoading] = React.useState(true);
  const UserCunsumer = useContext(UserContext); 
  const CardCunsumer = useContext(CardContext);
  const OrderCunsumer = useContext(OrderContext);

  useEffect(() => {
    UserCunsumer.getUsers();
    setLoading(false);
    return () => UserCunsumer.clear();
  }, []); 

  useEffect(() => {
    CardCunsumer.getCards();
    setLoading(false);
    return () => {
      CardCunsumer.clean();
    }
  }, []); 

  useEffect(() => {
    OrderCunsumer.getStat();
    setLoading(false)
    return () => OrderCunsumer.clean();
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'Активная'
      case 'ONHOLD':
        return 'На удержании'
      case 'NOTACTIVE':
        return 'Не активная'
      default:
        return status;
    }
  }

  const getBadgeClass = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'bg-label-success'
      case 'ONHOLD':
        return 'bg-label-warning'
      case 'NOTACTIVE':
        return 'bg-label-danger'
      default:
        return 'bg-label-primary'
    }
  }
  
  const getImageNumber = (max) => {
    return Math.floor(Math.random() * max);
  }

  let stats_1 = {
    options: {
      chart: {
        height: 235,
        type: 'area',
        parentHeightOffset: 0,
        offsetY: -8,
        toolbar: { show: false }
      },
      tooltip: { enabled: false },
      dataLabels: { enabled: false },
      stroke: {
        width: 5,
        curve: 'smooth'
      },
      grid: {
        show: false,
        padding: {
          left: 10,
          top: 0,
          right: 12
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityTo: 0.7,
          opacityFrom: 0.5,
          shadeIntensity: 1,
          stops: [0, 90, 100],
          colorStops: [
            [
              {
                offset: 0,
                opacity: 0.6,
                color: "#72e128"
              },
              {
                offset: 100,
                opacity: 0.1,
                color: "#464963"
              }
            ]
          ]
        }
      },
      theme: {
        monochrome: {
          enabled: true,
          shadeTo: 'light',
          shadeIntensity: 1,
          color: '#72e128'
        }
      },
      xaxis: {
        type: 'numeric',
        labels: { show: false },
        axisTicks: { show: false },
        axisBorder: { show: false }
      },
      yaxis: { show: false },
      markers: {
        size: 1,
        offsetY: 1,
        offsetX: -5,
        strokeWidth: 4,
        strokeOpacity: 1,
        colors: ['transparent'],
        strokeColors: 'transparent',
        discrete: [
          {
            size: 7,
            seriesIndex: 0,
            dataPointIndex: 7,
            strokeColor: "#72e128",
            fillColor: "#464963"
          }
        ]
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 255
            }
          }
        },
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 300
            }
          }
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              height: 240
            }
          }
        }
      ]
    },
    series: [
      {
        data: [0, 85, 50, 125, 90, 250, 200, 280]
      }
    ],
  };

  let stats_2 = {
    options: 
      {
        chart: {
          height: 328,
          type: 'bar',
          parentHeightOffset: 0,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            distributed: true,
            columnWidth: '55%',
            endingShape: 'rounded',
            startingShape: 'rounded'
          }
        },
        tooltip: {
          enabled: false
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        colors: [
          '#666cff',
          '#666cff',
          '#666cff',
          '#666cff',
          '#666cff',
          '#666cff',
          '#666cff',
        ],
        grid: {
          show: false,
          padding: {
            top: -15,
            left: -7,
            right: -4
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          },
          active: {
            filter: {
              type: 'none'
            }
          }
        },
        xaxis: {
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          categories: ['1', '2', '3', '4', '5', '6', '7'],
          labels: {
            style: {
              colors: '#4884ed'
            }
          }
        },
        yaxis: { show: false },
        responsive: [
          {
            breakpoint: 1240,
            options: {
              chart: {
                height: 307
              }
            }
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 327
              }
            }
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 250
              },
              plotOptions: {
                bar: {
                  columnWidth: '35%'
                }
              }
            }
          },
          {
            breakpoint: 577,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: '45%'
                }
              }
            }
          },
          {
            breakpoint: 477,
            options: {
              plotOptions: {
                bar: {
                  columnWidth: '50%'
                }
              }
            }
          }
        ]
      },
    series: [
      {
        data: [38, 55, 48, 65, 80, 38, 48]
      }
    ],
  }

  let stats_3 = {
    options: {
    chart: {
      height: 153,
      parentHeightOffset: 0,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '43%',
        endingShape: 'rounded',
        startingShape: 'rounded'
      }
    },
    colors: ['#ff4d49'],
    grid: {
      padding: {
        top: -4,
        left: -20,
        right: -2,
        bottom: -7
      },
      yaxis: { lines: { show: false } }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    responsive: [
      {
        breakpoint: 1443,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 7
            }
          }
        }
      },
      {
        breakpoint: 1372,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6
            }
          }
        }
      },
      {
        breakpoint: 1248,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 5
            }
          }
        }
      },
      {
        breakpoint: 1200,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6
            }
          }
        }
      },
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 8
            }
          },
          chart: {
            height: 156
          }
        }
      },
      {
        breakpoint: 838,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6
            }
          }
        }
      },
      {
        breakpoint: 644,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 4
            }
          }
        }
      },
      {
        breakpoint: 474,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 7
            }
          }
        }
      },
      {
        breakpoint: 383,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 6
            }
          }
        }
      }
    ],
  },
    series: [
      {
        name: 'Сумма',
        data: [70, 118, 92, 49, 19, 49, 23, 82, 65, 23, 49, 65, 65]
      }
    ],
  }

  return(
    <>
      <div className='layout-wrapper layout-content-navbar'>
        <div className='layout-container'>
          <AdminBar />
          <div className=' layout-page layout-page-pt'>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme nav-menu-mobil-media" id="layout-navbar">
              <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                <div className="navbar-nav align-items-center">
                  <div className="nav-item navbar-search-wrapper mb-0">
                    <a onClick = { () => {UserCunsumer.setOpenMenu(true)}}>
                      <i className="mdi mdi-menu mdi-24px me-3"></i>
                    </a>
                    <Link to='/user/home' className='sidebar-brand'>
                      <img src={require('../../images/logo2.png')} className='me-5' width={'110px'} alt='' onClick = {() => {UserCunsumer.setOpenMenu(false)}}/>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
            <div className="content-wrapper pt-75">
              <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row gy-4 mb-4">
                  <div className="col-md-12 col-lg-12">
                    <div className="card">
                      <div className="d-flex align-items-end row">
                        <div className="col-md-7 order-2 order-md-1">
                          <div className="card-body ps-5" >
                            <h4 className="card-title pb-xl-2"> Добро пожаловать! 🎉</h4>
                            <div className="row">
                              <div className="col-12 col-lg-7">
                                <p className="mb-0">Заявки уже ждут<span className="fw-semibold"> вас</span>😎</p>
                                <p>Проверьте новые заявки в своем профиле.</p>
                              </div>
                              <div className="col-12 col-lg-5 ps-3 pt-1">
                                <Link to='/admin/orders'>
                                  <button  className="btn btn-primary waves-effect waves-light media-btn-sm">Перейти к заявкам </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 text-center text-md-end order-1 order-md-2">
                          <div className="card-body pb-0 px-0 px-md-4 ps-0">
                            <img src={require('../../images/illustrations/illustration-john-dark.png')}  height={180}  />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card h-100 p-3">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title mb-0 me-2">Пользователи</h5>
                        <div className="dropdown">
                          <button className="btn p-0" type="button" id="meetingSchedule" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="mdi mdi-dots-vertical mdi-24px"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body pt-4">
                        {!isEmpty(UserCunsumer.users) ? (
                          UserCunsumer.users && (UserCunsumer.users.reverse().slice((UserCunsumer.users.length - 3), UserCunsumer.users.length)).map( (item, index) => (
                            <ul className="p-0 m-0" key={index}>
                              <li className="d-flex pb-1 media-mt-2"  >
                                <div className="avatar flex-shrink-0 me-3 media-mt-2">
                                  <img src={require(`../../images/avatars/${getImageNumber(8)}.png`)} alt="avatar" className="rounded"/>
                                </div>
                                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                  <div className="me-2">
                                    <h6 className="mb-0 fw-semibold">{item.email}</h6>
                                    <small className="text-muted"><p className='media-m-none media-mt-2'>Id {item.id} <br/>Баланс {item.balance} руб</p></small>
                                  </div>
                                  <div className="badge bg-label-primary rounded-pill">{item.nickname}</div>
                                </div>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <>
                          <ul className="p-0 m-0">
                            <div className='mt-3 mb-5'>
                              <h6 className="mb-0 me-2">Здесь будет отображаться список пользователей</h6>
                            </div>
                            <li className="d-flex my-3 pb-1">
                              <div className="avatar flex-shrink-0 me-3">
                                <img src={require('../../images/avatars/1.png')} alt="avatar" className="rounded"/>
                              </div>
                              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                <div className="me-2">
                                  <h6 className="mb-0 fw-semibold">test@gmail.com</h6>
                                  <small className="text-muted"><p>Id 2 <br/>Баланс 2039 руб</p></small>
                                </div>
                                <div className="badge bg-label-warning rounded-pill">Admin</div>
                              </div>
                            </li>
                            </ul>
                          </>
                        )}
                          {!isEmpty(UserCunsumer.users) ? (
                          <div>
                            <Link to='/admin/users' className="btn btn-success btn-sm waves-effect waves-light mt-4">все пользователи</Link>
                          </div>
                          ) : (
                          <div>
                            <Link to='/admin/users' className="btn btn-success btn-sm waves-effect waves-light mt-4">все пользователи</Link>
                          </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="card h-100 p-3 media-p-none">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="card-title m-0 me-2">Последние карты</h5>
                        <div className="dropdown">
                          <button className="btn p-0" type="button" id="paymentHistory" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="mdi mdi-dots-vertical mdi-24px"></i>
                          </button>
                        </div>
                      </div>
                      <div className="table-responsive text-nowrap">
                        {!isEmpty(CardCunsumer.cards) ? (
                            <table className="table table-borderless">
                              <thead >
                                <tr>
                                  <th className="text-capitalize text-body fw-medium fs-6 px-10">Карта</th>
                                  <th className="text-capitalize text-body fw-medium fs-6 px-10">Банк</th>
                                  <th className="text-end text-capitalize text-body fw-medium fs-6 px-10">Статус</th>
                                </tr>
                              </thead>
                              <tbody className='border-top border-bottom'>
                                {CardCunsumer.cards && (CardCunsumer.cards.reverse().slice((CardCunsumer.cards.length - 3), CardCunsumer.cards.length)).map( (item, index) => (
                                  <tr key={index}>
                                    <td className="d-flex mt-3 px-10">
                                      <div className="px-2 rounded bg-lighter d-flex align-items-center h-px-30 media-d-none me-3">
                                        <img src={require(`../../images/icons/payments/${getImageNumber(2)}.png`)} alt="credit-card" width="30"/>
                                      </div>
                                      <div className="">
                                        <h6 className="mb-0 fw-semibold">Id: {item.id}</h6>
                                        <small className="text-muted">{item.number}</small>
                                      </div>
                                    </td>
                                    <td className="text-muted small px-10">{item.provider}</td>
                                    <td className="text-end px-10">
                                      <div className={`badge ${getBadgeClass(item.status)}  rounded-pill`}>
                                        {getStatus(item.status)}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                        ) : (
                          <>
                            <div className='my-3 ms-3'>
                              <div className="row">
                                <div className="col-7">
                                  <h6 className="mb-0">У вас пока нет карт. Добавте их! </h6>
                                </div>
                                <div className="col-4 mb-2 ms-3">
                                  <Link to='/admin/add/card' className="btn btn-danger btn-sm waves-effect waves-light">добавить карту</Link>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-8 mt-4 ms-3">
                              <div className="alert alert-success alert-dismissible ps-4 py-4" role="alert" style={{maxHeight: '200px'}}>
                                <h5 className="alert-heading d-flex align-items-center mt-2">
                                  <i className="mdi mdi-credit-card mdi-24px me-2"></i>Карта
                                </h5>
                                <p className="mb-3" style={{textTransform: 'uppercase'}}>1234 5678 9012 3456</p>
                                <p className="mb-2" style={{textTransform: 'uppercase', fontSize: '13px'}}>Активная</p>
                                <p className="mb-0" style={{textTransform: 'uppercase', fontSize: '13px'}}>Visa</p>
                              </div>
                            </div>
                          </>
                        )}
                        {!isEmpty(CardCunsumer.cards) ? (
                          <div className="my-4 text-end me-3">
                            <Link to='/admin/cards' className="btn btn-danger btn-sm waves-effect waves-light">все карты</Link>
                          </div>
                        ) : ('')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4 mb-4">
                  <div className="col-12 col-xl-7">
                    <div className="card p-3 media-p-none">
                      <div className="card-header d-flex justify-content-between">
                        <div className="card-title m-0">
                          <small className="text-muted">Cтатистикa</small>
                        </div>
                      </div>
                      <div className="card-body pb-1">
                        <ul className="nav nav-tabs nav-tabs-widget pb-3 gap-4 mx-1 d-flex flex-nowrap" role="tablist">
                          <li className="nav-item" role="presentation">
                            <div className="nav-link btn d-flex flex-column align-items-center justify-content-center  page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-orders-id" aria-controls="navs-orders-id" aria-selected="true">
                              <button type="button" className="btn btn-icon rounded-pill btn-label-success waves-effect page-white-icon">
                                <img src={require('../../images/favicon/logoicon1.png')} width={30}/>
                              </button>
                            </div>
                          </li>
                          <li className="nav-item" role="presentation">
                            <div className="nav-link btn d-flex flex-column align-items-center justify-content-center page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-sales-id" aria-controls="navs-sales-id" aria-selected="false" tabIndex="-1">
                              <button type="button" className="btn btn-icon rounded-pill btn-label-primary waves-effect  page-white-icon">
                                <img src={require('../../images/favicon/logoicon2.png')} width={30}/>
                              </button>
                            </div>
                          </li>
                          <li className="nav-item" role="presentation">
                            <div className="nav-link btn d-flex flex-column align-items-center justify-content-center page-white" role="tab" data-bs-toggle="tab" data-bs-target="#navs-profit-id" aria-controls="navs-profit-id" aria-selected="false" tabIndex="-1">
                              <button type="button" className="btn btn-icon rounded-pill btn-label-instagram waves-effect  page-white-icon">
                                <img src={require('../../images/favicon/logoicon3.png')} width={30}/>
                              </button>
                            </div>
                          </li>
                          <span className="tab-slider" style={{left: '0px', width: '112px', bottom: '0px'}}></span>
                        </ul>
                        {!isEmpty(OrderCunsumer.stats) ? (
                          <div className="tab-content p-0 pt-3 ms-0 ms-sm-2">
                            <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                              <div className="table-responsive text-nowrap">
                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th className="fw-medium ps-0 text-heading">Статус</th>
                                      <th className="pe-0 fw-medium text-heading">Заявки</th>
                                      <th className="pe-0 text-end text-heading">Сумма</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-success media-fs-12">Выполненные</span></td>
                                      <td className="pe-0 text-success ">{OrderCunsumer.stats.deposited_orders_count} шт.</td>
                                      <td className="pe-0 text-success text-end fw-semibold h6">{OrderCunsumer.stats.deposited_orders_total_sum} руб.</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-primary media-fs-12">В процессе</span></td>
                                      <td className="text-primary pe-0">{OrderCunsumer.stats.pending_orders_count} шт.</td>
                                      <td className="text-primary pe-0 text-end fw-semibold h6">{OrderCunsumer.stats.pending_orders_total_sum} руб.</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-danger media-fs-12">Просроченные</span></td>
                                      <td className="text-danger pe-0">{OrderCunsumer.stats.expired_orders_count} шт.</td>
                                      <td className="text-danger pe-0 text-end">
                                        <Link to='/admin/orders/stats' >
                                          <button className="btn btn-danger btn-sm waves-effect waves-light media-d-none">Смотреть статистику</button>
                                        </Link>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="tab-content p-0 pt-3 ms-0 ms-sm-2">
                            <div className="tab-pane fade show active" id="navs-orders-id" role="tabpanel">
                              <div className="table-responsive text-nowrap">
                                <table className="table table-borderless">
                                  <thead>
                                    <tr>
                                      <th className="fw-medium ps-0 text-heading">Статус</th>
                                      <th className="pe-0 fw-medium text-heading">Заявки</th>
                                      <th className="pe-0 text-end text-heading">Сумма</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-success">Выполненные</span></td>
                                      <td className="pe-0 text-success">10 шт.</td>
                                      <td className="pe-0 text-success text-end fw-semibold h6">43580 руб.</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-primary">В процессе</span></td>
                                      <td className="text-primary pe-0">16 шт.</td>
                                      <td className="text-primary pe-0 text-end fw-semibold h6">61236 руб.</td>
                                    </tr>
                                    <tr>
                                      <td className="px-0"><span style={{fontSize: '14px'}} className="badge rounded-pill bg-label-danger">Просроченные</span></td>
                                      <td className="text-danger pe-0">3 шт.</td>
                                      <td className="text-danger pe-0 text-end">
                                        <a href='/admin/orders/stats' className="btn btn-danger btn-sm waves-effect waves-light">Смотреть статистику</a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 h-100">
                    <div className="row gy-4">
                      <div className="col-lg-12 col-sm-12">
                        <div className="card h-100">
                          <div className="row">
                            <div className="col-12 col-lg-6">
                              <div className="card-body ps-4">
                                <div className="card-info mb-3 py-2 mb-lg-1 mb-xl-3">
                                  <h5 className="mb-3 mb-lg-2 mb-xl-3 text-nowrap">Архив</h5>
                                  <div className="badge bg-label-primary rounded-pill lh-xs">Все заявки</div>
                                </div>
                                <div className="d-flex justify-content-between mt-3">
                                  <div>
                                    <h6 className="mb-1 fw-semibold">Смотреть </h6>
                                    <p className="mb-0 text-muted">за все время</p>
                                  </div>
                                  <div className="avatar">
                                    <div className="avatar-initial bg-label-primary rounded">
                                      <Link to='/admin/orders/archive' >
                                        <i className="mdi mdi-chevron-right mdi-24px scaleX-n1-rtl"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 text-end d-flex align-items-end justify-content-center">
                              <div className="card-body pb-0 pt-3 position-absolute bottom-0">
                                <img src={require("../../images/illustrations/card-ratings-illustration.png")} alt="Ratings" width="80"/>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12">
                        <div className="card h-100">
                          <div className="row">
                            <div className="col-5 d-flex text-center ">
                              <div className="card-body my-3">
                                <img style={{opacity: "0.85"}} src={require("../../images/2fa2.png")} alt="Ratings" width="100"/>
                              </div>
                            </div>
                            <div className="col-7 text-end pe-4">
                              <div className="card-body">
                                <div className="card-info mb-3 mt-3 mb-lg-1 mb-xl-3">
                                  <h6 className="mb-3 mb-lg-2 mb-xl-3 media-fs-12 text-nowrap">Настройки пользователя</h6>
                                </div>
                                <div className="d-flex justify-content-end mt-3">
                                  <div className='mt-4'>
                                    <button type="button" className="btn btn-warning btn-sm waves-effect waves-light">
                                      <Link to='/admin/settings' className='text-white'>Подключить 2FA</Link>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row gy-4  mb-4">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="card h-100">
                      <div className="card-header pb-1">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-1">Cтатистикa</h5>
                          <div className="dropdown">
                            <button className="btn p-0" type="button" id="monthlyBudgetDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="mdi mdi-dots-vertical mdi-24px"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body" style={{position: 'relative'}}>
                        <div className="col-md-4">  
                          <Chart options={stats_1.options} series={stats_1.series} type="area" width={300} height={200} />
                        </div>
                        <div className="mt-3">
                          <p className="mb-0 text-muted">Cтатистикa за месяц по заявкам</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-1">Cтатистикa</h5>
                          <div className="dropdown">
                            <button className="btn p-0" type="button" id="externalLinksDropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i className="mdi mdi-dots-vertical mdi-24px"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body" style={{position: 'relative'}}>
                        <div className="col-md-4">  
                          <Chart options={stats_2.options} series={stats_2.series} type="bar" width={300} height={200} />
                        </div>
                        <div className="table-responsive text-nowrap">
                          <table className="table table-borderless">
                            <tbody>
                              <tr>
                                <td className="text-start pb-0 ps-0">
                                  <div className="d-flex align-items-center">
                                    <div className="badge badge-dot bg-primary me-2"></div>
                                    <h6 className="mb-0 fw-semibold">Заявки</h6>
                                  </div>
                                </td>
                                <td className="pb-0">
                                  <p className="mb-0 text-muted">845k</p>
                                </td>
                                <td className="pe-0 pb-0">
                                  <div className="d-flex align-items-center justify-content-end">
                                    <h6 className="mb-0 fw-semibold me-2">82%</h6>
                                    <i className="mdi mdi-chevron-up text-success"></i>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="card h-100">
                      <div className="card-header pb-0">
                        <div className="d-flex justify-content-between mb-1">
                          <h4 className="mb-0">Cтатистикa</h4>
                          <div className="d-flex text-danger">
                            <p className="mb-0 me-2">+78.2%</p>
                            <i className="mdi mdi-chevron-up"></i>
                          </div>
                        </div>
                        <small className="text-muted">Карты</small>
                      </div>
                      <div className="card-body ms-1" style={{position: 'relative'}}>
                        <div className="col-md-4">  
                          <Chart options={stats_3.options} series={stats_3.series} type="bar" width={300} height={200} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default White;