import React, { useEffect } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

import Loader from "../../../components/Loader";

moment.locale('ru'); 

const Corretions = (props) => {

  const [loading, setLoading] = React.useState(true);
  const { show } = props;

  useEffect(() => {
    setLoading(false);
  }, [props]); 

  const getColor = (num) => {
		switch (num) {
		  case 0:
			return 'info'
		  case 1:
			return 'success'
		  case 2:
			return 'warning'
		  case 3:
			return 'primary'
		  case 4:
			return 'danger'
		  default:
			return 'secondary';
		}
	}
  
  return (
    <>
      <div
        className={`modal fade ${show === true ? "show" : "d-none"}`}
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content py-3 px-4">
            {loading ? (
              <Loader /> 
            ) : (
              <>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    <span className="text-primary">История изменений баланса </span> (oт новых к старым)
                  </h5>
                  <button
                    type="button"
                    onClick={props.togglePopUp}
                    className="btn-close-x"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row mt-3 mx-3">
                    {!isEmpty(props.data) ? props.data.map((item, index) => (
                      <ul className="timeline card-timeline mb-0" key={index}>
                        <li className="timeline-item timeline-item-transparent">
                          <span className={`timeline-point timeline-point-${getColor(index)}`}></span>
                          <div className="timeline-event">
                            <div className="timeline-header mb-1">
                            <h5 className={`mb-2 fw-semibold text-${getColor(index)}`}>{moment(item.date).format("DD.MM.YYYY, H:mm:ss")}</h5>
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <p className="text-muted mb-2">
                                  Сумма корректировки: <span className="text-white"> {item.amount}</span>
                                </p>
                                <p className="text-muted mb-2">
                                  Баланс <strong>до</strong> корректировки: <span className="text-white"> {item.balanceBefore}</span>
                                </p>
                                <p className="text-muted mb-2">
                                  Баланс <strong>после</strong> корректировки: <span className="text-white"> {item.balanceAfter}</span>
                                </p>
                                <p className="text-muted mb-2">
                                  Комментарий: <span className="text-white"> {item.comment}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    )) : (
                      <div className="col-md-12 mt-3">
                        <h6 >По даному пользователю пока нету корректировок.</h6>
                        <div className="alert alert-info mt-5" role="alert">
                          <p>
                            <i className='tf-icons mdi mdi-clipboard-plus-outline me-2 '></i>
                            <strong>Вы пожете создать первую корректировку</strong>, нажав на кнопку "Корректировка баланса", дальше Вам надо ввести сумму и если нужно комментарий.
                          </p>
                          <hr />
                          <p>
                            <i className='tf-icons mdi mdi-clipboard-minus-outline me-2 '></i>
                            <strong>Если Вы хотите отнять от баланса сумму</strong>, то вам нужно в сумме корректировки ввести сумму со знаком минус. Пример: -1000.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn rounded-pill btn-outline-danger waves-effect" onClick={props.togglePopUp}>Закрыть</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Corretions;