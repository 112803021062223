import React, { useContext, useEffect } from "react";
import { Redirect, useParams, Link } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";

import AdminBar from "../../components/AdminBar";
import Loader from "../../components/Loader";
import { sendNotification } from "../../helpers/notification";
import { OrderContext } from "../../context/orders-context";

moment.locale('ru'); 

const EditOrder = () => {

	const [loading, setLoading] = React.useState(true);
	const [submitLoading, setSubmitLoading] = React.useState(false);
	const [redirect, setRedirect] = React.useState(false);
	const [redirectPath, setRedirectPath] = React.useState("");
	const [amountError, setAmountError] = React.useState(false);
	const OrderCunsumer = useContext(OrderContext);
	const { id } = useParams();

	useEffect(() => {
		OrderCunsumer.getOrder(id);
		return () => OrderCunsumer.clean();
	}, []); 

	useEffect(() => {
		if (!isEmpty(OrderCunsumer.order)) {
			setLoading(false)
		}
	}, [OrderCunsumer.order]);

	useEffect(() => {
		if (OrderCunsumer.redirect === true) {
			sendNotification("warning", "Ошибка ⚠️", OrderCunsumer.redirectMessage);
			setRedirectPath(OrderCunsumer.redirectPath);
			OrderCunsumer.clean();
			setRedirect(true);
		}
	}, [OrderCunsumer.redirect]); 

	const renderRedirect = () => {
		if (redirect && redirectPath) {
		return (
			<Redirect to={{ pathname: redirectPath,}}/>
		)}
	};

	const updateField = async (event) => {
		if (event.target.name === 'amount' && amountError) {
			setAmountError(false)
		}
		await OrderCunsumer.updateState(event.target.name, event.target.value);
	};

	const submit = async () => {
		setSubmitLoading(true);
		if (OrderCunsumer.status === "EDITED" && isEmpty(OrderCunsumer.amount)) {
			setAmountError(true);
			setSubmitLoading(false);
			sendNotification("danger", "Введите сумму", "Нужно указать сумму, которая зашла на карту");
			return;
		}
		if (window.confirm(`Вы подтверждаете изменение заявки id: ${OrderCunsumer.order.id} ?`)) {
			return OrderCunsumer.updateOrderDetails()
			.then((res) => {
				if (res.status === 'success') {
				setSubmitLoading(false);
				sendNotification("success", "Заявка обновлена", "Не забудьте проделать нужные действия. Смотрите блок 'Важно'.");
				} else {
					setSubmitLoading(false);
				}
			});
		} else {
		setSubmitLoading(false);
		return;
		}   
	};

	const getColor = (num) => {
		switch (num) {
		  case 0:
			return 'danger'
		  case 1:
			return 'success'
		  case 2:
			return 'warning'
		  case 3:
			return 'primary'
		  case 4:
			return 'info'
		  default:
			return 'secondary';
		}
	}

	const getStatus = (status) => {
		switch (status) {
		  case 'CREATED':
			return 'НОВАЯ'
		  case 'PENDING':
			return 'В ПРОЦЕССЕ'
		  case 'SUCCESS':
			return 'ВЫПОЛНЕННАЯ'
		  case 'EDITED':
			return 'ВЫПОЛНЕННАЯ С КОРРЕКТИРОВКОЙ'
		  case 'EXPIRED':
			return 'ПРОСРОЧЕННАЯ'
		  default:
			return status;
		}
	  }
	
	const getBadgeClass = (status) => {
		switch (status) {
		  case 'CREATED':
			return 'info'
		  case 'PENDING':
			return 'primary'
		  case 'SUCCESS':
			return 'success'
		  case 'EDITED':
			return 'warning'
		  case 'EXPIRED':
			return 'danger'
		  default:
			return 'dark'
		}
	  }

  	return (
		<>
			{renderRedirect()}
			<div className='layout-wrapper layout-content-navbar'>
				<div className='layout-container'>
					<AdminBar />
					<div className='layout-page'>
						{loading === true ? (
							<Loader />
						) : ( 
							<> 
								<nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
									<div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
										<div className="navbar-nav align-items-center">
											<div className="nav-item navbar-search-wrapper mb-0">
												<h4 className="align-middle">Редактирование заявки
													<i className='ms-2 menu-icon tf-icons mdi mdi-border-color'></i>
												</h4>
											</div>
										</div>
									</div>
								</nav> 
								<div className="content-wrapper">
									<div className="container-xxl flex-grow-1 container-p-y">
										<div className="row gy-4 mb-4">
											<div className="col-md-12">
												<div className="card">
													<div className="card-body p-5">
														<h4 className="card-title text-primary">Заявка #{OrderCunsumer?.order?.id ? OrderCunsumer.order.id : ''}</h4>
														<hr />
														<div className="row">
															<div className="col-md-3">
																<div className="form-group">
																	<label className="mb-2" style={{ fontSize: 13 }}>
																		<strong>User id:</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={OrderCunsumer?.order?.userId ? OrderCunsumer.order.userId : ""}
																		disabled
																	/>
																</div>
																<div className="form-group">
																	<label className="my-2" style={{ fontSize: 13 }}>
																		<strong>Внешний id:</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={OrderCunsumer?.order?.externalId ? OrderCunsumer.order.externalId : ''}
																		disabled
																	/>
																</div>
																<div className="form-group">
																	<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="status">
																		<strong>Статус заявки:</strong>
																	</label>
																	<select
																		onChange={updateField}
																		value={OrderCunsumer?.status ? OrderCunsumer.status : OrderCunsumer.order.status}
																		className="form-control input-active"
																		name="status"
																		id="status"
																	>
																		<option defaultValue>
																			Выберите статус
																		</option>
																		<option value={"PENDING"}>В работе</option> 
																		<option value={"SUCCESS"}>Выполненная</option>
																		<option value={"EDITED"}>Выполненная с корректировкой</option>
																		<option value={"EXPIRED"}>Просроченная</option>
																	</select>
																</div>															
															</div>
															<div className="col-md-3">
																<div className="form-group">
																	<label className="mb-2" style={{ fontSize: 13 }}>
																		<strong>Дата создания:</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={moment(OrderCunsumer?.order?.createdAt ? OrderCunsumer.order.createdAt : '').format("D/MM/YYYY, H:mm:ss")}
																		disabled
																	/>
																</div>
																<div className="form-group">
																	<label className="my-2" style={{ fontSize: 13 }}>
																		<strong>Принята в статус "В работе":</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={moment(OrderCunsumer?.order?.pendingAt ? OrderCunsumer.order.pendingAt : '').format("D/MM/YYYY, H:mm:ss")}
																		disabled
																	/>
																</div>
																<div className="form-group">
																	<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="cardNumber">
																		<strong>Номер карты:</strong>
																	</label>
																	<input
																		type="text"
																		onChange={updateField}
																		className="form-control input-active"
																		name="cardNumber"
																		id="cardNumber"
																		value={OrderCunsumer?.cardNumber ? OrderCunsumer.cardNumber : OrderCunsumer.order.cardNumber}
																	/>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label className="mb-2"  style={{ fontSize: 13 }} htmlFor="cardNumber">
																		<strong>Hash:</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={OrderCunsumer?.order?.hash ? OrderCunsumer.order.hash  : ''}
																		disabled
																	/>
																</div>
																<div className="form-group">
																	<label className="my-2"  style={{ fontSize: 13 }} htmlFor="cardNumber">
																		<strong>Callback:</strong>
																	</label>
																	<input
																		type="text"
																		className="form-control"
																		value={OrderCunsumer?.order?.callbackUrl ? OrderCunsumer.order.callbackUrl : ''}
																		disabled
																	/>
																</div>
																<div className={`form-group ${amountError && 'has-danger'}`}>
																	<label style={{ fontSize: 13 }} htmlFor="amount" className={`my-2 ${amountError ? 'text-danger' : 'text-info'}`}>
																		<strong>Сумма, которая поступила на карту:</strong>
																	</label>
																	<input
																		type="number"
																		onChange={updateField}
																		className={`form-control ${amountError ? 'form-control-danger' : 'input-active'}`}
																		name="amount"
																		id="amount"
																		placeholder={OrderCunsumer.order.amount}
																		value={OrderCunsumer.amount ? OrderCunsumer.amount : ''}
																	/> 
																</div>
															</div>
														</div>
														<div className="row mb-4">
															<div className="col-md-6">
																<div className="form-group">
																	<label className="my-2 text-info" style={{ fontSize: 13 }} htmlFor="comment">
																		<strong>Комментарий:</strong>
																	</label>
																	<input
																		type="text"
																		onChange={updateField}
																		className="form-control input-active"
																		name="comment"
																		id="comment"
																		placeholder={OrderCunsumer.order.comment}
																		value={OrderCunsumer.comment ? OrderCunsumer.comment : '' }
																	/>
																</div>
															</div>
															<div className="col-md-6">
																<div className="form-group">
																	<label className="my-2 text-info"  style={{ fontSize: 13 }} htmlFor="provider">
																		<strong>Банк:</strong>
																	</label>
																	<select
																		onChange={updateField}
																		className="form-control input-active"
																		name="cardProvider"
																		id="cardProvider"
																		placeholder={OrderCunsumer.cardProvider}
																		value={OrderCunsumer.cardProvider ? OrderCunsumer.cardProvider : OrderCunsumer.order.cardProvider}
																	>
																		<option value="" defaultValue>Выберите банк</option>
																		<option value={"sberbank"}>sberbank</option>
																		<option value={"tinkoff"}>tinkoff</option>
																		<option value={"yandex"}>yandex</option>
																		<option value={"visa/master"}>visa/master</option>
																	</select>
																</div>
															</div>
														</div>
														<hr />
														<div className="mt-4 d-flex justify-content-between">
															<button type="button" className="btn btn-outline-dark waves-effect">
																<Link className='text-dark' to="/admin/orders"> Назад к заявкам</Link>
															</button>
															{!submitLoading ? (
																<button type="button" onClick={(e) => submit(e)} className="btn btn-outline-success waves-effect">Сохранить</button>
															) : (
																<button type="button" className="btn btn-outline-info waves-effect">
																	Загрузка... <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
																</button>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="row gy-4">
											<div className="col-md-12">
												<div className="alert alert-warning px-5 py-4" role="alert">
													<h4 className="alert-heading ms-1">Внимание! Важно!<i className='menu-icon tf-icons mdi mdi-comment-alert ms-2 mb-3'></i></h4>
													<hr />
													<p>
														<i className='tf-icons mdi mdi-sync-alert me-2'></i>
														<strong>Если Вы меняете финальный статус</strong> - не забудьте переотправить коллбэк по заявке. Для этого есть пункт в меню.
													</p>
													<p>
														<i className='tf-icons mdi mdi-clipboard-alert-outline me-2'></i>
														<strong>Если Вы меняете сумму</strong> - не забудьте отредактировать лимиты на карте в разделе карт (Надо прибавить или убавить сумму по дневному и месячному лимиту) <strong>+ коллбэк.</strong>
													</p>
													<p>
														<i className='tf-icons mdi mdi-clipboard-text-clock-outline me-2'></i>
														<strong>История изменений </strong> - в истории изменений Вы видите старые (архивные) записи по заявке. 
													</p>
												</div>
											</div>
										</div>
										<div className="row mt-2">
											<div className="col-md-12">
												<div className="card">
													<div className="card-body  mb-0 px-5 py-4" style={{ whiteSpace: 'pre-wrap' }}>
														<h4 className="card-title text-primary ms-1">
															История изменений заявки<i className='menu-icon tf-icons mdi mdi-clipboard-text-clock-outline ms-2 mb-3'></i>
														</h4>
														<hr style={{marginBottom: '50px'}} />
														{isEmpty(OrderCunsumer.order.metadata) ? (
															<h5 className="text-center text-muted my-5">История изменений пуста...</h5>
														) : (
															OrderCunsumer.history && OrderCunsumer.history.map((item, index) => (
																<ul className="timeline card-timeline mb-0" key={index}>
																	<li className="timeline-item timeline-item-transparent">
																		<span className={`timeline-point timeline-point-${getColor(index)}`}></span>
																		<div className="timeline-event">
																			<div className="timeline-header mb-1">
																			<h5 className={`mb-2 fw-semibold text-${getColor(index)}`}>{moment(item.date).format('DD-MM-YYYY, Время: HH:mm')}</h5>
																			</div>
																			<div className="row">
																				<div className="col-6">
																					<p className="text-muted mb-2">
																						Статус заявки: <span className={`ms-2 badge bg-label-${getBadgeClass(item.data.status)}`}> {getStatus(item.data.status)}</span>
																					</p>
																					<p className="text-muted mb-2">
																						Номер карты:<span className="text-white"> {item.data.cardNumber}</span>
																					</p>
																					<p className="text-muted mb-2">
																						Банк:<span className="text-white"> {item.data.cardProvider}</span>
																					</p>
																					<p className="text-muted mb-2">
																						Сумма: <span className="text-white"> {item.data.amount}</span>
																					</p>
																					<p className="text-muted mb-2">
																						Комментарий:<span className="text-white"> {item.data.comment}</span>
																					</p>
																				</div>
																			</div>
																		</div>
																	</li>
																</ul>
															))
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>		
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default EditOrder;