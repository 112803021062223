import React, { useContext, useEffect } from "react";
import { sendNotification } from "../helpers/notification";
import { AuthContext } from "../context/auth-context";
import "../scss/page-auth.css";
import Loader from "../components/Loader";
import  secureLocalStorage  from  "react-secure-storage";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";

const Login = (props) => {

    const [loading, setLoading] = React.useState(true);
    const [showTwoFa, setShowTwoFa] = React.useState(false);
    const authCunsumer = useContext(AuthContext);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (props.location.notify) {
            sendNotification(
                props.location.notify.type,
                props.location.notify.title,
                props.location.notify.message
            );
        }
    }, [props.location.notify]);

    const updateField = async (event) => {
        await authCunsumer.updateState(event.target.name, event.target.value);
    };

    const submit = async () => {
        return authCunsumer
            .login()
            .then((user) => {
                let role = secureLocalStorage.getItem('role');
                if (!isEmpty(role) && !isEmpty(Cookies.get("jwt"))) {
                    return props.history.push(`/${role}/home`);
                }
            }).catch((e) => {
                if (e.message?.includes("2фа")) {
                    setShowTwoFa(true);
                    return sendNotification(
                        "warning",
                        "Ошибка",
                        "Неверный 2ФА код"
                    );
                }
            });
    };

    return (
        <>
            {loading && <Loader/>}
            <div className="position-relative">
                <div className="authentication-wrapper authentication-basic container-p-y">
                    <div className="bg-style vh-100">
                        <div className="authentication-inner py-4 mx-auto media-mt-5" style={{marginTop: '5%'}}>
                            <div className="card p-4 media-p-2">
                                <div className="app-brand justify-content-center mt-5">
                                    <img src={require('../images/logo2.png')} className='media-w-img-logo' width={'200px'} alt='' />
                                </div>
                                <div className="card-body mt-2">
                                    <h4 className="mb-4 fw-semibold media-fs-17">Welcome to Apex Group! 👋</h4>
                                    <p className="mb-5" style={{fontSize: '14px'}}>Пожалуйста, войдите в свой аккаунт для начала работы</p>
                                    <form onSubmit={(e) => e.preventDefault()} className="">
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="email"
                                                name="email"
                                                onChange={updateField}
                                                placeholder="example@gmail.com"
                                                required
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                autoFocus
                                            />
                                            <label htmlFor="email">Email</label>
                                        </div>
                                        <div className="form-floating form-floating-outline mb-3">
                                            <input
                                                type="password"
                                                name="password"
                                                onChange={updateField}
                                                placeholder="**********"
                                                required
                                                className="form-control"
                                                id="exampleInputPassword1"
                                                autoComplete="current-password"
                                            />
                                            <label htmlFor="password">Пароль</label>
                                        </div>
                                        {showTwoFa && (
                                            <div className="form-floating form-floating-outline mb-3">
                                                <input
                                                    type="text"
                                                    name="code"
                                                    onChange={updateField}
                                                    placeholder="*******"
                                                    required
                                                    className="form-control"
                                                    id="code"
                                                />
                                                <label htmlFor="code">2FA</label>
                                            </div>
                                        )}
                                        <div className=" text-center">
                                            <button 
                                                className="btn btn-primary mt-4 py-3 px-5 media-px-4 media-py-2" 
                                                type="submit"
                                                onClick={(e) => submit(e)}
                                            >
                                                <span>Войти  <i className='tf-icons mdi mdi-login-variant ms-2'></i></span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;