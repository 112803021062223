import React from "react";
import { Payments } from "../helpers/api";
import { isEmpty } from "lodash";
import { v1 as uuidv1 } from "uuid";
import  secureLocalStorage  from  "react-secure-storage";
import { sendNotification } from "../helpers/notification";
import moment from "moment";

export const PaymentsContext = React.createContext({});

export class PaymentsProvider extends React.Component {

  state = {
    getPayBalance: () => {
      return Payments.current()
        .then((res) => {
          this.setState({ 
            balance: res.body.user.currency === "USDT" ? res.body.user.balanceUsdt : res.body.user.balance,
            currency: res.body.user.currency.toLowerCase()
          });
        })
        .catch((error) => {
          console.log(error.message);
          sendNotification("warning", "Не удалось загрузить баланс выплат ⚠️", error?.response?.body?.message);
        });
    },

    getPayOrders: async (filter) => {
      if (filter?.date) {
        filter = { ...filter, date: moment(filter.date).format('YYYY-MM-DD') };
      }
      await Payments.getOrdersPayments(filter)
        .then((res) => {
          this.setState({ orders: res.body.orders });
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
        });
    },

    getPayStat: async () => {
      return Payments.getStatPayments()
        .then((res) => {
          this.setState({ stats: res.body });
        })
        .catch((error) => {
          sendNotification("warning", "Ошибка ⛔️", error?.response?.body?.message);
          return { status: 'error' }
        });
    },

    createOrder: async () => {
      let params = {};
      params.amount = this.state.amount;
      params.card = this.state.card;
      params.bank = this.state.bank;
      params.external_id = uuidv1();
      await Payments.createOrder(params)
        .then((res) => {
          sendNotification("success", "Удачно", "Заявка созданна");
        })
        .catch((error) => {
          console.log("error", JSON.stringify(error, null, 4));
          sendNotification(
            "warning",
            "Ошибка",
            `${error.response.body.message}`
          );
        });
    },

    setOpenMenu: async (bool) => {
      this.setState({ openMenu: bool });
    },
    
    clear: () => {
      this.setState({
        openMenu: false,
        balance: null,
        stats: null,
        orders: null,
        amount: null,
        card: null,
        bank: null,
        currency: null
      });
    },
    
    updateState: async (key, val) =>
      await this.setState((state) => {
        state[key] = val;
        return state;
      }),

    openMenu: true,
  };

  render() {
    return (
      <PaymentsContext.Provider value={this.state}>
        {this.props.children}
      </PaymentsContext.Provider>
    );
  }
}